import { Button } from 'antd';
import React from 'react';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import { useBlockingContext } from '../../../Contexts/SchedulerContext/SchedulerContext';
import { TLightboxOnClose } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import './ConfirmCancel.scss';

interface IConfirmCancelProps {
  onCloseLightbox(onClose: TLightboxOnClose): void;
}

export default function ConfirmRemoveBlocking({
  onCloseLightbox,
}: IConfirmCancelProps) {
  const { blockerId } = useLightbox();

  const [, blockingEvents, , deleteBlocker] = useBlockingContext();

  const blocker = blockingEvents?.find(o => o.id === blockerId);

  const onRemove = () => {
    if (!!blockerId) {
      deleteBlocker(blockerId);
      onCloseLightbox('remove');
    }
  };

  return (
    <div className="Blocking RemoveBlocking">
      <Message
        title={
          <span>
            {`Are you sure you want to cancel`}{' '}
            <strong className="EventTitle">{blocker?.subject}</strong>
          </span>
        }
        subTitle={
          'Removing this blocking timeslot will make these slots available to parents'
        }
        icon={'fad fa-fw fa-triangle-exclamation'}
      />
      <div className="BlockingActions">
        <Button
          danger
          type="primary"
          onClick={onRemove}
          className="BlockingButton Remove Primary"
        >
          Remove timeslot
        </Button>
      </div>
    </div>
  );
}
