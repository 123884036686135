import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useChoices, useCurrentSiteEndpoint } from '@rapid/sdk';
import { useStore } from '../../Store';
import DynamicForm from '../common/DynamicForm';
import {
  childFormDatas,
  contactFormDatas,
  familyFormDatas,
  leadFormDatas,
} from './formData';
import { IChoice } from '@rapid/data-model';

const Lead = {
  id: null,
  enquiry_stage: '',
  start_date: '',
  priority: '',
  total_days: '',
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  enquiry_closure: null,
  family_id: null,
  primary_contact_id: null,
  child_id: null,
  child: '',
  LinkedItemsToAdd: [],
  ScopedLinkedItemsToAdd: [] as any,
  enrolment_form_completed: 0,
};

const Family = {
  id: null,
  family_name: '',
};

const Child = {
  id: null,
  first_name: '',
  last_name: '',
  gender: '',
  qkid: '',
  dob: '',
  family_id: null,
};

const Contact = {
  id: null,
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  dob: '',
  family_id: null,
};

const genderList = [
  { Text: 'Male', value: 'Male' },
  { Text: 'Female', value: 'Female' },
];

const blankChoice: IChoice = { Text: '', AccentColour: '' };

const LeadCreateForm = () => {
  const [store] = useStore();
  const ep = useCurrentSiteEndpoint();
  const history = useHistory();
  const match = useRouteMatch<any>();

  const centreId = +/^(\d+)/.exec(match.params.centreId)?.[0]!;

  const [leadData] = useState<any>();

  const [data, setData] = useState({
    leadDetail: { ...Lead },
    childDetail: { ...Child },
    familyDetail: { ...Family },
    contactDetail: { ...Contact },
  });

  const [errors, setErrors] = useState({ family: {}, child: {}, contact: {} });
  const [waitVisible, setWaitVisible] = useState<any | null>(false);
  const [priorities] = useChoices('Leads', 'priority');
  const [enquiryStages] = useChoices('Leads', 'enquiry_stage');
  const [enquiryClosures] = useChoices('Leads', 'enquiry_closure');

  const dropDownItems = useMemo(
    () => ({
      gender: genderList,
      priority: [blankChoice, ...priorities],
      enquiry_stage: [blankChoice, ...enquiryStages],
      enquiry_closure: [blankChoice, ...enquiryClosures],
    }),
    [priorities, enquiryStages, enquiryClosures],
  );

  useEffect(() => {
    let leadDetail = { ...Lead };
    let contactDetail = { ...Contact };
    let childDetail = { ...Child };
    let familyDetail = { ...Family };

    leadDetail.ScopedLinkedItemsToAdd.push({
      centre_id: centreId,
      column_name: 'centres',
      entity_type_id: 21,
    });

    if (leadData) {
      const leadKeys = Object.keys(Lead);
      leadKeys.forEach(element => {
        if (leadData[element]) {
          leadDetail[element] = leadData[element];
        }
      });
      leadDetail.ScopedLinkedItemsToAdd.push({
        centre_id: centreId,
        column_name: 'centres',
        entity_type_id: 21,
        lead_id: leadData.id,
      });
      familyDetail.id = leadData.family_id;
      familyDetail.family_name = leadData.family;

      childDetail.id = leadData.child_id;
      childDetail.first_name = leadData.child_first;
      childDetail.last_name = leadData.child_last;
      childDetail.dob = leadData.child_dob;
      childDetail.family_id = leadData.family_id;

      contactDetail.id = leadData.primary_contact_id;
      contactDetail.first_name = leadData.contact_first;
      contactDetail.last_name = leadData.contact_last;
      contactDetail.mobile = leadData.contact_mobile;
      contactDetail.email = leadData.contact_email;
      contactDetail.family_id = leadData.family_id;
      setData({ leadDetail, contactDetail, childDetail, familyDetail });
    } else setData({ leadDetail, contactDetail, childDetail, familyDetail });
  }, [leadData]);

  const onChangeLead = ev => {
    const { value, name, type } = ev.target;
    let temp = { ...data.leadDetail };

    if (type === 'checkbox') temp[value] = !temp[value];
    else temp[name] = value;

    setData({ ...data, leadDetail: temp });
  };

  const onChangeFamily = ev => {
    const { value, name, required, dataset } = ev.target;
    let error = { ...errors.family };
    let temp = { ...data.familyDetail };
    temp[name] = value;
    if (required && !value) error[name] = `Please enter ${dataset.label}`;
    else error[name] = undefined;
    setErrors({ ...errors, family: error });

    setData({ ...data, familyDetail: temp });
  };

  const onChangeChild = ev => {
    const { value, name, required, dataset } = ev.target;
    let error = { ...errors.child };
    let temp = { ...data.childDetail };
    if (name === 'gender') temp[name] = value;
    else temp[name] = value;
    if (required && !value) error[name] = `Please enter ${dataset.label}`;
    else error[name] = undefined;
    setErrors({ ...errors, child: error });
    setData({ ...data, childDetail: temp });
  };

  const onChangeContact = ev => {
    const { value, name, required, dataset } = ev.target;
    let error = { ...errors.contact };
    let temp = { ...data.contactDetail };
    temp[name] = value;

    if (required && !value) error[name] = `Please enter ${dataset.label}`;
    else error[name] = undefined;
    setErrors({ ...errors, contact: error });

    setData({ ...data, contactDetail: temp });
  };

  const checkValidation = () => {
    let isValid = true;
    const temp = Object.keys(errors);
    if (temp.length > 0) {
      temp.forEach(elm => {
        const tmp = Object.keys(errors[elm]);
        const tempItem = errors[elm];
        if (tmp.length > 0)
          tmp.forEach(elm2 => {
            isValid = tempItem[elm2] ? false : true;
          });
      });
    }
    return isValid;
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    // const familyUrl = `${authContext.site}/lists/Family/items`;
    // const childUrl = `${authContext.site}/lists/Child/items`;
    // const contactUrl = `${authContext.site}/lists/Contact/items`;
    // const leadUrl = `${authContext.site}/lists/Lead/items`;

    let tempChild = { ...data.childDetail };
    let tempContact = { ...data.contactDetail };
    let tempLead = { ...data.leadDetail };

    let isValid = checkValidation();

    if (isValid) {
      setWaitVisible(true);
      const familyRes = data.familyDetail.id
        ? await ep.Lists.Family.Items[+data.familyDetail.id!].putJson(
            undefined,
            data.familyDetail,
          )
        : // ? await rapidPutCall(
          //     `${familyUrl}/${data.familyDetail.id}`,
          //     data.familyDetail,
          //     authContext
          //   )
          await ep.Lists.Family.Items.postJson(undefined, data.familyDetail);
      // : await rapidPostCall(familyUrl, data.familyDetail, authContext);

      const familyJson = familyRes;
      tempChild.family_id = familyJson[0]?.id ?? familyJson.id;
      tempContact.family_id = familyJson[0]?.id ?? familyJson.id;
      tempLead.family_id = familyJson[0]?.id ?? familyJson.id;

      const childRes = tempChild.id
        ? await ep.Lists.Child.Items[+tempChild.id!].putJson(
            undefined,
            tempChild,
          )
        : // ? await rapidPutCall(
          //     `${childUrl}/${tempChild.id}`,
          //     tempChild,
          //     authContext
          //   )
          await ep.Lists.Child.Items.postJson(undefined, tempChild);
      // : await rapidPostCall(childUrl, tempChild, authContext);
      const childJson = childRes;
      const contactRes = tempContact.id
        ? await ep.Lists.Contacts.Items[+tempContact.id!].putJson(
            undefined,
            tempContact,
          )
        : // ? await rapidPutCall(
          //     `${contactUrl}/${tempContact.id}`,
          //     tempContact,
          //     authContext
          //   )
          await ep.Lists.Contacts.Items.postJson(undefined, tempContact);
      // : await rapidPostCall(contactUrl, tempContact, authContext);
      const contactJson = contactRes;
      tempLead.child_id = childJson[0]?.id ?? childJson.id;
      tempLead.primary_contact_id = contactJson[0]?.id ?? contactJson.id;
      let holder;

      for (const linkedItem of tempLead.ScopedLinkedItemsToAdd) {
        if ('centre_id' in linkedItem) {
          linkedItem.centre_id = centreId;
        }
      }

      tempLead.id
        ? (holder = await ep.Lists.Leads.Items[+tempLead.id!].putJson(
            undefined,
            tempLead,
          ))
        : // ? (holder = await rapidPutCall
          //     `${leadUrl}/${tempLead.id}`,
          //     tempLead,
          //     authContext
          //   ))
          (holder = await ep.Lists.Leads.Items.postJson(undefined, tempLead));
      // : (holder = await rapidPostCall(leadUrl, tempLead, authContext));
      setWaitVisible(false);
      // setContact(null);
      setData({
        leadDetail: { ...Lead },
        childDetail: { ...Child },
        familyDetail: { ...Family },
        contactDetail: { ...Contact },
      });

      try {
        const parsed = holder;
        history.push(`${match.url}/lead-${parsed[0]?.id}`);
      } catch (e) {}
    }
  };

  return (
    <React.Fragment>
      <div className="gce-component-container gce-margin-medium-top">
        <form onSubmit={handleSubmit}>
          <h2>Family</h2>
          <div className="gce-grid">
            <DynamicForm
              formDatas={familyFormDatas}
              options={null}
              errors={errors.family}
              data={data.familyDetail}
              handleChange={onChangeFamily}
            />
          </div>
          <h2>Child</h2>

          <div className="gce-grid">
            <DynamicForm
              formDatas={childFormDatas}
              options={dropDownItems}
              data={data.childDetail}
              handleChange={onChangeChild}
            />
          </div>
          <h2>Lead</h2>
          <React.Fragment>
            <div className="gce-grid">
              <DynamicForm
                formDatas={leadFormDatas}
                options={dropDownItems}
                data={data.leadDetail}
                handleChange={onChangeLead}
              />
            </div>
          </React.Fragment>

          <h2>Contact</h2>

          <div className="gce-grid">
            <DynamicForm
              formDatas={contactFormDatas}
              options={dropDownItems}
              data={data.contactDetail}
              handleChange={onChangeContact}
            />
          </div>

          <hr className="gce-margin-medium-top" />

          <div className="gce-grid">
            <div className="gce-width-1-3"></div>
            <div className="gce-width-1-3 gce-text-center">
              <button
                id="submitButton"
                className="gce-button gce-button-darkgreen"
                type="submit"
              >
                <i className="fal fa-fw fa-plus-circle"></i> Create Lead
              </button>
            </div>
            <div className="gce-width-1-3"></div>
          </div>
          {waitVisible ? (
            <div className={`gce-mask isVisible`}>
              <span className="fa-stack fa-3x">
                <i className="fad fa-spinner-third fa-stack-2x fa-spin"></i>
                <i className="fal fa-question fa-stack-1x"></i>
              </span>
              <div className="gce-text-center" style={{ fontSize: '1.5rem' }}>
                {'Please Wait...'}
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </React.Fragment>
  );
};

export default LeadCreateForm;
