import { Spinner, useListItemContext } from '@rapid/sdk';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCentreAlias } from '../../Store';
import Breadcrumb from '../common/Breadcrumb';
import BreadcrumbItem from '../common/BreadcrumbItem';
import Timeline from '../Timeline/Timeline';
import LeadCards from './LeadCards';
import LeadDetail from './LeadDetail';

export default function LeadProfile() {
  const [{ item: lead }] = useListItemContext();
  const centreAlias = useCentreAlias();
  const history = useHistory();

  const match = useRouteMatch<{
    centreId: string;
    tab: string;
    leadId: string;
  }>();

  const onNavigateToTab = (tab?: string) => {
    if (tab === 'waitlist') {
      const { centreId, leadId } = match.params;
      history.push(`/${centreId}/waitlist/lead-${leadId}`);
    }
  };

  if (!lead) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { key: 'centre', url: '/centres', displayName: 'Centres' },
          {
            key: 'centre_alias',
            url: `/${match.params.centreId}/${match.params.tab}`,
            displayName: <BreadcrumbItem icon="fa-home">{centreAlias}</BreadcrumbItem>,
          },
          {
            key: 'lead',
            displayName: <BreadcrumbItem icon="fa-child">{lead.child ?? 'TBC'}</BreadcrumbItem>,
          },
        ]}
      />

      <div className="gce-container">
        <LeadDetail lead={lead} />
        <LeadCards lead={lead} onSubmit={onNavigateToTab} />
        <Timeline searchAddress={lead.email} />
      </div>
    </>
  );
}
