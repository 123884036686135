import { SearchBox, useComposedClassName } from '@rapid/sdk';
import React, { ChangeEventHandler } from 'react';
import { IContactFilter } from '../ContactSelection';
import './ContactSelectionSearch.scss';

interface IContactSelectionSearch {
  filter: IContactFilter;
  onClear(): void;
  onChange(key: keyof IContactFilter): ChangeEventHandler<HTMLInputElement>;
  onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void;
}

export default function ContactSelectionSearch({
  filter,
  onChange,
  onClear,
  onKeyDown,
}: IContactSelectionSearch) {
  const mobileClass = useComposedClassName(function* () {
    yield 'Mobile';
  }, []);

  const emailClass = useComposedClassName(function* () {
    yield 'Email';
  }, []);

  return (
    <div className="ContactSelectionSearch">
      <SearchBox
        className={mobileClass}
        value={filter.email}
        onChange={onChange('email')}
        onClearClicked={onClear}
        onKeyDown={onKeyDown}
        placeholder="Contact email..."
        disableClearButton
        focusOnMount
      />
      <SearchBox
        className={emailClass}
        value={filter.mobile}
        onChange={onChange('mobile')}
        onClearClicked={onClear}
        onKeyDown={onKeyDown}
        placeholder="Contact mobile..."
      />
    </div>
  );
}
