import { AnyListItem } from '@rapid/data-model';
import { IScheduler } from '@rapid/dhtmlx-scheduler';
import React from 'react';
import EventRender from '../Components/Event/EventRender';
import { ILead } from '../Types/ItemTypes';
import { ISchedulerContext, TSchedulerTour } from '../Types/SchedulerTypes';

export function onEventRender(
  context: ISchedulerContext,
  fetchLeads: (eventId: number) => Promise<any>,
  openLead: (lead: AnyListItem<ILead>) => void,
) {
  return function _onEventRender(
    scheduler: IScheduler,
    _event: TSchedulerTour,
  ) {
    return (
      <EventRender
        event={_event}
        scheduler={scheduler}
        mode={context.mode}
        fetchLeads={fetchLeads}
        openLead={openLead}
      />
    );
  };
}
