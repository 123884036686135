import { Button, Calendar } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { IValidDateRange } from './DateTimePicker';
import getValidMomentRange from './getValidMomentRange';
import getValidTimeRangeWithDate from './getValidTimeRangeWithDate';
import TimePicker, { IValidTimeRange } from './TimePicker';

interface IDateTimePickerDropdownProps {
  validDateRange?: IValidDateRange;
  validTimeRange?: IValidTimeRange;
  onChange(type: 'time' | 'date' | 'clear', value?: DateTime): void;
  value?: DateTime;
  onClose(): void;
}

export default function DateTimePickerDropdown({
  validDateRange,
  validTimeRange,
  onChange,
  onClose,
  value,
}: IDateTimePickerDropdownProps) {
  const momentVal = useMemo(
    () => (!!value && value.isValid ? moment(value.toJSDate()) : undefined),
    [value],
  );

  const validMomentRange = getValidMomentRange(validDateRange);

  const validTimeRangeWithDate = useMemo(
    function getValidTimeRangeWithDateEffect() {
      return getValidTimeRangeWithDate(validTimeRange, validDateRange, value);
    },
    [validTimeRange, validDateRange, value],
  );

  const onDateSelect = (date: Moment) => {
    const dt = DateTime.fromJSDate(date.toDate());
    onChange('date', dt);
  };

  const onTimeSelect = (date: DateTime) => {
    onChange('time', date);
  };

  const onClear = () => {
    onChange('clear');
    onClose();
  };

  const onSelect = () => {
    onClose();
  };

  return (
    <div className="DateTimePickerDropdown">
      <div className="Pickers">
        <div className="Calendar">
          <Calendar
            fullscreen={false}
            validRange={validMomentRange}
            onSelect={onDateSelect}
            defaultValue={momentVal}
          />
        </div>
        <TimePicker
          onChange={onTimeSelect}
          validTimeRange={validTimeRangeWithDate}
          value={value}
        />
      </div>

      <div className="DateTimePickerActions">
        <Button
          size="small"
          type="text"
          danger
          className="DTPButton Clear"
          onClick={onClear}
        >
          Clear
        </Button>
        <Button
          size="small"
          type="primary"
          className="DTPButton Select"
          onClick={onSelect}
        >
          Ok
        </Button>
      </div>
    </div>
  );
}
