import { AnyListItem } from '@rapid/data-model';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { IChild, IContact, IFamily, ILead, ITour } from '../Types/ItemTypes';
import { TSchedulerTour } from '../Types/SchedulerTypes';
import {
  linkedChildrenTag,
  linkedFamilyTag,
  linkedLeadsTag,
  linkedParentTag,
} from '../Utils/SchedulerSymbols';

export interface IUpdateTourData {
  tour?: Partial<AnyListItem<ITour>>;
  contact?: Partial<AnyListItem<IContact>>;
  child?: Partial<AnyListItem<IChild>>;
  lead?: Partial<AnyListItem<ILead>>;
  family?: Partial<AnyListItem<IFamily>>;
}

function undefinedResp(resolve: (a?: any) => void) {
  resolve();

  return undefined;
}

export default async function updateTour(
  ep: ISiteAPIEndpoint,
  event: Partial<TSchedulerTour>,
  data: IUpdateTourData,
) {
  const tour = data.tour;

  let tourResp: Promise<undefined | AnyListItem<ITour>> = new Promise(
    undefinedResp,
  );

  if (!!tour && !!event.id) {
    tourResp = ep.Lists.Tour.Items[event.id].putJson({}, tour);
  }

  const contact = data.contact;

  let contactResp: Promise<undefined | AnyListItem<IContact>> = new Promise(
    undefinedResp,
  );

  if (!!contact && !!event[linkedParentTag]?.id) {
    contactResp = ep.Lists.Contact.Items[event[linkedParentTag].id].putJson(
      {},
      contact,
    );
  }

  const family = data.family;

  let familyResp: Promise<undefined | AnyListItem<IFamily>> = new Promise(
    undefinedResp,
  );

  if (!!family && !!event[linkedFamilyTag]?.id) {
    familyResp = ep.Lists.Lead.Items[event[linkedFamilyTag].id].putJson(
      {},
      family,
    );
  }

  /*
      TODO

      Add support for multiple leads and multiple children
  */

  const child = data.child;

  let childResp: Promise<undefined | AnyListItem<IChild>> = new Promise(
    undefinedResp,
  );

  if (!!child && !!event[linkedChildrenTag]?.[0]?.id) {
    childResp = ep.Lists.Child.Items[event[linkedChildrenTag][0].id].putJson(
      {},
      child,
    );
  }

  const lead = data.lead;

  let leadResp: Promise<undefined | AnyListItem<ILead>> = new Promise(
    undefinedResp,
  );

  if (!!lead && !!event[linkedLeadsTag]?.[0]?.id) {
    leadResp = ep.Lists.Lead.Items[event[linkedLeadsTag][0].id].putJson(
      {},
      lead,
    );
  }

  return {
    lead: await leadResp,
    child: await childResp,
    family: await familyResp,
    contact: await contactResp,
    tour: await tourResp,
  };
}
