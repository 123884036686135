import { DateTime } from 'luxon';
import React from 'react';
import './TourTime.scss';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';

interface ITourTimeProps {
  event?: Partial<TSchedulerTour>;
  timezone?: string;
}

export default function TourTime({ event, timezone }: ITourTimeProps) {
  let startTime = '(Unset)';
  let endTime = '(Unset)';
  let date = '(Unset)';

  const start =
    event?.start_date instanceof Date
      ? DateTime.fromJSDate(event?.start_date)
      : event?.start_date;

  const end =
    event?.end_date instanceof Date
      ? DateTime.fromJSDate(event?.end_date)
      : event?.end_date;

  if (!!event?.start_date) {
    startTime = start!.toFormat('h:mm a');
    date = start!.toFormat('DDDD');
  }
  if (!!event?.end_date) {
    endTime = end!.toFormat('h:mm a');
  }

  return (
    <div className="TourTime">
      <div className="Time">
        <span className="Start">{startTime}</span>&ensp;-&ensp;
        <span className="End">{endTime}</span>
      </div>
      <div className="Date">{date}</div>
      <small className="Date">Bookings are in {timezone} time</small>
    </div>
  );
}
