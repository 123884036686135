import { DateTime, Interval } from 'luxon';
import { IOutlookEvent } from '../Types/ItemTypes';
import { TSchedulerTour } from '../Types/SchedulerTypes';

export default function checkBlockerCollision(
  blockingTimespans: IOutlookEvent[],
  event: TSchedulerTour | DateTime,
) {
  let startDt: DateTime;
  let endDt: DateTime;

  if (event instanceof DateTime) {
    startDt = event;
    endDt = event.plus({ minute: 30 });
  } else {
    startDt = DateTime.fromJSDate(event.start_date);
    endDt = DateTime.fromJSDate(event.end_date);
  }

  const eventInterval = Interval.fromDateTimes(startDt, endDt);

  const isBlocked = blockingTimespans.some(blocker => {
    const startTime = DateTime.fromISO(blocker.start.dateTime, {
      zone: blocker.start.timeZone,
    }).setZone(event.zone);

    const endTime = DateTime.fromISO(blocker.end.dateTime, {
      zone: blocker.end.timeZone,
    }).setZone(event.zone);

    const interval = Interval.fromDateTimes(startTime, endTime);
    const overlaps = interval.overlaps(eventInterval);

    return overlaps;
  });

  return isBlocked;
}
