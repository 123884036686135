import { AnyListItem } from '@rapid/data-model';
import { Updater, useCurrentSiteEndpoint } from '@rapid/sdk';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import { ILead } from '../../../Types/ItemTypes';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import {
  linkedLeadsTag,
  linkedParentTag,
} from '../../../Utils/SchedulerSymbols';
import LeadCard from '../Lead/LeadCard';
import Message from '../Message/Message';
import { useContactLeadSelectionMessages } from '../Message/UseMessages';
import ContactCard from './ContactCard';
import '../SelectContactOrLead.scss';
import './ContactSelection.scss';

interface IContactLeadSelectionProps {
  event: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  onSelectLead(lead: AnyListItem<ILead>): void;
  onCreateTour(): void;
  onCreateLead(): void;
}

export default function ContactLeadSelection({
  event,
  updateEvent,
  onSelectLead,
  onCreateTour,
  onCreateLead,
}: IContactLeadSelectionProps) {
  const ep = useCurrentSiteEndpoint();

  const { catchLightboxError, setLightboxMode } = useLightbox();

  const [leadsLoading, setLeadsLoading] = useState(false);
  const [leads, setLeads] = useState<AnyListItem<ILead>[]>();

  useEffect(
    function getContactLeads() {
      const selectedContact = event?.[linkedParentTag];

      let filter = `primary_contact_id eq '${selectedContact?.id}'`;

      if (selectedContact?.family_id !== null) {
        filter += ` or family_id eq '${selectedContact?.family_id}'`;
      }

      ep.Lists.Leads.All$.Items.getJson({ $filter: filter })
        .then(res => {
          setLeads(res.value ?? []);
          setLeadsLoading(false);
        })
        .catch(catchLightboxError('notify'));
    },
    [event?.[linkedParentTag]],
  );

  const onClearContact = () => {
    setLightboxMode('select-contact-or-lead');
  };

  const LeadRow = ({ index, style }) => {
    return (
      <div
        className={`Lead${index % 2 === 0 ? ' even' : ' odd'}`}
        key={leads?.[index].id}
        style={style}
      >
        <LeadCard
          lead={leads?.[index]}
          key={leads?.[index]?.id}
          onSelectLead={onSelectLead}
          selected={event?.[linkedLeadsTag]?.some(
            l => l.id === leads?.[index]?.id,
          )}
        />
      </div>
    );
  };

  const message = useContactLeadSelectionMessages(
    leads,
    leadsLoading,
    event?.[linkedParentTag],
    [leads, leadsLoading],
  );

  return (
    <div className="TourCreation">
      <div className="ContactLeadSelection ContactSelection SelectionContainer">
        <div className="SelectedContact">
          <ContactCard contact={event?.[linkedParentTag]} />
          <i
            className="fas fa-fw fa-ban ClearContact"
            onClick={onClearContact}
            title="Clear contact selection"
          />
        </div>

        {!leadsLoading && !!leads?.length && (
          <div className="ContactLeads">
            <div className="SelectALead">Results</div>

            <List
              itemSize={() => 60}
              width={400}
              height={300}
              itemCount={leads.length}
            >
              {LeadRow}
            </List>
          </div>
        )}

        {message.show && <Message {...message} />}

        <div className="ContactSelectionActions">
          <Button onClick={onCreateLead} type="text" className="Button Create">
            Create A New Lead
          </Button>

          <Button
            onClick={onCreateTour}
            type="primary"
            className="Button Create"
            disabled={!event?.[linkedLeadsTag]?.length}
          >
            Attach to tour
          </Button>
        </div>
      </div>
    </div>
  );
}
