import React, { useEffect, useState } from 'react';
import { IEmbedConfiguration, models } from 'powerbi-client';
import './Report.scss';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useCurrentSiteEndpoint } from '@rapid/sdk';
import getReports from '../SchedulerTab/Utils/getReports';

export interface EmbedToken {
  /**
   * Expiration time of token. In UTC.
   */
  expiration: string;
  /**
   * Embed token
   */
  token: string;
  /**
   * Unique token Id. Can be used to correlate operations that use this token
   * with the generate operation through audit logs.
   */
  tokenId: string;
}

interface IReportDetails {
  reportId: string;
  workspaceId: string;
  viewMode: string;
  allowSaveAs: boolean;
}

interface IReportProps { }

export default function Report({ }: IReportProps) {
  const siteEp = useCurrentSiteEndpoint();

  const [report, setReport] = useState<IReportDetails>();

  useEffect(function getReportsEffect() {
    getReports().then(res => {
      setReport(res['global-report']);
    });
  }, []);

  const [embedConfig, setEmbedConfig] = useState<IEmbedConfiguration>();

  const [embedToken, setEmbedToken] = useState<EmbedToken>();

  useEffect(
    function getEmbedToken() {
      if (!!report && !!report.reportId) {
        siteEp.Reports.Embed.getJson(report).then((token: EmbedToken) => {
          setEmbedToken(token);

          setEmbedConfig({
            type: 'report',
            id: report.reportId,
            permissions: models.Permissions.All,
            tokenType: 1,
            accessToken: token.token,
          });
        });
      }
    },
    [report],
  );

  if (!!embedConfig) {
    return (
      <div className="report-resize-wrapper">
        <div className="report">
          <PowerBIEmbed
            cssClassName="powerbi-client-react"
            embedConfig={embedConfig}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="report loading">
      <i className="fal fa-fw fa-spinner-third fa-spin" />
      &ensp; Loading...
    </div>
  );
}

// reportId: 7c8b3fc8-3090-4f82-9fd3-15aea259baa9
// workspaceId: 06b59df7-3b23-41cb-85b1-ebd35671f862
// viewMode: View
// allowSaveAs: true
