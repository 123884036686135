
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ManagedListItemContext } from '@rapid/sdk';

export default function CentreContext({ children }: React.PropsWithChildren<{}>) {
  const match = useRouteMatch<any>();
  const centreId = +/^(\d+)/.exec(match.params.centreId)?.[0]!;

  return (
    <ManagedListItemContext validate list="Centres" id={centreId}>
      {children}
    </ManagedListItemContext>
  );
}

