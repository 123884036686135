import { DateTime } from 'luxon';
import { IValidDateRange } from './DateTimePicker';
import { IValidTimeRange } from './TimePicker';

export default function getValidTimeRangeWithDate(
  validTimeRange?: IValidTimeRange,
  validDateRange?: IValidDateRange,
  value?: DateTime,
) {
  const now = !!value?.isValid ? value : DateTime.now();

  if (
    now.toLocaleString(DateTime.DATE_MED) ===
    validDateRange?.start?.toLocaleString(DateTime.DATE_MED)
  ) {
    const validDateStart = validDateRange.start.toFormat('HH:mm');

    if (!!validTimeRange?.start && validDateStart > validTimeRange.start) {
      const start = validDateRange.start.plus({ minute: 30 }).toFormat('HH:mm');

      return {
        start,
        end: validTimeRange?.end,
      } as IValidTimeRange;
    }

    if (!validTimeRange?.start) {
      const start = validDateRange.start.plus({ minute: 30 }).toFormat('HH:mm');

      return {
        start,
        end: validTimeRange?.end,
      } as IValidTimeRange;
    }
  }

  return validTimeRange;
}
