import { AnyListItem } from '@rapid/data-model';
import { SearchBox, useCurrentSiteEndpoint } from '@rapid/sdk';
import React, { useState } from 'react';
import { TSchedulerEvent } from '@rapid/dhtmlx-scheduler';
import { Button } from 'antd';
import './LeadSelection.scss';
import { ILead, ITour } from '../../../Types/ItemTypes';
import { useLeadSelectionMessages } from '../Message/UseMessages';
import LeadCard from './LeadCard';
import Message from '../Message/Message';
import '../SelectContactOrLead.scss';

interface ILeadSelectionProps {
  onSelectLead(lead: AnyListItem<ILead>): void;
  onCreateLead(): void;
  event?: TSchedulerEvent<ITour>;
}

export default function LeadSelection({
  onSelectLead,
  onCreateLead,
  event,
}: ILeadSelectionProps) {
  const ep = useCurrentSiteEndpoint();

  const [lead, setLead] = useState<AnyListItem<ILead> | null>();

  const [leadId, setLeadId] = useState<number>();

  const [loading, setLoading] = useState(false);

  const onLeadIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLeadId(+e.target.value);
  };

  const onClear = () => {
    setLeadId(undefined);
    setLead(undefined);
  };

  const onSearchLeads = () => {
    setLead(undefined);

    if (!!leadId) {
      setLoading(true);
      ep.Lists.Leads.Items[leadId]
        .getJson()
        .then(res => {
          setLead(res ?? null);
          setLoading(false);
        })
        .catch(err => {
          setLead(null);
          setLoading(false);
        });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchLeads();
    }

    if (e.key === 'Escape') {
      onClear();
    }
  };

  const message = useLeadSelectionMessages(leadId, lead, loading, [
    leadId,
    lead,
    loading,
  ]);

  const noLeadsFoud = !!leadId && !loading && lead === null;

  return (
    <div className="LeadSelection SelectionContainer">
      <SearchBox
        type="number"
        value={leadId}
        onChange={onLeadIdChange}
        placeholder="Search for lead id..."
        onClearClicked={onClear}
        onKeyDown={onKeyDown}
        focusOnMount
      />

      {!!lead && <LeadCard lead={lead} onSelectLead={onSelectLead} />}

      {message.show && <Message {...message} />}

      <div className="LeadSelectionActions">
        {noLeadsFoud && (
          <Button
            onClick={onCreateLead}
            type="primary"
            className="Button Create"
          >
            Create A New Lead
          </Button>
        )}
      </div>
    </div>
  );
}
