import React from 'react';
import { KIconTypes, useComposedClassName } from '@rapid/sdk';

interface IActionCardProps {
  name: string;
  icon: KIconTypes;
  label: React.ReactNode;
  buttonClassName?: string;
  active?: boolean;
  onClick(cardName: string): void;
}

export default function ActionCard({
  name,
  icon,
  label,
  buttonClassName,
  active,
  onClick,
}: IActionCardProps) {
  const buttonClass = useComposedClassName(
    function* () {
      yield 'gce-button gce-button-big';

      if (buttonClassName) yield buttonClassName;

      if (active) yield 'active';
    },
    [buttonClassName, active],
  );

  const onClickInner = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(name);
  };

  return (
    <div className="ActionCard gce-width-1-2">
      <button
        id="gce-button-note"
        className={buttonClass}
        onClick={onClickInner}
      >
        <div>
          <i className={`fal fa-fw ${icon} fa-3x`}></i>
        </div>
        <div>{label}</div>
      </button>
    </div>
  );
}
