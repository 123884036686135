import React from 'react';
import { IList } from '@rapid/data-model';
import { useField } from '@rapid/sdk';
export interface IFieldErrorProps {
  list: IList | string;
  field: string;
}

export default function FieldError({
  list,
  field: fieldName,
}: IFieldErrorProps) {
  const [field] = useField(list, fieldName);
  return <li>{field?.Title ?? fieldName}</li>;
}
