import React from 'react';

interface Props {
  ref?: React.MutableRefObject<HTMLInputElement>;
  placeholder?: string;
  value?: string;
  onChange?(value: string): void;
  onSearch?(): void;
}

export default function SearchBox({ ref, placeholder, onChange, value, onSearch }: Props) {
  function onValueChangedInternal(e: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  function onKeyDownInternal(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSearch?.();
    }
  }

  return (
    <div className="gce-grid gce-grid-collapse">
      <div className="gce-width-expand">
        <div
          className="gce-inputgroup rapid-list-search"
          style={{ width: '100%', marginBottom: '20px' }}
        >
          <i className="fal fa-search"></i>
          <input
            ref={ref}
            className="gce-input"
            value={value}
            onChange={onValueChangedInternal}
            id="searchContactsList"
            placeholder={placeholder ? placeholder : ''}
            onKeyDown={onKeyDownInternal}
            onBlur={onSearch}
          />
        </div>
      </div>
    </div>
  );
}
