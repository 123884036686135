import { IField } from '@rapid/data-model';
import { Spinner, useCurrentSiteEndpoint, useList, useListItemContext } from '@rapid/sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCentre,
  getCentreRooms,
  getChild,
  getFamily,
  getPrimaryContact,
  returnRapidResponse,
} from '../../common/utils/requests';
import CentreRoom from '../../types/centre-room.interface';
import Centre from '../../types/centre.interface';
import Child from '../../types/child.interface';
import Contact from '../../types/contact.interface';
import Family from '../../types/family.interface';
import Lead from '../../types/lead.interface';
import DynamicForm from '../dynamic-form/dynamic-form';
import { FormField } from '../dynamic-form/fields/form-field';

type Params = { tourId: string; leadId: string; centreId: string };

type State = {
  child?: Child | null;
  lead?: Lead | null;
  family?: Family | null;
  contact?: Contact | null;
  note?: string;
  centre?: Centre;
  centreRooms?: Array<CentreRoom>;
  updated?: Record<string, { formValue?: any; errors?: object }>;
};

const dayPickerField = {
  ColumnName: '__day_picker',
  Title: 'Days',
  FieldType: 'DayPicker' as any,
} as IField;

const formComplete = {
  ColumnName: 'enrolment_form_completed',
  FieldType: 'Choice',
  Title: 'Enrolment Form Completed',
  Settings: {
    Choices: [
      { Value: true, Text: 'Yes' },
      { Value: false, Text: 'No' },
    ],
  },
} as any;

export default function LeadUpdateForm() {
  const [contactList] = useList('Contacts');
  const [familyList] = useList('Families');
  const [leadList] = useList('Leads');
  const [childList] = useList('Children');
  const history = useHistory();

  const params = useParams<Params>();
  const centreId = /^(\d+)/.exec(params.centreId)?.[0]!;
  const ep = useCurrentSiteEndpoint();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [{ fetchItem: _fetchItem }] = useListItemContext();

  const [state, updateState] = useState<State>({});

  const onMount = useCallback(async lead => {
    const centre = await getCentre(ep, +centreId);
    const centreRooms = await getCentreRooms(ep, +centreId);
    const child = await getChild(ep, lead.child_id);
    const family = await getFamily(ep, lead.family_id);
    const contact = await getPrimaryContact(ep, lead.primary_contact_id);

    updateState({
      centre,
      centreRooms,
      child,
      family,
      lead,
      contact,
      note: '',
      updated: {},
    });
    setLoading(false);
  }, []);

  const hasError = Object.values(state.updated ?? {}).some(v => {
    return Object.values(v?.errors ?? {}).some(err => !!err);
  });

  const onFormUpdated = (key: string) => (formValue, errors) => {
    updateState(draft => ({
      ...draft,
      updated: { ...draft.updated, [key]: { formValue, errors } },
    }));
  };

  /**
   * Form Steps:
   *
   * 1: Update Lead,
   * 2: Create or Update Contact,
   * 3: Create or Update Family, `family_name: contact.full_name`
   * 4: Update Contact with, `family_id: family.id`
   * 5: Create or Update Child, `family_id: family.id`
   * 6: Update the lead with, `family_id: family.id`, `primary_contact_id: contact.id`, `child_id: child.id`
   *
   */
  const onSubmitForm = async () => {
    setSubmitting(true);
    const leadEp = ep.Lists.Leads;
    const childEp = ep.Lists.Children;
    const familyEp = ep.Lists.Families;
    const contactEp = ep.Lists.Contacts;

    let lead = state.updated!.lead.formValue as Lead;
    let child = (state.updated!.child?.formValue as Child) ?? {};
    let contact = (state.updated!.contact?.formValue as Contact) ?? {};
    let family = (state.updated!.family?.formValue as Family) ?? {};

    // Step 1
    if (!lead.id) return;
    const updatedLead = await leadEp.Items[lead.id].putJson(undefined, lead);
    lead = returnRapidResponse(updatedLead);

    // Step 2
    if (contact.id) {
      contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));
    } else {
      contact = returnRapidResponse(await contactEp.Items.postJson(undefined, contact));
    }

    family.family_name =
      contact.full_name ?? `${contact.first_name ?? ''} ${contact.last_name ?? ''}`;

    // Step 3
    if (family.id) {
      family = returnRapidResponse(await familyEp.Items[family.id].putJson(undefined, family));
    } else {
      family = returnRapidResponse(await familyEp.Items.postJson(undefined, family));
    }

    // Step 4
    contact.family_id = family.id;
    child.family_id = family.id;
    contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));

    // Step 5
    if (child.id) {
      child = returnRapidResponse(await childEp.Items[child.id].putJson(undefined, child));
    } else {
      child = returnRapidResponse(await childEp.Items.postJson(undefined, child));
    }

    // Step 6
    lead.child_id = child.id;
    lead.family_id = family.id;
    lead.primary_contact_id = contact.id;
    await ep.Lists.Leads.Items[lead.id].putJson(undefined, lead);

    // End
    setSubmitting(false);
    _fetchItem();
    history.push(`.`);
  };

  useEffect(() => {
    ep.Lists.Leads.All.Items[+params.leadId].getJson().then(lead => {
      onMount(lead);
    });
  }, []);

  if (loading || !state.lead) {
    return <Loader />;
  }

  return (
    <div className="gce-component-container gce-margin-medium-top">
      {submitting && <Loader />}

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Family details</h3>
        </div>
        <DynamicForm
          fields={familyList.Fields ?? []}
          initialValue={state.family! ?? {}}
          onUpdate={onFormUpdated('family')}
        ></DynamicForm>
        <DynamicForm
          fields={contactList.Fields ?? []}
          initialValue={state.contact! ?? {}}
          onUpdate={onFormUpdated('contact')}
        >
          <FormField name="first_name" className="gce-width-1-3@m" required />
          <FormField name="last_name" className="gce-width-1-3@m" required />

          <DynamicForm
            fields={[formComplete, dayPickerField].concat(leadList.Fields)}
            initialValue={state.lead!}
            onUpdate={onFormUpdated('lead')}
          >
            <FormField name="start_date" className="gce-width-1-3@m" required />
          </DynamicForm>

          <FormField name="mobile" className="gce-width-1-3@m" required />
          <FormField name="email" className="gce-width-1-3@m" required />
        </DynamicForm>
        <DynamicForm
          fields={[formComplete, dayPickerField].concat(leadList.Fields)}
          initialValue={state.lead! ?? {}}
          onUpdate={onFormUpdated('lead')}
        >
          <FormField name="priority" className="gce-width-1-3@m" />
          <FormField name="enquiry_stage" className="gce-width-1-3@m" required />
          <FormField name="total_days" className="gce-width-1-3@m" required />
          <FormField name="__day_picker" className="gce-width-1-3@m" required />
          <FormField name="enquiry_closure" label="Wrap up code" className="gce-width-1-3@m" />
          <FormField
            name="enrolment_form_completed"
            label="Enrolment Form Completed"
            className="gce-width-1-3@m"
            required
          />
        </DynamicForm>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Child details</h3>
        </div>
        <DynamicForm
          fields={childList.Fields ?? []}
          initialValue={state.child! ?? {}}
          onUpdate={onFormUpdated('child')}
        >
          <FormField name="first_name" className="gce-width-1-3@m" required />
          <FormField name="last_name" className="gce-width-1-3@m" required />
          <FormField name="dob" label="Date of birth" className="gce-width-1-3@m" required />
          <FormField name="gender" className="gce-width-1-3@m" required />
        </DynamicForm>
      </div>

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top gce-text-center">
          <button
            disabled={hasError}
            className={`gce-button ${!hasError ? 'gce-button-darkgreen' : 'gce-button-green'}`}
            type="submit"
            onClick={onSubmitForm}
          >
            <i className="fal fa-fw fa-paper-plane"></i> Submit update
          </button>
        </div>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className="EditOfferSpinner" style={{ zIndex: 9999 }}>
      <div className="EditOfferSpinner__card rp-card-default">
        <h3>Loading...</h3>
        <div className="EditOfferSpinner__spin-container">
          <Spinner />
        </div>
      </div>
    </div>
  );
}
