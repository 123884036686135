import { Spinner } from '@rapid/sdk/lib/ui';
import * as React from 'react';
import './LoadingSpinner.scss';

export interface ISpinnerProps {
  type: 'fullpage' | 'component';
}

function SimpleSpinner() {
  return (
    <div className={`SimpleSpinner`}>
      <div className="text-container">
        <span>Loading...</span>
        <Spinner />
      </div>
    </div>
  );
}

export default SimpleSpinner;
