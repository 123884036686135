import { TSchedulerEvent, IScheduler } from '@rapid/dhtmlx-scheduler';
import { Updater } from '@rapid/sdk';

export function onEventAdded<T>(
  events: TSchedulerEvent<T>[],
  updateEvents: Updater<TSchedulerEvent<T>[]>,
) {
  return function (scheduler: IScheduler, id: string, ev: MouseEvent) {
    const event = scheduler.getEvent(id);

    updateEvents(d => {
      if (!d.some(e => e.id === event.id)) {
        d.push(event);
      }
    });
  };
}
