import { AnyListItem } from '@rapid/data-model';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { DateTime } from 'luxon';
import { linkedFamilyTag, linkedLeadsTag } from '../Utils/SchedulerSymbols';
import { ILead, IMeeting, IMeetingEvent, ITour } from '../Types/ItemTypes';
import { TSchedulerTour } from '../Types/SchedulerTypes';

export interface ICreateTourReturn {
  meeting: IMeeting;
  tour: ITour;
  meetingEvent: AnyListItem<IMeetingEvent>;
}

export default async function createTour(
  ep: ISiteAPIEndpoint,
  event: Partial<TSchedulerTour> | undefined,
  centreId: number,
): Promise<ICreateTourReturn> {
  if (!event?.[linkedFamilyTag]?.family_name) {
    const fam = await ep.Lists.Families.All$.Items.getJson({
      linkedTo: `Leads/${event?.[linkedLeadsTag]?.[0].id}`,
    });

    event![linkedFamilyTag] = fam.value[0];
  }

  // Create tour
  //// Link Centre
  //// Link family
  //// Tour date and time = event.start_date event.end_date
  //// Tour status -> Tour Booked
  //// Link Leads
  const [tourResp] = await ep.Lists.Tour.items.postJson(
    {},
    {
      ...(event ?? {}),
      id: null,
      tour_status: 'Tour Booked',
      tour_booking_date_and_time: event?.start_date?.toUTC().toISO(),
      centre_id: centreId,
      family_id: event?.[linkedFamilyTag]?.id,
      is_your_child_going_to_attend_the_tour:
        // @ts-ignore
        event?.is_your_child_going_to_attend_the_tour,
      LinkedItemsToAdd: [
        ...(event?.[linkedLeadsTag]?.reduce(
          (prev: string[], curr: AnyListItem<ILead>) => {
            prev.push(`Leads/${curr.id}`);
            return prev;
          },
          [] as string[],
        ) ?? []),
      ],
    },
  );

  // Get the full new tour
  const tour = await ep.Lists.Tour.All$.Items[tourResp.id].getJson();

  // Get Meeting for centre
  //
  // If not meeting exists create one
  //// Title `${Centre Name} - Tours`
  //// Organiser -> CMCentre (comes from the cm_task_id on centre)
  let meeting = await (ep.Lists.Meeting.All$ as any).items.getJson({
    linkedTo: `Centres/${centreId}`,
  });

  const centre = await ep.Lists.Centres.items[centreId].getJson();

  // If no meeting create one
  if (!meeting.value.length) {
    [meeting] = await (ep.Lists.Meeting.All as any).items.postJson(
      {},
      {
        title: `${centre.centre_name} - Tours`,
        organiser_id: centre.cm_task_id,
        LinkedItemsToAdd: [`Centres/${centreId}`],
      },
    );
  } else {
    // If meeting has value set it to meeting;
    meeting = meeting.value[0];
  }

  //
  // Get meeting event
  //
  // If none exists create one
  //// Title = Family Name?
  //// Invitees -> CMCentre & Centre
  //// Guests -> Primary Contact -> Create External Contact using LookupsToAdd query
  //// Start Date = event.start_date
  //// End Date = event.end_date
  //// Status -> pending
  //
  const start = !!event?.start_date
    ? event?.start_date.toUTC().toISO()
    : null;
  const end = !!event?.end_date
    ? event?.end_date.toUTC().toISO()
    : null;

  // Get the meeting events list to ensure we use the correct id
  // As meeting events id can change site to site
  const entities = await ep.Lists.Entities.All$.Items.getJson({
    $filter: "type eq 'Meeting Events'",
  });

  // Meeting events id used to create ScopedLinkedItemsToAdd
  const meetingEventsId = entities.value[0].id;

  // Create a new meeting event. Omni will take care of the rest...
  const [meetingEvent] = await ep.Lists['Meeting Events'].items.postJson(
    {},
    {
      title: event?.[linkedFamilyTag]?.family_name,
      subject: event?.[linkedFamilyTag]?.family_name,
      start,
      end,
      status: 'Pending',
      meeting_id: meeting.id,
      LinkedItemsToAdd: [`Centres/${centreId}`, `Tours/${tour.id}`],
      ScopedLinkedItemsToAdd: [
        {
          column_name: 'invitees',
          principal_id: centre.cm_task_id,
          entity_type_id: meetingEventsId,
        },
        {
          column_name: 'invitees',
          principal_id: centre.centre_user_id,
          entity_type_id: meetingEventsId,
        },
        // This is the code to add parents as guests. incase it's wanted again
        //
        // {
        //   column_name: 'guests',
        //   $filter: `contact eq '${event?.[linkedParentTag]?.email}'`,
        //   contact: event?.[linkedParentTag]?.email,
        //   name: event?.[linkedParentTag]?.full_name,
        //   __metadata: {
        //     type: 'External Contacts',
        //   },
        // },
      ],
    },
  );

  return {
    tour,
    meeting,
    meetingEvent,
  };
}
