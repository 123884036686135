import { AnyListItem } from '@rapid/data-model';
import { DateTime } from 'luxon';
import React from 'react';
import { ITab, Store, TTabs, useCentreAlias, useStore } from '../Store';
import { ManagedViewContext, useGraphEndpoint, useViewContextListItemArray } from '@rapid/sdk';
import { notification } from 'antd';

export const getWeekDays = item => {
  return (
    <>
      <span>{item.monday ? 'M' : '-'}</span> <span>{item.tuesday ? 'T' : '-'}</span>{' '}
      <span>{item.wednesday ? 'W' : '-'}</span> <span>{item.thursday ? 'T' : '-'}</span>{' '}
      <span>{item.friday ? 'F' : '-'}</span>{' '}
    </>
  );
};

export const getWeekDaysCount = item => {
  let dayCount = 0;

  if (item.monday) dayCount += 1;
  if (item.tuesday) dayCount += 1;
  if (item.wednesday) dayCount += 1;
  if (item.thursday) dayCount += 1;
  if (item.friday) dayCount += 1;

  return dayCount;
};

export function openSendEmailWindow(to: string, subject?: string) {
  const newwindow = window.open(
    `https://outlook.office.com/owa/?path=/mail/action/compose&subject=${subject ?? ''}&to=${to}`,
    'New Email',
    'height=600,width=700,top=50,left=200',
  );

  newwindow?.focus();
}

export function openMessageWindow(id: string) {
  const newwindow = window.open(
    `https://outlook.office365.com/mail/deeplink/compose/${encodeURIComponent(
      id,
    )}?popoutv2=1&version=20210823004.07`,
    'New Email',
    'height=600,width=700,top=50,left=200',
  );

  //
  newwindow?.focus();
}

export interface IFetchMyCentresUsersTasksHOCProps {
  filter: string;
  tab?: ITab;
  searchTerm?: string;
  orderby?: string;
  count?: boolean;
  dataSource?: string;
  children: React.ReactNode;
}

export function FetchMyCentresUsersTasksHOC({
  filter,
  orderby,
  searchTerm,
  tab,
  children,
  ...rest
}: IFetchMyCentresUsersTasksHOCProps) {
  const [items, view] = useViewContextListItemArray();

  if (view.loading) {
    return <>{children}</>;
  }

  const filterByCentreUserIds = items?.reduce((result, item, i) => {
    if (i === 0) return `assigned_to_id eq '${item.user_id}'`;
    return `${result} or assigned_to_id eq '${item.user_id}'`;
  }, '');

  return (
    <ManagedViewContext
      {...tab?.viewContext}
      {...rest}
      list="Tasks"
      searchTerm={searchTerm}
      filter={
        filterByCentreUserIds
          ? `${filter ? `(${filter}) and ` : ''}(${filterByCentreUserIds})`
          : filter
      }
      orderby={orderby}
      dataSource={!!tab?.showCompletedTasks ? 'Portal Completed Tasks' : 'Portal Uncompleted Tasks'}
    >
      {children}
    </ManagedViewContext>
  );
}

export const createEmailSubject = (lead: AnyListItem, subject?: string) => {
  //[RAPIDPLATFORM116R8-SUB-000039] - 'subject line here'
  const partOne = 'guardianchildcare'.toLocaleUpperCase() + 'internal'.toLocaleUpperCase();
  const partTwo = 'LEA';
  const partThree = `${lead.id}`.padStart(6, '0');
  const partFour = `Email from: ${subject}`;

  return !!subject
    ? `[${partOne}-${partTwo}-${partThree}] - ${partFour}`
    : `[${partOne}-${partTwo}-${partThree}]`;
};

interface ICellProps {
  cell: { path: string };
  item: AnyListItem;
}

export function LeadItemCell({ item, cell }: ICellProps) {
  return <td>{item[cell.path]}</td>;
}

function LeadEmailCell({ item, cell }: ICellProps) {
  const [store] = useStore();
  const alias = useCentreAlias();
  const graphEp = useGraphEndpoint('Mail.Send.Shared');

  function onEmailClicked(ev: React.MouseEvent<HTMLAnchorElement>) {
    ev.stopPropagation();
    ev.preventDefault();

    const fromName = `Guardian Childcare & Education ${alias}`;

    graphEp['me'].messages
      .postJson(undefined, {
        from: {
          emailAddress: {
            name: fromName,
            address: store.centreEmail,
          },
        },
        sender: {
          emailAddress: {
            name: fromName,
            address: store.centreEmail,
          },
        },
        toRecipients: [
          {
            emailAddress: {
              address: item[cell.path],
            },
          },
        ],
        subject: '',
        body: {
          contentType: 'html',
          content: '',
        },
      } as microsoftgraph.Message)
      .then((message: microsoftgraph.Message) => {
        if (message.id) {
          openMessageWindow(message.id);
        }
      })
      .catch(err => {
        notification.error({
          type: 'error',
          message: 'Could not prepare e-mail for sending: ' + err.message,
        });
      });
  }

  return (
    <td>
      <a onClick={onEmailClicked} href={`mailto:${item[cell.path]}`}>
        {item[cell.path]}
      </a>
    </td>
  );
}

function DateOfBirthCell({ item, cell }: ICellProps) {
  const value = DateTime.fromISO(item[cell.path]);

  return (
    <td>
      {value.isValid && (
        <>
          <span>{value.toFormat('dd/MM/yyyy')}</span>
          <small>&nbsp;({value.toRelative({ unit: 'years' })})</small>
        </>
      )}
    </td>
  );
}

export const leadItems = {
  detail: [
    // {
    //   label: 'ID',
    //   path: 'id',
    //   iconName: 'ri ri-rapid',
    //   content: item => (
    //     <a
    //       href={`${process.env.REACT_APP_RAPID_BASE_URL}/guardianchildcare/internal/explorer/Leads/Lead:Leads:${item.id}`}
    //       target="_blank"
    //     >
    //       {item.id} <i className="fal fa-external-link fa-xs" />
    //     </a>
    //   )
    // },
    {
      label: 'Date of Birth',
      path: 'child_dob',
      iconName: 'fal fa-fw fa-calendar-day',
      Content: DateOfBirthCell,
    },
    { label: 'Family', path: 'family', iconName: 'fal fa-fw fa-users' },
    {
      label: 'Contact',
      path: 'primary_contact',
      iconName: 'fal fa-fw fa-user-circle',
    },
    {
      label: 'Mobile',
      path: 'contact_mobile',
      iconName: 'fal fa-fw fa-mobile',
      Content: ({ item }) => (
        <td>
          <a href={`tel:${item.contact_mobile}`}>{item.contact_mobile}</a>
        </td>
      ),
    },
    {
      label: 'Email',
      path: 'contact_email',
      iconName: 'fal fa-fw fa-envelope',
      Content: LeadEmailCell,
    },
  ],
  tour: [
    {
      label: 'Start Date',
      path: 'start_date',
      iconName: 'fal fa-fw fa-calendar-star',
      Content: ({ item }) => (
        <td>
          <span>{new Date(item.start_date).toLocaleDateString()}</span>
        </td>
      ),
    },
    {
      label: 'Days',
      path: '',
      width: '120px',
      iconName: 'fal fa-fw fa-calendar-week',
    },
    {
      label: 'Tour date',
      path: 'tour_booking_date_and_time',
      iconName: 'fal fa-fw fa-map-signs',
      tour: true,
    },
    {
      label: 'Source',
      path: 'enquiry_source',
      iconName: 'fal fa-fw fa-inbox-in',
    },
    {
      label: 'Enrolment Form Completed',
      path: 'enrolment_form_completed',
      iconName: 'fal fa-fw fa-file-alt',
    },
  ],
};
