export const getDate = sentDate => {
  const date = new Date(sentDate);
  return date.toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  });
};

export const getDateTime = sentDate => {
  const date = new Date(sentDate);
  return date.toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const getLongDateTime = sentDate => {
  const date = new Date(sentDate);
  return date.toLocaleDateString(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const compareDate = sentDate => {
  const date1 = new Date();
  const date2 = new Date(sentDate);

  return date2.getTime() > date1.getTime();
};

export function howOld(birthDate: Date | string): string | null {
  const day = 1000 * 60 * 60 * 24;
  const year = day * 365;

  birthDate =
    typeof birthDate === 'string' ? new Date(Date.parse(birthDate)) : birthDate;

  const today = Math.floor(Date.now() / day) * day;
  const age = Math.floor((today - +birthDate) / year - 0.0001);

  if (age < 0) return null;

  switch (age) {
    case 0:
      return '0-1 yrs';
    case 1:
      return '1-2 yrs';
    case 2:
      return '2-3 yrs';
    case 3:
      return '3-4 yrs';
    case 4:
      return '4-5 yrs';
    default:
      return '5+ yrs';
  }
}

export const debounce = (fn, delay) => {
  let timeoutID;
  return function (...args) {
    if (timeoutID) clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const calculateAge = dateValue => {
  let months;
  if (!dateValue) return '';
  const dob = new Date(dateValue);
  const currentDate = new Date();
  const diff = currentDate.getTime() - dob.getTime();
  months = (currentDate.getFullYear() - dob.getFullYear()) * 12;
  months -= dob.getMonth();
  months += currentDate.getMonth();
  const year = Math.floor(parseInt(months) / 12);
  const month = months % 12;
  const days = Math.floor(diff / 86400000);
  const weeks = Math.floor(days / 7);
  if (year > 0) {
    const childAge = ` (${year} ${year > 1 ? 'years' : 'year'}, ${
      month > 0 ? `${month} ${month > 1 ? 'months' : 'month'})` : ''
    }`;
    return childAge;
  } else if (month > 0) {
    return ` (${month} ${month > 1 ? 'months' : 'month'})`;
  } else if (month > 0) {
    return ` (${weeks} ${weeks > 1 ? 'weeks' : 'week'})`;
  } else if (days > 0) {
    return ` (${days} ${days > 1 ? 'days' : 'day'})`;
  }
  return '';
};

export const calculateTime = dateValue => {
  let months;
  if (!dateValue) return '';
  const dob = new Date(dateValue);
  const currentDate = new Date();
  const diff = currentDate.getTime() - dob.getTime();
  months = (currentDate.getFullYear() - dob.getFullYear()) * 12;
  months -= dob.getMonth();
  months += currentDate.getMonth();
  const year = Math.floor(parseInt(months) / 12);
  const month = months % 12;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  if (minutes < 60)
    return `${minutes} ${minutes == 1 ? 'minute' : 'minutes'} ago`;
  else if (hours < 24) return `${hours} ${hours == 1 ? 'hour' : 'hours'} ago`;
  else if (days < 30) return `${days} ${days == 1 ? 'day' : 'days'} ago`;
  else
    return months && parseInt(months) > 12
      ? ` ${Math.abs(year)} ${year > 1 ? 'years' : 'year'}, ${
          month > 0
            ? ` ${Math.abs(month)} ${month > 1 ? 'months' : 'month'} ago`
            : ''
        }`
      : months && parseInt(months) < 12
      ? `${Math.abs(month)} ${month > 1 ? 'months' : 'month'} ago`
      : '';
};

export const fromNow = dateValue => {
  let months;
  if (!dateValue) return '';
  const valueDate = new Date(dateValue);
  const currentDate = new Date();
  const diff = valueDate.getTime() - currentDate.getTime();
  months = (currentDate.getFullYear() - valueDate.getFullYear()) * 12;
  months -= valueDate.getMonth();
  months += currentDate.getMonth();
  const year = Math.floor(parseInt(months) / 12);
  const month = months % 12;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (minutes < 60)
    return `${minutes} ${minutes == 1 ? 'minute' : 'minutes'} from now`;
  else if (hours < 24)
    return `${hours} ${hours == 1 ? 'hour' : 'hours'} from now`;
  else if (days < 30) return `${days} ${days == 1 ? 'day' : 'days'} from now`;
  else
    return months && parseInt(months) > 12
      ? ` ${Math.abs(year)} ${year > 1 ? 'years' : 'year'}, ${
          month > 0
            ? ` ${Math.abs(month)} ${month > 1 ? 'months' : 'month'} from now`
            : ''
        }`
      : months && parseInt(months) < 12
      ? `${Math.abs(month)} ${month > 1 ? 'months' : 'month'} from now`
      : '';
};
