import moment from 'moment';
import { IValidDateRange } from './DateTimePicker';

export default function getValidMomentRange(
  validDateRange?: IValidDateRange,
): [moment.Moment, moment.Moment] {
  let start = moment().subtract(1, 'day').endOf('day');
  let end = moment().endOf('year').add(500, 'year');

  if (!!validDateRange?.start) {
    start = moment(validDateRange.start.toFormat('yyyy-MM-dd'));
  }

  if (!!validDateRange?.end) {
    end = moment(validDateRange.end.toFormat('yyyy-MM-dd'));
  }

  return [start, end];
}
