import { IScheduler } from '@rapid/dhtmlx-scheduler';
import { Updater } from '@rapid/sdk';
import { DateTime } from 'luxon';
import {
  ISchedulerContext,
  ISchedulerContextReturn,
  TSchedulerTour,
} from '../SchedulerContext';

export function onEmptyClick(
  events: TSchedulerTour[],
  updateEvents: Updater<TSchedulerTour[]>,
  context: ISchedulerContextReturn,
  updateContext: Updater<ISchedulerContext>,
  timezone?: string,
) {
  return function _onEmptyClick(scheduler: IScheduler, date: Date) {
    const event = context.activeEvent;

    if (!!event && context.mode === 'reschedule') {
      const start_date = DateTime.fromJSDate(date);

      const end_date = start_date.plus({
        minutes: 30,
      });

      event.start_date = start_date.toJSDate();
      event.end_date = end_date.toJSDate();
      event.readonly = true;

      updateEvents(d => {
        const index = d.findIndex(i => i.id === context.activeEvent?.id) ?? -1;

        if (index !== -1) {
          d.splice(index, 1, event);
        }
      });

      updateContext(d => {
        d.activeEvent = undefined;
        d.mode = 'readonly';
      });

      scheduler.render();
    }
  };
}
