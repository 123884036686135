import { AnyListItem } from '@rapid/data-model';
import { Updater, useCurrentSiteEndpoint } from '@rapid/sdk';
import { useEffect, useState } from 'react';
import { ISchedulerContext } from '../Types/SchedulerTypes';
import getForms from '../Utils/getForms';

export default function useCDNForms(updateContext: Updater<ISchedulerContext>) {
  const ep = useCurrentSiteEndpoint();

  const [formIds, setFormIds] =
    useState<Record<'create-tour' | 'create-lead', number>>();

  useEffect(function getFormsEffect() {
    getForms().then(forms => {
      setFormIds(forms);
    });
  }, []);

  useEffect(
    function getSchemasEffect() {
      if (!!formIds) {
        ep.Lists['Adaptive Documents'].Items[formIds['create-tour']]
          .getJson()
          .then((res: AnyListItem) => {
            updateContext(d => {
              d.schemas.createTour = res.document?.body;
              d.schemas.createTourId = formIds['create-tour'];
            });
          })
          .catch((err: any) => {
            console.log(err);
          });

        ep.Lists['Adaptive Documents'].Items[formIds['create-lead']]
          .getJson()
          .then((res: AnyListItem) => {
            updateContext(d => {
              d.schemas.createLead = res.document?.body;
              d.schemas.createLeadId = formIds['create-lead'];
            });
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    },
    [formIds],
  );
}
