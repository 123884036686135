import { useViewContextCount } from '@rapid/sdk';
import React from 'react';

function BondPaid() {
  const [count, loading] = useViewContextCount();

  return (
    <>
      <th>Bond Paid</th>
      <td>{loading ? '...' : <span>{count > 0 ? 'YES' : 'NO'}</span>}</td>
    </>
  );
}

export default BondPaid;
