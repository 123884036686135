import { useMsal } from '@rapid/sdk';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Nag() {
  const ua = useMsal();

  function logout(ev: React.MouseEvent<HTMLAnchorElement>) {
    ev.preventDefault();
    const account = ua.getActiveAccount();

    ua.logout({
      account: account!,
      postLogoutRedirectUri: '/SignedOut'
    });
  }

  return (
    <div className="gce-width-expand">
      <div className="gce-alert-danger">
        <h3>
          <i className="fad fa-fw fa-exclamation-circle"></i>Hold up!
        </h3>

        <p>
          It looks like you are not logged in with one of the centre specific
          acccounts (e.g. cmcentrename@guardian.edu.au)
        </p>

        <p>
          If the centre specific account is not used your tailored My Centre and
          My Tasks will not function properly.
        </p>

        <p>
          <a className="gce-button gce-button-primary" onClick={logout}>
            Sign out
          </a>
        </p>

        <p>Area Managers and State Managers can proceed below.</p>

        <Link
          type="submit"
          className="gce-button gce-button-danger"
          to="/centres"
        >
          Proceed
        </Link>
      </div>
    </div>
  );
}
