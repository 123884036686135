import { IField } from '@rapid/data-model';
import { Spinner, useCurrentSiteEndpoint, useList, useListItemContext } from '@rapid/sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getBondsFromLead,
  getCentre,
  getCentreRooms,
  getChild,
  getFamily,
  getPrimaryContact,
  returnRapidResponse,
} from '../../common/utils/requests';
import Bond from '../../types/bond.interface';
import CentreRoom from '../../types/centre-room.interface';
import Centre from '../../types/centre.interface';
import Child from '../../types/child.interface';
import Contact from '../../types/contact.interface';
import Lead from '../../types/lead.interface';
import DynamicForm from '../dynamic-form/dynamic-form';
import { FormField } from '../dynamic-form/fields/form-field';
import NoteField from '../dynamic-form/fields/note-field/note-field';
import Family from '../../types/family.interface';

interface IEditOfferFormProps {
  onSubmit?(): void;
}

type State = {
  child?: Child | null;
  lead?: Lead | null;
  family?: Family | null;
  contact?: Contact | null;
  bond?: Bond | null;
  note?: string;
  centre?: Centre;
  centreRooms?: Array<CentreRoom>;
  updated?: Record<string, { formValue?: any; errors?: object }>;
};

type Params = { tourId: string; leadId: string; centreId: string };

const dayPickerField = {
  ColumnName: '__day_picker',
  Title: 'Days',
  FieldType: 'DayPicker' as any,
} as IField;

export default function EditOfferForm({ onSubmit }: IEditOfferFormProps) {
  const [contactList] = useList('Contacts');
  const [familyList] = useList('Families');
  const [leadList] = useList('Leads');
  const [childList] = useList('Children');
  const [bondList] = useList('Bonds');

  const params = useParams<Params>();
  const centreId = /^(\d+)/.exec(params.centreId)?.[0]!;
  const ep = useCurrentSiteEndpoint();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [{ item, fetchTimeline, fetchItem: _fetchItem, subContexts }] = useListItemContext();

  const [state, updateState] = useState<State>({});

  const title = useMemo(() => {
    return !state.bond?.id && !state.lead?.offered ? 'Send Offer' : 'Edit Offer';
  }, [state.bond?.id, state.lead?.id]);

  const onMount = useCallback(async lead => {
    const centre = await getCentre(ep, +centreId);
    const centreRooms = await getCentreRooms(ep, +centreId);
    const bonds = await getBondsFromLead(ep, +centreId, +params.leadId);
    const child = await getChild(ep, lead?.child_id);
    const family = await getFamily(ep, lead.family_id);
    const contact = await getPrimaryContact(ep, lead?.primary_contact_id);

    updateState({
      bond: bonds?.[0] ?? null,
      centre,
      centreRooms,
      child,
      contact,
      family,
      lead,
      note: '',
      updated: {},
    });
    setLoading(false);
  }, []);

  const hasError = Object.values(state.updated ?? {}).some(v => {
    return Object.values(v?.errors ?? {}).some(err => !!err);
  });

  const onNoteUpdate = (event: any) => {
    updateState(draft => {
      draft.note = event?.target?.value;
      return { ...draft };
    });
  };

  const onFormUpdated = (key: string) => (formValue, errors) => {
    updateState(draft => ({
      ...draft,
      updated: { ...draft.updated, [key]: { formValue, errors } },
    }));
  };

  /**
   * Form Steps:
   *
   * 1: Update Lead + Set WaitList Items
   * 2: Create or Update Contact,
   * 3: Create or Update Family, `family_name: contact.full_name`
   * 4: Update Contact with, `family_id: family.id`
   * 5: Create or Update Child, `family_id: family.id`
   * 6: Update the lead with, `family_id: family.id`, `primary_contact_id: contact.id`, `child_id: child.id`
   *
   */
  const onSubmitForm = async () => {
    setSubmitting(true);
    const leadEp = ep.Lists.Leads;
    const childEp = ep.Lists.Children;
    const familyEp = ep.Lists.Families;
    const contactEp = ep.Lists.Contacts;
    const bondEp = ep.Lists.Bonds;

    let lead = state.updated!.lead.formValue as Lead;
    let child = (state.updated!.child?.formValue as Child) ?? {};
    let contact = (state.updated!.contact?.formValue as Contact) ?? {};
    let family = (state.updated!.family?.formValue as Family) ?? {};
    let bond = (state.updated!.bond?.formValue as Bond) ?? {};

    // Step 1
    if (!lead.id) return;
    const updatedLead = await leadEp.Items[lead.id].putJson(undefined, lead);
    lead = returnRapidResponse(updatedLead);

    try {
      const waitlistItems = await ep.Lists.Waitlists.All.Items.getJson({
        $filter: `lead_id eq ${lead.id} and centre_id eq ${centreId}`,
      });

      for (const waitlistItem of waitlistItems?.value ?? []) {
        await ep.Lists.Waitlists.Items[waitlistItem.id].putJson(undefined, {
          status: 'Closed',
        });
      }

      const tours = await ep.Lists.Tour.All.Items.getJson({
        linkedTo: `Leads/${lead.id}`,
        $filter: `centre_id eq ${centreId}`,
      });

      for (const tour of tours?.value ?? []) {
        await ep.Lists.Tour.Items[tour.id].putJson(undefined, {
          on_waitlist: false,
        });
      }
    } catch (e) {}

    // Step 2
    if (contact.id) {
      contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));
    } else {
      contact = returnRapidResponse(await contactEp.Items.postJson(undefined, contact));
    }

    family.family_name =
      contact.full_name ?? `${contact.first_name ?? ''} ${contact.last_name ?? ''}`;

    // Step 3
    if (family.id) {
      family = returnRapidResponse(await familyEp.Items[family.id].putJson(undefined, family));
    } else {
      family = returnRapidResponse(await familyEp.Items.postJson(undefined, family));
    }

    // Step 4
    contact.family_id = family.id;
    child.family_id = family.id;
    contact = returnRapidResponse(await contactEp.Items[contact.id].putJson(undefined, contact));

    // Step 5
    if (child.id) {
      child = returnRapidResponse(await childEp.Items[child.id].putJson(undefined, child));
    } else {
      child = returnRapidResponse(await childEp.Items.postJson(undefined, child));
    }

    // Step 6
    lead.child_id = child.id;
    lead.family_id = family.id;
    lead.primary_contact_id = contact.id;
    await ep.Lists.Leads.Items[lead.id].putJson(undefined, lead);

    // Bond needs to go last
    bond.child_id = child.id;
    bond.centre_id = +centreId;
    bond.lead_id = lead.id;
    bond.start_date = lead.start_date;
    bond.monday = lead.monday;
    bond.tuesday = lead.tuesday;
    bond.wednesday = lead.wednesday;
    bond.thursday = lead.thursday;
    bond.friday = lead.friday;

    bond = returnRapidResponse(await bondEp.Items.postJson(undefined, bond));

    if (state.note) {
      const linkedItemsToAdd: string[] = [];
      const inheritedLinksToadd: string[] = await ep.Lists.Leads.Items[lead.id][
        'inherited-links'
      ].getJson({ type: 'Notes' });

      if (inheritedLinksToadd?.length) linkedItemsToAdd.push(...inheritedLinksToadd);

      linkedItemsToAdd.push(`lead/${lead.id}`);

      if (params.tourId) linkedItemsToAdd.push(`tour/${params.tourId}`);
      if (bond.id) linkedItemsToAdd.push(`bond/${bond.id}`);

      const noteData = { LinkedItemsToAdd: linkedItemsToAdd, body: state.note };

      await ep.Lists.Notes.Items.postJson(undefined, noteData);
    }

    // End
    setSubmitting(false);
    _fetchItem();
    fetchTimeline?.(true);
    onSubmit?.();
  };

  useEffect(() => {
    ep.Lists.Leads.All.Items[+params.leadId].getJson().then(lead => {
      onMount(lead);
    });
  }, []);

  if (loading || !state.lead) {
    return <Loader />;
  }

  return (
    <div className="gce-component-container gce-margin-medium-top">
      {submitting && <Loader />}
      {title === 'Send Offer' && <h2 className="gce-text-center">Send Offer</h2>}

      {title === 'Send Offer' && (
        <div className="gce-custom-alert font-size-norm">
          Before sending an offer, please review the existing family and child details below,
          including DOB, to ensure that all information required to create the QK Shell File is
          accurate. You're able to update the information in any field and add to a blank field (new
          information). When you press the SEND OFFER button, the information is saved and will
          update the family details at the top of the lead in addition to sending the offer.
        </div>
      )}

      {title === 'Edit Offer' && (
        <div className="gce-custom-alert font-size-norm">
          When editing an existing offer, please review the existing family and child details below,
          including DOB, to ensure that all information required to create the QK Shell File is
          accurate. You're able to update the information in any field. When you press the EDIT
          OFFER button, the information is saved and will update the family details at the top of
          the lead in addition to sending the new offer.
        </div>
      )}

      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Contact details</h3>
        </div>
        <DynamicForm
          fields={familyList.Fields ?? []}
          initialValue={state.family! ?? {}}
          onUpdate={onFormUpdated('family')}
        ></DynamicForm>
        <DynamicForm
          fields={contactList.Fields ?? []}
          initialValue={state.contact ?? {}}
          onUpdate={onFormUpdated('contact')}
        >
          <FormField name="first_name" className="gce-width-1-2@m" required />
          <FormField name="last_name" className="gce-width-1-2@m" required />
          <FormField name="mobile" className="gce-width-1-2@m" required />
          <FormField name="email" className="gce-width-1-2@m" required />
        </DynamicForm>
      </div>
      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Child details</h3>
        </div>
        <DynamicForm
          fields={childList.Fields ?? []}
          initialValue={state.child! ?? {}}
          onUpdate={onFormUpdated('child')}
        >
          <FormField name="first_name" className="gce-width-1-2@m" required />
          <FormField name="last_name" className="gce-width-1-2@m" required />
          <FormField name="dob" label="Date of birth" className="gce-width-1-2@m" required />
          <FormField name="gender" className="gce-width-1-2@m" required />
        </DynamicForm>
      </div>
      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Enrolment details</h3>
        </div>
        <DynamicForm
          fields={[...leadList.Fields, dayPickerField]}
          initialValue={state.lead ?? {}}
          onUpdate={onFormUpdated('lead')}
        >
          <FormField name="start_date" className="gce-width-1-4@m" required />
          <FormField name="total_days" className="gce-width-1-4@m" required />

          <DynamicForm
            fields={bondList.Fields ?? []}
            initialValue={state.bond! ?? {}}
            onUpdate={onFormUpdated('bond')}
          >
            <FormField
              name="room_id"
              className="gce-width-1-4@m"
              required
              extra={{ $filter: !!centreId ? `centre_id eq '${centreId}'` : undefined }}
            />
          </DynamicForm>

          <FormField name="__day_picker" className="gce-width-1-4@m" required />
        </DynamicForm>
      </div>
      <div className="gce-grid gce-margin">
        <div className="gce-width-1-1 gce-margin-top">
          <h3>Notes</h3>
        </div>
        <NoteField
          label="Anything else we need to know?"
          id="additional_notes"
          value={state.note}
          onChange={onNoteUpdate}
        />

        <div className="gce-width-1-1 gce-margin-top gce-text-center">
          <button
            disabled={hasError}
            className={`gce-button ${!hasError ? 'gce-button-darkgreen' : 'gce-button-green'}`}
            type="submit"
            onClick={onSubmitForm}
          >
            {title}
          </button>
        </div>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className="EditOfferSpinner" style={{ zIndex: 9999 }}>
      <div className="EditOfferSpinner__card rp-card-default">
        <h3>Loading...</h3>
        <div className="EditOfferSpinner__spin-container">
          <Spinner />
        </div>
      </div>
    </div>
  );
}
