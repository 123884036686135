import React from 'react';
import { days } from '../DayPicker';
import { DatePicker, Select } from 'antd';
import { IChoice } from '@rapid/data-model';
import moment from 'moment';

interface Props {
  type?: string;
  formDatas: any[];
  options?: Record<string, IChoice[]> | null;
  data?: any;
  errors?: any;
  handleChange: (event: unknown) => void;
}

export default function DynamicForm({
  type,
  formDatas,
  options,
  data,
  handleChange,
  errors,
}: Props) {
  let returnForm: any = formDatas.map(item => {
    let { divClass, label } = item;
    let selectOptions;

    if (options?.[item.name] && options?.[item.name]?.length > 0) {
      selectOptions = options?.[item.name]?.map(o => ({
        value: o.Text,
        label: o.Text,
      }));
    }

    let value = data?.[item.name] ?? '';

    function onChangeInternal(value: string | moment.Moment | null) {
      handleChange?.({
        target: {
          value: moment.isMoment(value) ? value.toISOString() : value,
          name: item.name,
          type,
        },
      });
    }

    switch (item.type) {
      case 'dropdown_list':
        return (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-form-label" htmlFor={item.name}>
              {label}
            </label>
            {item.required && (
              <span>
                <span className="gce-text-medium gce-override-red">*</span>
              </span>
            )}
            {selectOptions && (
              <Select
                className={`gce-input ${
                  errors && errors[item.name] ? 'input-validation-error' : ''
                }`}
                id={item.name}
                options={selectOptions}
                onSelect={onChangeInternal}
                value={value}
              />
            )}
            {errors && errors[item.name] ? (
              <span className="tm-text-danger tm-text-small field-validation-error">
                <span id={`${item.name}-error`}>{errors[item.name]}</span>
              </span>
            ) : null}
          </div>
        );
      case 'obj_dropdown':
        return selectOptions ? (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-form-label" htmlFor={item.name}>
              {label}
            </label>
            {item.required && (
              <span>
                <span className="gce-text-medium gce-override-red">*</span>
              </span>
            )}
            <Select
              className={`gce-input ${
                errors && errors[item.name] ? 'input-validation-error' : ''
              }`}
              options={selectOptions}
              id={item.name}
              value={value}
              onSelect={onChangeInternal}
            />
            {errors && errors[item.name] ? (
              <span className="tm-text-danger tm-text-small field-validation-error">
                <span id={`${item.name}-error`} className="">
                  {errors[item.name]}
                </span>
              </span>
            ) : null}
          </div>
        ) : null;

      case 'datepicker':
        value = value ? moment(value) : null;
        return (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-form-label" htmlFor={item.name}>
              {label}
            </label>
            {item.required && (
              <span>
                <span className="gce-text-medium gce-override-red">*</span>
              </span>
            )}
            {React.createElement(DatePicker as any, {
              className: `gce-input ${
                errors && errors[item.name] ? 'input-validation-error' : ''
              }`,
              id: item.name,
              placeholder: '',
              required: item.required,
              format: 'DD/MM/YYYY',
              value,
              onChange: onChangeInternal,
            })}
            {errors && errors[item.name] ? (
              <span className="tm-text-danger tm-text-small field-validation-error">
                <span id={`${item.name}-error`}>{errors[item.name]}</span>
              </span>
            ) : null}
          </div>
        );
      case 'text':
        return (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-form-label" htmlFor={item.name}>
              {label}
            </label>
            {item.required && (
              <span className="required">
                <span className="gce-text-medium">*</span>
              </span>
            )}
            <input
              className={`gce-input ${
                errors && errors[item.name] ? 'input-validation-error' : ''
              }`}
              data-label={item.label}
              required={item.required}
              type={item.inputType}
              id={`${type}_${item.name}`}
              name={item.name}
              value={value}
              onChange={handleChange}
            />
            {errors && errors[item.name] ? (
              <span className="tm-text-danger tm-text-small field-validation-error">
                <span id={`${item.name}-error`} className="">
                  {errors[item.name]}
                </span>
              </span>
            ) : null}
          </div>
        );

      case 'dayCheckbox':
        return (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-form-label">{label}</label>
            {item.required && (
              <span className="required">
                <span className="gce-text-medium">*</span>
              </span>
            )}
            <div className="">
              {days.map(elm => (
                <label key={elm.value} className="gce-check-day">
                  {elm.label}
                  <input
                    name={elm.value}
                    value={elm.value}
                    type="checkbox"
                    onChange={handleChange}
                    checked={data[elm.value]}
                  />
                  <span className="checkmark"></span>
                </label>
              ))}
            </div>
          </div>
        );
      case 'checkbox':
        return (
          <div key={item.name} className={`gce-width-1-1@s ${divClass}`}>
            <label className="gce-check">
              {label}
              <input
                name={item.name}
                type="checkbox"
                onChange={handleChange}
                value={value ?? false}
                checked={data ? data[item.name] : false}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        );
      default:
        return null;
    }
  });

  return returnForm ? returnForm : null;
}
