import { AnyListItem, Updater } from '@rapid/data-model';
import { ILead } from '../../../Types/ItemTypes';
import { TLightboxMode, TSchedulerTour } from '../../../Types/SchedulerTypes';
import { linkedLeadsTag } from '../../../Utils/SchedulerSymbols';

export default function selectLead(
  lead: AnyListItem<ILead>,
  single: boolean = false,
  updateEvent: Updater<Partial<TSchedulerTour>>,
  setLightboxMode: (mode: TLightboxMode) => void,
) {
  updateEvent?.(d => {
    if (!Array.isArray(d[linkedLeadsTag])) {
      d[linkedLeadsTag] = [];
    }

    const ind =
      d[linkedLeadsTag].findIndex(
        (l: AnyListItem<ILead>) => l.id === lead.id,
      ) ?? -1;

    if (ind !== -1) {
      d[linkedLeadsTag].splice(ind, 1);
      return;
    }

    d[linkedLeadsTag].push(lead);
  });

  if (single) {
    setLightboxMode('create-tour');
  }
}
