import React from 'react';
import './day-picker.scss';
import { useComposedClassName } from '@rapid/sdk';

interface IDayPickerProps {
  label: string;
  error?: string;
  className?: string;
  id?: string;
  onChange: (value: string, toggle: boolean) => void;
  value: Record<string, boolean>;
  required?: boolean;
}

export const days = [
  { label: 'mon', value: 'monday' },
  { label: 'tue', value: 'tuesday' },
  { label: 'wed', value: 'wednesday' },
  { label: 'thu', value: 'thursday' },
  { label: 'fri', value: 'friday' },
];

function DayPicker({ className, label, error, id, required, onChange, value }: IDayPickerProps) {
  const cn = useComposedClassName(
    function* () {
      yield 'day-picker field-container';
      if (className) yield className;
    },
    [className],
  );

  function onChangeInternal(ev: React.ChangeEvent<HTMLInputElement>) {
    onChange(ev.target.name, !value[ev.target.name]);
  }

  return (
    <div className={cn} data-required={!!required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      <div className="day-picker__days">
        {days.map(elm => (
          <label key={elm.value} className="day-picker__day gce-check-day">
            {elm.label}
            <input
              name={elm.value}
              value={elm.value}
              type="checkbox"
              onChange={onChangeInternal}
              checked={!!value[elm.value]}
            />
            <span className="checkmark"></span>
          </label>
        ))}
      </div>

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default DayPicker;
