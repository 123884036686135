import { AnyListItem } from '@rapid/data-model';
import { ManagedViewContext, useCurrentSiteEndpoint } from '@rapid/sdk';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { LeadItemCell, leadItems } from '../untils';
import BondPaid from './EnquiryStageFields/BondPaid';
import Offered from './EnquiryStageFields/Offered';
import Toured from './EnquiryStageFields/Toured';
import Waitlisted from './EnquiryStageFields/Waitlisted';
import TourDateTime from './TourDateTime';

type ParamProps = {
  centreId?: string;
  leadId?: string;
};

interface ILeadDetailProps {
  lead: AnyListItem;
}

const tourColumns: string[] = [
  // 'booking_id',
  // 'family_id',
  // 'child_name',
  // 'parent_name',
  // 'parent_mobile',
  // 'start_date',
  // 'child_dob',
  'tour_booking_date_and_time',
  // 'tour_status',
  // 'tour_lead_id',
  'id',
];

export default function LeadDetail({ lead }: ILeadDetailProps) {
  const match = useRouteMatch<ParamProps>();

  const siteEp = useCurrentSiteEndpoint();

  const [waitlisted, setWaitlisted] = useState<AnyListItem>();

  useEffect(function fetchWaitlistedOnMount() {
    (siteEp.Lists.Waitlists.All as any).items
      .getJson({
        linkedTo: `Leads/${lead.id}`,
      })
      .then(res => {
        setWaitlisted(res.value[0]);
      });
  }, []);

  const leadStartDate = useMemo(
    () => lead && DateTime.fromISO(lead.start_date),
    [lead],
  );

  return (
    <div
      id="waitlistItemDetailsContainer"
      className="gce-component-container"
      style={{ position: 'relative' }}
    >
      <div className="gce-grid">
        <div className="gce-width-1-2@s gce-width-1-3@m">
          <table className="gce-table-detail">
            <tbody>
              {leadItems.detail.map(cell => {
                const Cell = cell.Content ?? LeadItemCell;

                return (
                  <tr key={cell.label}>
                    <th>
                      <i className={cell.iconName} />
                    </th>
                    <th>{cell.label}</th>

                    <Cell item={lead} cell={cell} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="gce-width-1-2@s gce-width-1-3@m">
          <table className="gce-table-detail">
            <tbody>
              <tr>
                <th>
                  <i className="fal fa-fw fa-calendar-star"></i>
                </th>
                <th>Start Date</th>
                <td>
                  <span>
                    {leadStartDate?.isValid &&
                      leadStartDate.toLocaleString(DateTime.DATE_SHORT)}
                  </span>
                  <small>
                    &nbsp;(
                    {leadStartDate?.isValid && leadStartDate.toRelative()})
                  </small>
                </td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top' }}>
                  <i className="fal fa-fw fa-calendar-week"></i>
                </th>
                <th style={{ verticalAlign: 'top' }}>Days</th>
                <td>
                  <span>
                    {lead.total_days ? <span>{lead.total_days} : </span> : ''}
                    <span>{lead.monday ? 'M' : '-'}</span>{' '}
                    <span>{lead.tuesday ? 'T' : '-'}</span>{' '}
                    <span>{lead.wednesday ? 'W' : '-'}</span>{' '}
                    <span>{lead.thursday ? 'T' : '-'}</span>{' '}
                    <span>{lead.friday ? 'F' : '-'}</span>{' '}
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  <i className="fal fa-fw fa-map-signs"></i>
                </th>
                <th>Tour Date</th>

                <td>
                  <ManagedViewContext
                    list="Tours"
                    linkedTo={`Leads/${lead.id}`}
                    columns={tourColumns}
                  >
                    <TourDateTime />
                  </ManagedViewContext>
                </td>
              </tr>
              <tr>
                <th>
                  <i className="fal fa-fw fa-inbox-in"></i>
                </th>
                <th>Source</th>
                <td>{lead.enquiry_source}</td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top' }}>
                  <i className="fal fa-fw fa-file-alt"></i>
                </th>
                <th style={{ verticalAlign: 'top' }}>
                  Enrolment Form
                  <br />
                  Completed
                </th>
                <td style={{ verticalAlign: 'top' }}>
                  {lead.enrolment_form_completed ? 'YES' : 'NO'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="gce-width-1-1@s gce-width-1-3@m">
          <table className="gce-table-detail">
            <tbody>
              <tr>
                <th>
                  <i className="fal fa-fw fa-user-circle"></i>
                </th>
                <th>Enrolment Officer</th>
                <td>{lead.enrolment_officer}</td>
              </tr>
            </tbody>
          </table>

          <div className="gce-enquiry-stage">
            <div>
              <i
                className="fal fa-fw fa-project-diagram"
                style={{ marginRight: '10px' }}
              ></i>
              <span style={{ fontWeight: 'bold' }}>Enquiry Stage</span>
            </div>

            <table className="gce-table-detail">
              <tbody>
                <tr>
                  <td>
                    <table className="gce-table-detail">
                      <tbody>
                        <tr>
                          <th>
                            <i className="fal fa-fw fa-map-signs"></i>
                          </th>
                          <ManagedViewContext
                            list="Tours"
                            linkedTo={`Leads/${lead.id}`}
                            filter={`tour_status eq 'attended'`}
                            count
                          >
                            <Toured />
                          </ManagedViewContext>
                        </tr>
                        <ManagedViewContext
                          list="Bonds"
                          linkedTo={`Leads/${lead.id}`}
                          count
                        >
                          <Offered />
                        </ManagedViewContext>
                        <tr>
                          <ManagedViewContext
                            list="Waitlists"
                            linkedTo={`Leads/${lead.id}`}
                            filter="status eq 'Open'"
                            count
                          >
                            <Waitlisted />
                          </ManagedViewContext>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ display: 'block', marginLeft: '10px' }}>
                    <table className="gce-table-detail">
                      <tbody>
                        <tr>
                          <th>
                            <i className="fal fa-fw fa-dollar-sign"></i>
                          </th>
                          <ManagedViewContext
                            list="Bonds"
                            linkedTo={`Leads/${lead.id}`}
                            filter="status eq 'Paid'"
                            count
                          >
                            <BondPaid />
                          </ManagedViewContext>
                        </tr>
                        <tr>
                          <th>
                            <i className="fal fa-fw fa-calendar-check"></i>
                          </th>
                          <th>Confirmed Start</th>
                          <td>
                            <span>
                              {lead.enquiry_closure &&
                              lead.enquiry_closure.toLowerCase() ===
                                'Confirmed Enrolment'.toLowerCase()
                                ? 'YES'
                                : 'NO'}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Link
          className="gce-button gce-button-blue"
          to={`${match.url.replace(/\/*$/g, '')}/edit`}
        >
          <i className="fal fa-fw fa-edit"></i> Edit
        </Link>
      </div>
    </div>
  );
}
