import React from 'react';
import {
  AttachmentsContext,
  ManagedListItemContext,
  useListItemContext,
} from '@rapid/sdk';
import { Spinner } from '@rapid/sdk/lib/ui';
import { useRouteMatch } from 'react-router-dom';
import { useCentreAlias } from '../../Store';
import Breadcrumb from '../common/Breadcrumb';
import BreadcrumbItem from '../common/BreadcrumbItem';
import TaskDetail from './TaskDetail';
import TaskCards from './TaskCards';
import EmbeddedForm from '../EmbeddedForm';
import { IEmbeddedForm } from '@rapid/data-model';
import Timeline from '../Timeline/Timeline';
import { useEffect } from 'react';

interface ITaskProfileRoute {
  centreId: string;
  tab: string;
  taskId: string;
}

export default function TaskProfile() {
  const [{ item: task, saveItem: saveTask, loading }] = useListItemContext();
  const match = useRouteMatch<ITaskProfileRoute>();

  const centreAlias = useCentreAlias();

  useEffect(
    function autoSaveEffect() {
      if (task?.id && !loading) {
        saveTask?.(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [task],
  );

  if (!task) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { key: 'centre', url: '/centres', displayName: 'Centres' },
          {
            key: 'centre_alias',
            url: `/${match.params.centreId}/${match.params.tab}`,
            displayName: (
              <BreadcrumbItem icon="fa-home">{centreAlias}</BreadcrumbItem>
            ),
          },
          {
            key: 'lead',
            displayName: (
              <BreadcrumbItem icon="fa-child">
                {task?.title ?? 'TBC'}
              </BreadcrumbItem>
            ),
          },
        ]}
      />

      <div className="gce-container">
        <TaskDetail task={task} onSave={() => saveTask?.(true)} />
       
        {task.embedded_form?.map((f: IEmbeddedForm, i: number) => (
          <ManagedListItemContext
            validate
            key={`${f.FormRef.Type}/${f.FormRef.Id ?? i}`}
            list={f.FormRef.Type}
            id={f.FormRef.Id}
          >
            <EmbeddedForm form={f} />
          </ManagedListItemContext>
        ))}
        <TaskCards />
        <Timeline />
      </div>
    </>
  );
}
