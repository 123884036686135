import { AnyListItem } from '@rapid/data-model';
import { Updater, useCurrentSiteEndpoint, useImmer } from '@rapid/sdk';
import React, { useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import ContactSelectionSearch from './ContactSelectionSearch/ContactSelectionSearch';
import { Button } from 'antd';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import { IContact } from '../../../Types/ItemTypes';
import { linkedParentTag } from '../../../Utils/SchedulerSymbols';
import ContactCard from './ContactCard';
import { useContactSelectionMessages } from '../Message/UseMessages';
import Message from '../Message/Message';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import '../SelectContactOrLead.scss';
import './ContactSelection.scss';

export interface IContactFilter {
  mobile: string;
  email: string;
}

interface IContactSelectionProps {
  event?: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  onCreateLead(): void;
  setContactSelected(isSelected: boolean): void;
}

export default function ContactSelection({
  event,
  updateEvent,
  onCreateLead,
  setContactSelected,
}: IContactSelectionProps) {
  const ep = useCurrentSiteEndpoint();

  const { catchLightboxError, setLightboxMode } = useLightbox();

  const [contactLoading, setContactLoading] = useState(false);

  const [contact, setContact] = useState<AnyListItem<IContact>>();

  const onSelectContact = (selectedContact: AnyListItem<IContact>) => {
    setContact(selectedContact);
    setContactSelected(true);

    updateEvent(d => {
      d[linkedParentTag] = selectedContact;
    });

    setLightboxMode('select-lead');
  };

  const onSearchContacts = async () => {
    setContactLoading(true);

    let resp: any;

    resp = await ep.Lists.Contact.All$.Items.getJson({
      $filter: `email eq '${filter.email}'`,
    }).catch(catchLightboxError('notify'));

    if (!!resp.value?.length) {
      setContactList(resp.value);
      setContactLoading(false);
      return;
    } else {
      resp = await ep.Lists.Contact.All$.Items.getJson({
        $filter: `mobile eq '${filter.mobile}'`,
      }).catch(catchLightboxError('notify'));
    }

    setContactList(resp.value ?? []);
    setContactLoading(false);
  };

  const [contactList, setContactList] = useState<AnyListItem<IContact>[]>();

  const [filter, updateFilter] = useImmer<IContactFilter>({
    mobile: '',
    email: '',
  });

  const onFilterChange =
    (key: keyof IContactFilter) => (e: React.ChangeEvent<HTMLInputElement>) => {
      updateFilter(d => {
        d[key] = e.target.value;
      });
    };

  const onClearFilter = () => {
    setContactList(undefined);
    updateFilter(d => {
      d.mobile = '';
      d.email = '';
    });
  };

  const message = useContactSelectionMessages(
    contact,
    contactLoading,
    filter,
    contactList,
    [contact, contactLoading, filter, contactList],
  );

  const noContacts =
    !contact && !!contactList && !contactList.length && !contactLoading;

  const withTerm =
    !contact && !contactList && !!filter?.mobile && !contactLoading;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!filter.email && !!filter.mobile) {
      if (e.key === 'Enter') {
        noContacts ? onCreateLead() : onSearchContacts();
      }

      if (e.key === 'Escape') {
        onClearFilter();
      }
    }

    if (e.key === 'Escape') {
      onClearFilter();
    }
  };

  const ContactRow = ({ index, style }) => {
    return (
      <div
        className={`Contact${index % 2 === 0 ? ' even' : ' odd'}`}
        key={contactList?.[index].id}
        style={style}
      >
        <ContactCard
          contact={contactList?.[index]}
          onContactClick={onSelectContact}
        />
      </div>
    );
  };

  return (
    <div className="ContactSelection SelectionContainer">
      <ContactSelectionSearch
        filter={filter}
        onChange={onFilterChange}
        onClear={onClearFilter}
        onKeyDown={onKeyDown}
      />

      {!!contactList && !!contactList.length && (
        <div className="ContactsList">
          <List
            itemSize={() => 50}
            width={400}
            height={300}
            itemCount={contactList.length}
          >
            {ContactRow}
          </List>
        </div>
      )}

      {message.show && <Message {...message} />}

      <div className="ContactSelectionActions">
        {withTerm && (
          <Button
            type="primary"
            className="Button Search"
            onClick={onSearchContacts}
          >
            Search contacts
          </Button>
        )}

        {noContacts && (
          <Button
            onClick={onCreateLead}
            type="primary"
            className="Button Create"
          >
            Create A New Child
          </Button>
        )}
      </div>
    </div>
  );
}
