import { Updater } from '@rapid/sdk';
import { Button } from 'antd';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { TLightboxMode, TSchedulerTour } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import './SelectTime.scss';
import '../Tour/TourTime.scss';
import DateTimePicker, {
  IValidDateRange,
} from '../../DateTimePicker/DateTimePicker';
import { IValidTimeRange } from '../../DateTimePicker/TimePicker';
import OffsetTime from '../../../Utils/offsetTime';

interface ISelectTimeProps {
  event?: Partial<TSchedulerTour>;
  updateEvent: Updater<Partial<TSchedulerTour>>;
  setLightboxMode(mode: TLightboxMode): void;
  timezone?: string;
}

export default function SelectTime({
  event,
  updateEvent,
  setLightboxMode,
  timezone,
}: ISelectTimeProps) {
  const [date, setDate] = useState<DateTime>();

  const onSelectTime = () => {
    updateEvent(d => {
      d.start_date = date
        ? OffsetTime(date, timezone, 'Local to Zone').setZone(timezone)
        : date;
      d.end_date = date
        ? OffsetTime(date, timezone, 'Local to Zone')
            ?.setZone(timezone)
            .plus({ minute: 30 })
        : date;
    });

    setLightboxMode('select-contact-or-lead');
  };

  const onChange = (_value?: DateTime) => {
    setDate(_value);
  };

  const validTimes: IValidTimeRange = { start: '08:00', end: '17:30' };
  const validDates: IValidDateRange = useMemo(function getValidDateStart() {
    let now = DateTime.now();

    if (now.minute >= 30) {
      now.startOf('hour').set({ minute: 30 });
    } else {
      now.startOf('hour');
    }

    return {
      start: now,
    };
  }, []);

  return (
    <div className="SelectTime">
      <Message
        title="Select the start time for one off tour"
        subTitle="Use the inputs below to select the start date and time for the tour"
        icon="fad fa-fw fa-calendar-clock"
      />

      <div className="FormWrapper">
        <DateTimePicker
          value={date}
          validDateRange={validDates}
          validTimeRange={validTimes}
          onChange={onChange}
          label="Start Date & Time"
        />
      </div>

      <div className="TourTime">
        <div className="Time">
          <span className="Start">{date?.toFormat('h:mm a') ?? '(Unset)'}</span>
          &ensp;-&ensp;
          <span className="End">
            {date?.plus({ minute: 30 }).toFormat('h:mm a') ?? '(Unset)'}
          </span>
        </div>
        <div className="Date">{date?.toFormat('DDDD') ?? '(Unset)'}</div>
      </div>

      <div className="OneOffActions">
        <Button
          disabled={!date}
          onClick={onSelectTime}
          type="primary"
          className="Button Select"
        >
          Select time
        </Button>
      </div>
    </div>
  );
}
