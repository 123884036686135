import React from 'react';
import { KIconTypes, useComposedClassName } from '@rapid/sdk';

interface IDetailItemProps {
  iconName: KIconTypes;
  title: string;
  value?: React.ReactNode;
  className?: string;
}
export default function DetailItem({
  iconName,
  title,
  value,
  className
}: IDetailItemProps) {
  const detailItemClass = useComposedClassName(function* () {
    yield 'gce-table-detail-row';

    if (className) yield className;
  }, []);

  return (
    <tr className={detailItemClass}>
      <th className="gce-table-detail-icon">
        <i className={`gce-table-icon fal ${iconName}`} />
      </th>
      <th className="gce-table-detail-title">{title}</th>
      <td className="gce-table-detail-value">
        {value ?? <span className="gce-text-muted"><em className="gce-text-meta">Unset</em></span>}
      </td>
    </tr>
  );
}
