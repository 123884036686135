import { AnyListItem } from '@rapid/data-model';
import {
  useCurrentSiteEndpoint,
  useImmer,
  useListItemContext,
  useOmnichannelEndpoint,
} from '@rapid/sdk';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useBlockingContext } from '../../../Contexts/SchedulerContext/SchedulerContext';
import { TLightboxOnClose } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import { DateTime } from 'luxon';
import BlockingForm from './BlockingForm';
import './Blocking.scss';
import './CreateBlocking.scss';
import OffsetTime from '../../../Utils/offsetTime';

interface ICreateBlockingProps {
  onCloseLightbox(onClose: TLightboxOnClose): void;
  timezone?: string;
}

export default function CreateBlocking({
  onCloseLightbox,
  timezone,
}: ICreateBlockingProps) {
  const siteEp = useCurrentSiteEndpoint();
  const omniEp = useOmnichannelEndpoint();
  const [, , fetchAvailability] = useBlockingContext();

  const [centre] = useListItemContext();

  const [creating, setCreating] = useState(false);

  const [cm, setCm] = useState<AnyListItem>();

  useEffect(
    function getCMOnMount() {
      if (!!centre.item?.cm_task_id) {
        siteEp.Lists.Principals.items[centre.item.cm_task_id]
          .getJson()
          .then((res: AnyListItem) => setCm(res));
      }
    },
    [centre.item?.cm_task_id],
  );

  const [blocking, updateBlocking] = useImmer({
    subject: '',
    type: 'singleInstance',
    bodyPreview: '',
    body: {
      content: '',
      contentType: 'html',
    },
    start: {
      dateTime: undefined as string | undefined,
      timeZone: 'UTC',
    },
    end: {
      dateTime: undefined as string | undefined,
      timeZone: 'UTC',
    },
    organizer: {
      emailAddress: {
        address: '',
        name: '',
      },
    },
    attendees: [
      {
        emailAddress: {
          address: '',
          name: '',
        },
        type: 'required',
      },
    ],
  });

  useEffect(
    function addPrincipalDetailsOnLoad() {
      if (!!cm) {
        updateBlocking(d => {
          d.organizer.emailAddress.address = cm.email;
          d.organizer.emailAddress.name = cm.display_name;

          d.attendees[0].emailAddress.address = cm.email;
          d.attendees[0].emailAddress.name = cm.display_name;
        });
      }
    },
    [cm],
  );

  const onBlockTimeslot = async () => {
    if (!blocking.start.dateTime || !blocking.end.dateTime) {
      return;
    }
    
    setCreating(true);

    blocking.start.dateTime = OffsetTime(DateTime.fromISO(blocking.start.dateTime), timezone, 'Local to Zone').toUTC().toISO();
    blocking.end.dateTime = OffsetTime(DateTime.fromISO(blocking.end.dateTime), timezone, 'Local to Zone').toUTC().toISO();

    await omniEp.services['rapid-meetings'].event.outlook[
      cm?.email ?? cm?.upn
    ].create.post(
      {},
      {
        event: blocking,
      },
    );

    fetchAvailability();
    setCreating(true);
    onCloseLightbox('close');
  };

  const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateBlocking(d => {
      d.subject = e.target.value;
    });
  };

  const onDateChange = (key: 'start' | 'end') => (date: DateTime) => {
    if (key === 'start') {
      updateBlocking(d => {
        d.start.dateTime = date.toISO();
      });
    }

    if (key === 'end') {
      updateBlocking(d => {
        d.end.dateTime = date.toISO();
      });
    }
  };

  return (
    <div className="CreateBlocking Blocking">
      <Message
        title="Create a blocking event in outlook"
        subTitle="The subject of the event will show up in the scheduler to let you know what is blocking each time slot"
        icon="fad fa-fw fa-calendar-day"
      />

      <BlockingForm
        subject={blocking.subject}
        onSubjectChange={onSubjectChange}
        onDateChange={onDateChange}
        loading={creating}
      />

      <div className="BlockingActions">
        <Button
          className="Create BlockingButton"
          type="primary"
          onClick={onBlockTimeslot}
          disabled={
            !blocking.organizer.emailAddress.address ||
            !blocking.organizer.emailAddress.name ||
            !blocking.end ||
            !blocking.start ||
            !blocking.subject ||
            creating
          }
        >
          Block timeslot
        </Button>
      </div>
    </div>
  );
}

