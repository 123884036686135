import React from 'react';
import './date-time.scss';
import { DatePicker } from 'antd';
import { useComposedClassName } from '@rapid/sdk';
import moment from 'moment';

interface IDateTimeProps {
  label: string;
  error?: string;
  className?: string;
  id?: string;
  onChange: (value: moment.Moment) => void;
  value?: string;
  required?: boolean;
  [key: string]: unknown;
}

function DateTime({
  className,
  label,
  error,
  id,
  required,
  onChange,
  value,
  ...rest
}: IDateTimeProps) {
  const cn = useComposedClassName(
    function* () {
      yield 'field-container';
      if (className) yield className;
    },
    [className],
  );

  return (
    <div className={cn} data-required={!!required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      {/* @ts-expect-error */}
      <DatePicker
        className="date-time"
        id={id}
        onSelect={onChange}
        value={!!value ? moment(value) : null}
        format="DD/MM/YYYY"
        allowClear={false}
        onBlur={rest.onBlur as any}
      />

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default DateTime;
