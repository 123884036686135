import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { AnyListItem } from '@rapid/data-model';
import { DateTime } from 'luxon';
import React from 'react';
import { ILead } from '../../../Types/ItemTypes';
import '../Contact/Card.scss';
import './LeadCard.scss';

interface ILeadCardProps {
  lead?: AnyListItem<ILead>;
  onSelectLead?(lead?: AnyListItem<ILead>): void;
  selected?: boolean;
  compact?: boolean;
}

export default function LeadCard({
  lead,
  onSelectLead,
  selected,
  compact,
}: ILeadCardProps) {
  const _onSelectLead = () => {
    onSelectLead?.(lead);
  };

  const leadClass = useComposedClassName(
    function* () {
      yield 'LeadCard Card';

      if (selected) {
        yield 'selected';
      }

      if (compact) {
        yield 'compact';
      }
    },
    [selected, compact],
  );

  let childName = lead?.child;

  let childDOB = lead?.child_dob;

  let monday = lead?.monday;

  let tuesday = lead?.tuesday;

  let wednesday = lead?.wednesday;

  let thursday = lead?.thursday;

  let friday = lead?.friday;

  let startDate = lead?.start_date;

  return (
    <div
      className={leadClass}
      onClick={_onSelectLead}
      title={compact ? 'Click to open lead' : childName ?? ''}
    >
      <div className="LeftItems">
        <i className="fal fa-fw fa-baby LeadIcon Icon" />
        <div className="LeadName NameContainer">
          <div className="FullName Name">
            {!!childName ? childName : <em className="muted">(Not set)</em>}
          </div>
          {!compact && (
            <div className="ChildDOB SubName">
              <i className="fal fa-birthday-cake Icon IconSmall" />
              {!!childDOB
                ? DateTime.fromISO(childDOB).toLocaleString(DateTime.DATE_FULL)
                : 'Unset'}
            </div>
          )}
        </div>
      </div>
      <div className="RightItems">
        {!compact && (
          <div className="Days">
            <i className="fal fa-calendar-week Icon IconSmall" />
            &ensp;
            <span className="Day Monday">
              {monday ? 'M' : <span className="NotToday">-</span>}
            </span>
            <span className="Day Tuesday">
              {tuesday ? 'T' : <span className="NotToday">-</span>}
            </span>
            <span className="Day Wednesday">
              {wednesday ? 'W' : <span className="NotToday">-</span>}
            </span>
            <span className="Day Thursday">
              {thursday ? 'T' : <span className="NotToday">-</span>}
            </span>
            <span className="Day Friday">
              {friday ? 'F' : <span className="NotToday">-</span>}
            </span>
          </div>
        )}

        <div className="StartDate">
          <i className="fal fa-calendar-check Icon IconSmall" />
          &ensp;
          {!!startDate
            ? DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_SHORT)
            : 'Unset'}
        </div>
      </div>
    </div>
  );
}
