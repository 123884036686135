import { IField } from '@rapid/data-model';
import { useContext, useEffect } from 'react';
import { days } from '../DayPicker';
import { DynamicFormContext } from './dynamic-form';
import { ValidationErrors } from './form-validators';

type ReturnValue = [
  unknown,
  string | null,
  IField,
  (value: unknown, overrideName?: string) => void,
  (value: unknown) => void,
  object,
  { current: Map<string, { isRequired?: boolean }> },
];

export function useDynamicFormValue(name: string): ReturnValue {
  const [fv, errors, getField, validate, updateFV, updateErrors, fields, rules] =
    useContext(DynamicFormContext);
  const field = fields.find(field => field.ColumnName === name);

  const onUpdate = (value: unknown, overrideName?: string) => {
    updateFV(draft => ({ ...draft, [overrideName ?? name]: value }));
  };

  const onValidate = (value: unknown | symbol) => {
    if (!field) return;

    if (value instanceof Symbol && value.toString() === 'clear') {
      updateErrors(draft => ({ ...draft, [name]: null }));
      return;
    }

    if ((field.FieldType as any) === 'DayPicker') {
      const hasDay = days.some(day => {
        return fv[day.value];
      });
      updateErrors(draft => ({ ...draft, [name]: !hasDay ? ValidationErrors.empty : null }));
      return;
    }

    updateErrors(draft => ({ ...draft, [name]: validate(field, name, value) }));
  };

  useEffect(() => {
    onValidate(fv[name]);
  }, []);

  return [fv[name], errors[name], field!, onUpdate, onValidate, fv, rules];
}
