import { ActionEvent, actionRegistry } from '@rapid/adaptive-framework';
import { IMarkedTimespan, IScheduler } from '@rapid/dhtmlx-scheduler';
import { isNaN } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import OffsetTime from '../Utils/offsetTime';

export type TCellClickHandler = (scheduler: IScheduler, date: DateTime) => void;

interface IPostitionMarkedCellsReturn {
  onMouseMove(scheduler: IScheduler, id: string | null, ev: MouseEvent): void;
  markedCells: IMarkedTimespan[];
}

export default function usePostitionMarkedCells(
  scheduler: IScheduler,
  cssVarRef: React.RefObject<HTMLDivElement>,
  onCellClick: TCellClickHandler,
  html: (day: number, days: string[]) => string,
  startOfWeek: DateTime,
  zone?: string,
): IPostitionMarkedCellsReturn {
  const [columnIndex, setColumnIndex] = useState<string>();

  const [marginTop, setMarginTop] = useState<number>(0);

  const CELL_TIME_STEP = scheduler.config.time_step;

  const CELL_HEIGHT_PX = scheduler.config.hour_size_px * (CELL_TIME_STEP / 60);

  const HOURS_PER_CELL = CELL_TIME_STEP / 60;

  const DAY_INDEX = [0, 1, 2, 3, 4, 5, 6];

  const DAY_NAMES = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const onMouseMove = useCallback(
    (scheduler: IScheduler, id: string | null, ev: MouseEvent) => {
      if (ev.target instanceof HTMLDivElement) {
        let target: HTMLElement | null | undefined = ev.target;

        while ((target = target?.parentElement)) {
          if (!target?.className.includes('dhx_scale_holder')) {
            continue;
          }

          if (target?.dataset.columnIndex !== columnIndex) {
            setColumnIndex(target?.dataset.columnIndex);
          }
        }

        if (ev.target.className.includes('MarkedTimespan')) {
          const offestY =
            Math.floor(ev.offsetY / CELL_HEIGHT_PX) * CELL_HEIGHT_PX;

          if (
            typeof offestY === 'number' &&
            !isNaN(offestY) &&
            offestY !== marginTop
          ) {
            setMarginTop(offestY);
          }
        }
      }
    },
    [columnIndex, marginTop],
  );

  const markedCells = useMemo(
    function generateMarkedCells() {
      const markedCells: IMarkedTimespan[] = DAY_INDEX.reduce((prev, curr) => {
        prev.push({
          days: curr,
          zones: 'fullday',
          css: 'MarkedTimespan gray_section',
          html: html(curr, DAY_NAMES),
        } as IMarkedTimespan);

        return prev;
      }, [] as IMarkedTimespan[]);

      return markedCells;
    },
    [html],
  );

  useEffect(
    function setCssVariableEffect() {
      if (!!columnIndex && typeof marginTop === 'number') {
        cssVarRef.current?.style.setProperty(
          `--cell-${columnIndex}-margin-top`,
          `${marginTop}px`,
        );

        const dateTime = currentDateTime(+columnIndex + 1);

        const time = `${dateTime.toFormat('hh:mm a')} - ${dateTime
          .plus({ minute: 30 })
          .toFormat('hh:mm a')}`;

        cssVarRef.current?.style.setProperty('--time', `"${time}"`);

        const now = DateTime.now().setZone(zone);
        if (dateTime.diff(now, 'seconds').seconds < 0) {
          cssVarRef.current?.classList.add('disabled');
        } else {
          cssVarRef.current?.classList.remove('disabled');
        }
      }
    },
    [columnIndex, marginTop],
  );

  const currentDateTime = (weekday: number): DateTime => {
    const cells = marginTop / CELL_HEIGHT_PX;
    const hoursFromFirst = cells * HOURS_PER_CELL;
    const hour = scheduler.config.first_hour + hoursFromFirst;
    const minute = hoursFromFirst % 1 === 0 ? 0 : 30;
    const date = startOfWeek.plus({ days: +(weekday - 1) }).set({
      hour,
      minute,
      second: 0,
      millisecond: 0,
    });

    return date;
  };

  const onEmptyCellClick = (e: ActionEvent<IEmptyCellArgs>) => {
    if (typeof marginTop === 'number' && !!e.args?.day) {
      onCellClick(scheduler, currentDateTime(e.args.day));
    }
  };

  useEffect(
    function addCatchEmptyCellClickListener() {
      actionRegistry.addEventListener(
        'empty-cell-click',
        onEmptyCellClick as EventListener,
      );

      return function cleanup() {
        actionRegistry.removeEventListener(
          'empty-cell-click',
          onEmptyCellClick as EventListener,
        );
      };
    },
    [marginTop, CELL_HEIGHT_PX],
  );

  return { onMouseMove, markedCells };
}

interface IEmptyCellArgs {
  day: number;
}
