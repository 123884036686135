import React from 'react';
import { ITimelineEvent, TimelineEventType } from '@rapid/data-model';
import {
  groupBy,
  useSingleListItemEmails,
  useSingleListItemTimeline,
} from '@rapid/sdk';
import { Timeline as AntTimeline } from 'antd';
import 'antd/dist/antd.css';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import TimelineItem from './TimelineItem';

const AntTimelineItem = AntTimeline.Item;

function graphMessageToTimelineEvent(
  message: microsoftgraph.Message,
): ITimelineEvent {
  return {
    author: message.sender?.emailAddress?.name!,
    authorId: 0,
    date: message.sentDateTime!,
    edited: false,
    entity_id: -1,
    key: message.id!,
    id: message.id!,
    title: `Email sent by: ${message.sender?.emailAddress?.address}`,
    entity_type_id: -1,
    type: TimelineEventType.Email,
    body: message.body!.content!,
    editedDate: message.sentDateTime!,
    subtitle: '',
  };
}

interface ITimelineProps {
  searchAddress?: string;
}

export default function Timeline({ searchAddress }: ITimelineProps) {
  const [timeline, fetchTimeline, loading] = useSingleListItemTimeline();
  const [emails, fetchEmails] = useSingleListItemEmails(searchAddress);

  const sortedTimeline = useMemo(
    function sortTimelineMemo() {
      return groupBy(
        orderBy(
          [
            ...(timeline ?? []),
            ...(emails ?? []).map(graphMessageToTimelineEvent),
          ],
          ['date'],
          ['desc'],
        ),
        i => DateTime.fromISO(i.date).toRelative()!,
      );
    },
    [timeline, emails],
  );

  useEffect(
    function fetchTimelineItems() {
      fetchTimeline?.(true);
      // fetchEmails();
    },
    [searchAddress],
  );

  return (
    <div className="Timeline gce-component-container gce-margin-medium-top">
      <h2 className="gce-text-center gce-margin">Timeline</h2>
      <AntTimeline pending={loading && 'loading...'}>
        {Object.entries(sortedTimeline).map(([key, items]) => (
          <AntTimelineItem key={key}>
            <div className="EditedTime">{key}</div>
            {items.map(item => (
              <TimelineItem key={item.id} item={item} />
            ))}
          </AntTimelineItem>
        ))}
      </AntTimeline>
    </div>
  );
}
