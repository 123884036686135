import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <div className="gce-container content-block">
      {breadcrumbs && breadcrumbs.length > 0 && (
        <ul className="gce-breadcrumb">
          {breadcrumbs.map(item => (
            <li key={`${item.key}`}>
              {item.url ? (
                <Link to={item.url}>{item.displayName}</Link>
              ) : (
                <span>{item.displayName}</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Breadcrumb;
