import React, { useEffect, useState } from 'react';
import AddNoteForm from './AddNoteForm';
import EditOfferForm from './EditOfferForm';
import MoveToWaitlistForm from './MoveToWaitlistForm';
import NotProceedingForm from './NotProceedingForm';

import { useParams } from 'react-router-dom';
import { AnyListItem } from '@rapid/data-model';
import { useCurrentSiteEndpoint } from '@rapid/sdk';

export interface ILeadCardProps {
  lead: AnyListItem;

  onSubmit?(tab?: string): void;
}

const cards = [
  {
    name: 'note',
    icon: 'fa-comments',
    label: 'Add a note',
    buttonClass: 'gce-button-secondary gce-blue',
  },
  {
    name: 'offer',
    icon: 'fa-smile',
    icon2: 'fa-edit',
    label: 'Send offer',
    label2: 'Edit offer',
    buttonClass: 'gce-button-primary gce-green',
  },
  {
    name: 'waitlist',
    icon: 'fa-clipboard-list',
    label: 'Move to  waitlist',
    buttonClass: 'gce-button-primary gce-blue',
  },
  {
    name: 'proceeding',
    icon: 'fa-sad-cry',
    label: 'Not proceeding',
    buttonClass: 'gce-button-danger gce-red',
  },
];

export default function LeadCards({ lead, onSubmit }: ILeadCardProps) {
  const ep = useCurrentSiteEndpoint();
  const { bondId, tab, leadId, ...params } = useParams<any>();
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const centreId = /^(\d+)/.exec(params.centreId)?.[0]!;
  const [isWaitlist, setIsWaitlist] = useState(false);
  const [state, setState] = useState({ isOfferSent: false, isLoading: false });

  useEffect(() => {
    checkIsWaitlisted();

    if (lead.lead_stage?.toLowerCase() === 'offer sent') {
      setState({ ...state, isOfferSent: true });
    }
  }, [lead]);

  const checkIsWaitlisted = async () => {
    const waitlistItems = await ep.Lists.Waitlists.All.Items.getJson({
      $filter: `lead_id eq ${leadId} and centre_id eq ${centreId}`,
    });

    const hasOpenWaitlist = waitlistItems.value.findIndex(
      item => item.status === 'Open',
    );
    setIsWaitlist(hasOpenWaitlist !== -1);
  };

  const handleCardClick = card => {
    if (selectedCard === card) setSelectedCard(null);
    else setSelectedCard(card);
  };

  function onCardSubmit(tab?: string) {
    setSelectedCard(null);
    onSubmit?.(tab);
  }

  const showForm = card => {
    switch (card) {
      case 'note':
        return <AddNoteForm onSubmit={onCardSubmit} />;
      case 'offer':
        return <EditOfferForm onSubmit={onCardSubmit} />;
      case 'waitlist':
        return <MoveToWaitlistForm onSubmit={onCardSubmit} />;
      case 'proceeding':
      case 'rm-waitlist':
        return <NotProceedingForm onSubmit={onCardSubmit} />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div
        id="actions"
        className="gce-grid gce-margin-medium-top gce-grid-match"
      >
        {cards.map(card => (
          <div
            key={card.name}
            className={`gce-width-1-${isWaitlist ? '3' : '4'}`}
            style={{
              display: card.name === 'waitlist' && isWaitlist ? 'none' : '',
            }}
          >
            <button
              id="gce-button-note"
              className={`gce-button gce-button-big ${card.buttonClass} ${
                card.name === selectedCard ? 'active' : ''
              }`}
              onClick={ev => handleCardClick(card.name)}
            >
              <div>
                <i
                  className={`fal fa-fw ${
                    (bondId || lead.offered) && card.icon2
                      ? card.icon2
                      : card.icon
                  } fa-3x`}
                />
              </div>
              <div>
                {(bondId || lead.offered) && card.label2
                  ? card.label2
                  : card.label}
              </div>
            </button>
          </div>
        ))}
      </div>
      {showForm(selectedCard)}
      {state.isLoading ? (
        <div className={`gce-mask isVisible`}>
          <span className="fa-stack fa-3x">
            <i className="fad fa-spinner-third fa-stack-2x fa-spin" />
            <i className="fal fa-question fa-stack-1x" />
          </span>
          <div className="gce-text-center" style={{ fontSize: '1.5rem' }}>
            {'Please Wait...'}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}
