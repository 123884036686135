import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

type TEventListener<T extends Event> = (e: T) => void;

export default function useKeyPress(
  keys: string[],
  callback: TEventListener<KeyboardEvent>,
  node: HTMLElement | null = null,
) {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress: TEventListener<KeyboardEvent> = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keys.some(key => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys],
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode &&
      targetNode.addEventListener(
        'keydown',
        handleKeyPress as EventListenerOrEventListenerObject,
      );

    // remove the event listener
    return () =>
      targetNode &&
      targetNode.removeEventListener(
        'keydown',
        handleKeyPress as EventListenerOrEventListenerObject,
      );
  }, [handleKeyPress, node]);
}
