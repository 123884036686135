import { Moment } from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef } from 'react';
import { useDynamicFormValue } from '../dynamic-form-hooks';
import DateTime from './date-time/date-time';
import DayPicker from './day-picker/day-picker';
import DropdownField from './dropdown-field/dropdown-field';
import InputField from './input-field/input-field';
import LookupField from './lookup-field/lookup-field';
import NoteField from './note-field/note-field';

interface IFormFieldProps {
  name: string;
  required?: boolean;
  checkOnMount?: boolean;
  label?: string;
  className?: string;
  extra?: any;
}

export function FormField(props: IFormFieldProps) {
  const [value, error, field, onUpdate, onValidate, fv, rules] = useDynamicFormValue(props.name);
  const id = useRef(nanoid()).current;

  const label = props.label ?? field?.Title ?? props.name;
  rules.current.set(props.name, { isRequired: !!props.required });

  const onChange = (v: unknown, overrideName?: string) => {
    if (error) onValidate(Symbol.for('clear'));
    onUpdate(v, overrideName);
  };

  const onBlur = () => onValidate(value);
  const onChangeValue = (value: string) => onChange(value);
  const onChangeEvent = (event: any) => onChange(event?.target?.value);
  const onChangeMoment = (value: Moment) => onChange(value.toISOString());
  const onChangeDay = (value: string, toggle: boolean) => {
    onUpdate(!toggle ? null : true, value);
  };

  useEffect(() => {
    if ((field?.FieldType as any) === 'DayPicker') onValidate(true);
  }, [field?.FieldType, fv]);

  switch (field?.FieldType as any) {
    case 'DayPicker':
      return (
        <DayPicker
          id={id}
          label={label}
          required={props.required}
          onChange={onChangeDay}
          className={props.className}
          value={fv as any}
          error={error!}
          {...props.extra}
        />
      );

    case 'Note':
      return (
        <NoteField
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeEvent}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'Lookup':
      return (
        <LookupField
          field={field!}
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeValue}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'Choice':
      return (
        <DropdownField
          field={field!}
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeValue}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'DateTime':
      return (
        <DateTime
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeMoment}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'Number':
      return (
        <InputField
          type="number"
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeEvent}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'Email':
      return (
        <InputField
          type="email"
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeEvent}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );

    case 'Text':
    default:
      return (
        <InputField
          type="text"
          required={props.required}
          label={label}
          className={props.className}
          id={id}
          value={value}
          onChange={onChangeEvent}
          onBlur={onBlur}
          error={error!}
          {...props.extra}
        />
      );
  }
}
