import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
import './Toolbar.scss';

interface IToolbarProps {
  className?: string;
}

export default function Toolbar({
  className,
  children,
}: React.PropsWithChildren<IToolbarProps>) {
  const toolbarClass = useComposedClassName(
    function*() {
      yield 'Toolbar';

      if (className) yield className;
    },
    [className],
  );

  return <div className={toolbarClass}>{children}</div>;
}
