import { ISortClause } from '@rapid/data-model';
import { DateTime } from 'luxon';
import { TDays } from '../../Store';

/**========================================================================
 *                           Date Filters
 *========================================================================**/

export function startOfToday() {
  return DateTime.local()
    .setZone('Australia/Sydney')
    .startOf('day')
    .toUTC()
    .toISO();
}

export function startOfTomorrow() {
  return DateTime.local()
    .setZone('Australia/Sydney')
    .plus({ days: 1 })
    .startOf('day')
    .toUTC()
    .toISO();
}

/**========================================================================
 *                           Request Filters
 *========================================================================**/

export function sortClauseString(...clauses: ISortClause[]): string {
  return clauses.map(c => `${c.ColumnName} ${c.Direction}`).join(',');
}

export function queryStringify(query: object): string {
  return Object.entries(query)
    .filter(([, val]) => !!val)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
}

export function odataFilterDays(days: Record<TDays, boolean>): string {
  return Object.entries(days)
    .filter(([, enabled]) => enabled)
    .map(([day]) => `${day} eq true`)
    .join(' and ');
}
