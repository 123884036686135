import { ISortClause } from '@rapid/data-model';
import React from 'react';
import { IColumn } from '../../Store';

interface ISortIconProps {
  column: IColumn;
  clause: ISortClause;
}

export default function SortIcon({ column, clause }: ISortIconProps) {
  if (column.path !== clause?.ColumnName) return null;

  let direction = clause.Direction;

  if (column.invertSort) {
    direction = direction === 'asc' ? 'desc' : 'asc';
  }

  if (direction === 'asc')
    return <i className="fal fa-fw sort-icon fa-sort-up" />;
  return <i className="fal fa-fw sort-icon fa-sort-down" />;
}
