import { AnyListItem } from '@rapid/data-model';
import React, { useEffect, useMemo, useState } from 'react';
import { ILead } from '../../Types/ItemTypes';
import { TSchedulerTour } from '../../Types/SchedulerTypes';
import LeadCard from '../Lightbox/Lead/LeadCard';
import { VariableSizeList as List } from 'react-window';
import '../Lightbox/Lead/LeadCard.scss';
import '../Lightbox/Contact/Card.scss';
import './EventPopoverContent.scss';

interface IEventPopoverContentProps {
  event: TSchedulerTour;
  fetchLeads(eventId: number): Promise<any>;
  openLead(lead: AnyListItem<ILead>): void;
  start: string;
  end: string;
  date: string;
}

export default function EventPopoverContent({
  event,
  fetchLeads,
  openLead,
  start,
  end,
  date,
}: IEventPopoverContentProps) {
  const [loading, setLoading] = useState(true);
  const [linkedLeads, setLinkedLeads] = useState<AnyListItem<ILead>[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchLeads(event.id).then((res: { value: AnyListItem<ILead>[] }) => {
      setLinkedLeads(res.value);

      setLoading(false);
    });
  }, [event.id]);

  const LeadRow = ({ index, style }) => {
    return (
      <div
        className={`Lead${index % 2 === 0 ? ' even' : ' odd'}`}
        key={linkedLeads[index]?.id}
        style={style}
      >
        <LeadCard
          key={linkedLeads[index]?.id}
          lead={linkedLeads[index]}
          onSelectLead={openLead}
          compact
        />
      </div>
    );
  };

  return (
    <div className="EventPopoverContent">
      <div className="EventPopDetails">
        <div className="EventPopTitle">
          {linkedLeads?.[0]?.primary_contact ?? event.text}
        </div>
        <div className="EventPopTime">
          <div className="EventPopStartEnd">
            {start}&ensp;-&ensp;{end}
          </div>
          <div className="EventPopDate">{date}</div>
        </div>
      </div>

      <div className="EventPopLeadsTitle">
        Linked Child{linkedLeads.length > 1 ? 'ren' : ''}:
      </div>
      <div className="EventPopLeads">
        <List
          itemSize={() => 40}
          width={300}
          height={85}
          itemCount={linkedLeads.length}
        >
          {LeadRow}
        </List>
      </div>
    </div>
  );
}
