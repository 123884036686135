import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React, { ComponentProps } from 'react';
import './input-field.scss';

interface IInputFieldProps extends ComponentProps<'input'> {
  label: string;
  error?: string;
}

function InputField({ className, label, error, id, ...inputProps }: IInputFieldProps) {
  const cn = useComposedClassName(
    function* () {
      yield 'field-container';
      if (className) yield className;
    },
    [className],
  );

  return (
    <div className={cn} data-required={!!inputProps.required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      <input
        className="input-field field-container__field"
        id={id}
        aria-errormessage={`${id}-error`}
        {...inputProps}
      />

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default InputField;
