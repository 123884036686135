import { AnyListItem, ISortClause } from '@rapid/data-model';
import { useChoices, useViewContextListItemArray } from '@rapid/sdk';
import { Checkbox, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import '../Media/scss/table.scss';
import { IColumn, Store, ageOptions, useTabContext } from '../Store';
import AddLead from './AddLead';
import DayPicker from './DayPicker';
import SearchBox from './common/SearchBox';
import TableBody from './common/TableBody';
import TableHeader from './common/TableHeader';

interface ICentreDetailsProps {
  selectedTab: keyof Store['tabs'];
  centreId: number;
  timezone?: string;
}

const daysElement = ['sentOffers', 'waitlist', 'enrolled'];

export default function CentreTabContent(props: ICentreDetailsProps) {
  const [tab, updateTab] = useTabContext(props.selectedTab);
  const [priorities] = useChoices('Leads', 'priority');
  const [stages] = useChoices('Leads', 'enquiry_stage');
  const [localSearch, setLocalSearch] = useState<string>(tab.searchKey ?? '');
  const [items, viewContext] = useViewContextListItemArray();

  // FIXME: maybe stop bouncing viewContext flags up to
  // the tab
  useEffect(
    function contextLoadingEffect() {
      updateTab(draft => {
        draft.loading = !!viewContext.loading;
      });
    },
    [viewContext.loading],
  );

  function onSearchValueChanged(value: string) {
    setLocalSearch(value);
  }

  function onSort(clause: ISortClause) {
    updateTab(draft => {
      draft.sort = { ...clause };
    });
  }

  function onDayToggled(val: string) {
    if (!tab.days) {
      return;
    }

    updateTab(draft => {
      draft.days![val] = !draft.days?.[val];
    });
  }

  function onAgeRangeSelect(value: string) {
    updateTab(draft => {
      draft.selectedAgeRanges?.push(value);
    });
  }

  function onAgeRangeDeselect(value: string) {
    updateTab(draft => {
      const index = draft.selectedAgeRanges?.indexOf(value) ?? -1;
      if (index >= 0) {
        draft.selectedAgeRanges?.splice(index, 1);
      }
    });
  }

  function onAgeRangeClear() {
    updateTab(draft => {
      draft.selectedAgeRanges!.length = 0;
    });
  }

  function onPriorityValueChanged(value: string) {
    updateTab(draft => {
      draft.priority = value;
      draft.odataFilters = draft.odataFilters?.filter(i => !i.includes('priority'));
      if (value) {
        draft.odataFilters?.push(`priority eq '${value}'`);
      }
    });
  }

  function onStageValueChanged(value: string) {
    updateTab(draft => {
      draft['stage'] = value;

      draft.odataFilters = draft.odataFilters?.filter(i => !i.includes('enquiry_stage'));
      if (value) {
        draft.odataFilters?.push(`enquiry_stage eq '${value}'`);
      }
    });
  }

  function onSearch() {
    updateTab(draft => {
      draft.searchKey = localSearch;
    });
  }

  function toggleCompleteTasks(e: CheckboxChangeEvent) {
    updateTab(t => {
      t.showCompletedTasks = !t.showCompletedTasks;
    });
  }

  switch (props.selectedTab as string) {
    case 'createLead':
      return <AddLead />;
    default:
      return (
        <React.Fragment>
          <SearchBox
            placeholder={'Search...'}
            value={localSearch}
            onChange={onSearchValueChanged}
            onSearch={onSearch}
          />

          <div className="gce-container rapid-list-items">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <div id="div1" style={{ marginRight: '5px' }}>
                {props.selectedTab !== 'tasks' && tab.selectedAgeRanges && (
                  <Select
                    getPopupContainer={t => t.parentNode.parentNode}
                    style={{ width: 200 }}
                    mode="multiple"
                    value={tab.selectedAgeRanges}
                    options={ageOptions}
                    placeholder="Age Range"
                    allowClear
                    onSelect={onAgeRangeSelect}
                    onDeselect={onAgeRangeDeselect}
                    onClear={onAgeRangeClear}
                  />
                )}
                {props.selectedTab === 'tasks' && (
                  <Checkbox checked={!!tab.showCompletedTasks} onChange={toggleCompleteTasks}>
                    Show Completed
                  </Checkbox>
                )}
              </div>

              {props.selectedTab === 'waitlist' && tab.priorities && (
                <>
                  <div style={{ marginRight: '5px' }}>
                    <Select
                      allowClear
                      getPopupContainer={t => t.parentNode.parentNode}
                      style={{ width: 200 }}
                      value={tab.priority}
                      placeholder="Select Priority"
                      options={priorities.map(p => ({
                        value: p.Text,
                        label: p.Text,
                      }))}
                      onChange={onPriorityValueChanged}
                    />
                  </div>

                  <div style={{ marginRight: '5px' }}>
                    <Select
                      style={{ width: 200 }}
                      allowClear
                      getPopupContainer={t => t.parentNode.parentNode}
                      value={tab.stage}
                      placeholder="Select Stage"
                      options={stages.map(p => ({
                        value: p.Text,
                        label: p.Text,
                      }))}
                      onChange={onStageValueChanged}
                    />
                  </div>
                </>
              )}

              {daysElement.includes(props.selectedTab) && tab.days && (
                <DayPicker selectedDays={tab.days} onDayToggle={onDayToggled} />
              )}
            </div>

            <div className="gce-centre-details-container">
              <table className="gce-centre-details">
                <thead>
                  <TableHeader
                    columns={tab.columns as IColumn[]}
                    sortColumn={tab.sort}
                    onSort={onSort}
                    isLoading={viewContext.loading}
                  />
                </thead>

                <TableBody
                  columns={tab.columns as IColumn[]}
                  items={items}
                  tab={props.selectedTab}
                  timezone={props.timezone}
                  isLoading={!!viewContext.loading}
                />
              </table>
            </div>
          </div>

          {!items?.length && !viewContext.loading && (
            <div className="rapid-list-empty gce-text-center">
              <p style={{ fontSize: '1.3em' }}>No items could be found.</p>
            </div>
          )}

          {!items?.length && viewContext.loading && (
            <div className="rapid-list-empty gce-text-center">
              <p style={{ fontSize: '1.3em' }}>
                <i className="fal fa-spin fa-fw fa-spinner-third"></i>
                Loading...
              </p>
            </div>
          )}
        </React.Fragment>
      );
  }
}
