import { Updater, useCurrentSiteEndpoint } from '@rapid/sdk';
import { useEffect, useState } from 'react';
import {
  linkedFamilyTag,
  linkedLeadsTag,
  linkedMeetingEventTag,
  linkedParentTag,
} from '../Utils/SchedulerSymbols';
import { TSchedulerTour } from '../Types/SchedulerTypes';
import { useLightbox } from '../Contexts/LightboxContext/LightboxContext';
import { AnyListItem } from '@rapid/data-model';
import { IContact, IFamily, ILead, IMeeting } from '../Types/ItemTypes';

interface IViewResp<T = any> {
  value: AnyListItem<T>[];
}

export default function useTourLinkedItems(
  event: Partial<TSchedulerTour> | undefined,
  updateEvent: Updater<Partial<TSchedulerTour>>,
  mode: 'Edit' | 'Create',
) {
  const ep = useCurrentSiteEndpoint();

  const { catchLightboxError } = useLightbox();

  const [loadingLeads, setLoadingLeads] = useState(false);
  const [loadingFamily, setLoadingFamily] = useState(false);
  const [loadingParent, setLoadingParent] = useState(false);

  useEffect(function getLinkedLeadsOnMount() {
    if (mode === 'Edit' && !!event?.id) {
      setLoadingLeads(true);

      (ep.Lists.Leads.All as any).items
        .getJson({
          linkedTo: `Tours/${event.id}`,
        })
        .then((res: IViewResp<ILead>) => {
          updateEvent(d => {
            d[linkedLeadsTag] = res.value;
          });

          setLoadingLeads(false);
        })
        .catch(catchLightboxError('notify'));
    }
  }, []);

  useEffect(
    function getLinkedParentOnMount() {
      if (
        !!event?.[linkedLeadsTag]?.length &&
        !!event?.[linkedLeadsTag][0].primary_contact_id
      ) {
        setLoadingParent(true);
        ep.Lists.Contacts.items[event?.[linkedLeadsTag][0].primary_contact_id]
          .getJson()
          .then((res: AnyListItem<IContact>) => {
            if (!!res) {
              updateEvent(d => {
                d[linkedParentTag] = res;
              });
            }

            setLoadingParent(false);
          })
          .catch(catchLightboxError('notify'));
      }
    },
    [event?.[linkedLeadsTag]],
  );

  useEffect(
    function getLinkedFamilyOnMount() {
      if (
        !!event?.[linkedLeadsTag]?.length &&
        !!event?.[linkedLeadsTag][0].family_id
      ) {
        setLoadingFamily(true);

        ep.Lists.Families.items[event?.[linkedLeadsTag][0].family_id]
          .getJson()
          .then((res: AnyListItem<IFamily>) => {
            if (!!res) {
              updateEvent(d => {
                d[linkedFamilyTag] = res;
              });
            }
            setLoadingFamily(false);
          })
          .catch(catchLightboxError('notify'));
      } else if (
        !!event?.[linkedParentTag] &&
        !!event?.[linkedParentTag].family_id
      ) {
        setLoadingFamily(true);

        ep.Lists.Families.items[event?.[linkedParentTag].family_id]
          .getJson()
          .then((res: AnyListItem<IFamily>) => {
            if (!!res) {
              updateEvent(d => {
                d[linkedFamilyTag] = res;
              });
            }
            setLoadingFamily(false);
          })
          .catch(catchLightboxError('notify'));
      }
    },
    [event?.[linkedLeadsTag], event?.[linkedParentTag]],
  );

  useEffect(
    function getLinkedMeetingOnMount() {
      if (mode === 'Edit' && !!event?.id) {
        (ep.Lists.Meetings.all as any).items
          .getJson({
            linkedTo: `Tours/${event.id}`,
          })
          .then((res: IViewResp<IMeeting>) => {
            if (!!res.value.length) {
              updateEvent(d => {
                d[linkedMeetingEventTag] = res.value[0];
              });
            }
          })
          .catch(catchLightboxError('notify'));
      }
    },
    [event?.[linkedLeadsTag]],
  );

  return {
    loadingLeads,
    loadingFamily,
    loadingParent,
  };
}
