import { ActionEvent, actionRegistry } from '@rapid/adaptive-framework';
import { AnyListItem } from '@rapid/data-model';
import {
  Updater,
  useCurrentSiteEndpoint,
  useImmer,
  useLocalStorageState,
} from '@rapid/sdk';
import { DateTime } from 'luxon';
import React, { useContext, useEffect } from 'react';
import { createContext, PropsWithChildren } from 'react';
import { useRouteMatch } from 'react-router-dom';
import selectLead from '../../Components/Lightbox/Lead/selectLead';
import LightboxRender from '../../Components/Lightbox/LightboxRender';
import createTour, { ICreateTourReturn } from '../../CRUD/createTour';
import updateTour, { IUpdateTourData } from '../../CRUD/updateTour';
import useLightboxError, {
  TCatchLightboxError,
} from '../../Hooks/useLightboxError';
import { ILead } from '../../Types/ItemTypes';
import {
  ILightboxContext,
  TLightboxMode,
  TLightboxOnClose,
  TSchedulerTour,
} from '../../Types/SchedulerTypes';
import OffsetTime from '../../Utils/offsetTime';
import { linkedMeetingEventTag } from '../../Utils/SchedulerSymbols';
import { IsCTProvider } from '../CTContext/CTContext';
import { useSchedulerContext } from '../SchedulerContext/SchedulerContext';

const lightboxContext = createContext<
  [
    ILightboxContext,
    Updater<ILightboxContext>,
    Partial<TSchedulerTour>,
    Updater<Partial<TSchedulerTour>>,
  ]
>([] as any);

lightboxContext.displayName = 'Lightbox Context';

const LightboxProvider = lightboxContext.Provider;

interface ILightboxContextProps {
  timezone?: string;
}

export default function LightboxContext({
  timezone: zone,
  children,
}: PropsWithChildren<ILightboxContextProps>) {
  const match = useRouteMatch<{ centreId: string }>();
  const centreId = +/^(\d+)/.exec(match.params.centreId)?.[0]!;
  const [isCT, setIsCT] = useLocalStorageState<boolean>('is-ct');
  const ep = useCurrentSiteEndpoint();
  const [event, updateEvent] = useImmer<Partial<TSchedulerTour>>({});
  const { scheduler, updateEvents, updateContext } = useSchedulerContext();
  const [lightbox, updateLightbox] = useImmer<ILightboxContext>({
    mode: 'select-contact-or-lead',
    isOneOff: false,
    isOpen: false,
    loading: false,
  });

  useEffect(
    function setDateOnMount() {
      if (lightbox.mode === 'create-tour') {
        updateEvent(d => {
          if (!d.start_date) {
            d.start_date = DateTime.now().setZone(zone).toJSDate();
          }

          if (!d.end_date) {
            d.end_date = DateTime.now()
              .setZone(zone)
              .plus({ minute: 30 })
              .toJSDate();
          }
        });
      }
    },
    [lightbox.mode],
  );

  const setLightboxMode = (mode: TLightboxMode) => {
    updateLightbox(d => {
      d.mode = mode;
    });
  };

  const closeLightbox = () => {
    updateEvent(() => ({}));
    updateLightbox(d => {
      d.loading = false;
      d.mode = 'select-contact-or-lead';
      d.isOneOff = false;
      d.isOpen = false;
    });
  };

  const catchLightboxError = useLightboxError(closeLightbox);

  const onCloseLightbox = (
    onClose: TLightboxOnClose,
    creatingEvent?: TSchedulerTour,
  ) => {
    switch (onClose) {
      case 'create':
        if (!!creatingEvent) {
          updateEvents(d => {
            const ind = d.findIndex(e => e.id === creatingEvent.id) ?? -1;

            if (ind !== -1) {
              d.splice(ind, 1, creatingEvent as TSchedulerTour);
            } else {
              creatingEvent.start_date = OffsetTime(creatingEvent.start_date, zone);
              creatingEvent.end_date = OffsetTime(creatingEvent.end_date, zone);
              
              d.push(creatingEvent as TSchedulerTour);
            }
          });

          updateEvent(() => ({}));
        }

        closeLightbox();
        break;
      case 'cancel':
        if (!!event?.id) {
          ep.Lists.Tours.items[event.id]
            .putJson({}, { tour_status: 'Cancelled' })
            .then(() => {
              updateEvents(d => {
                const ind = d.findIndex(e => e.id === event.id) ?? -1;

                if (ind !== -1) {
                  d.splice(ind, 1);
                }
              });

              updateContext(d => {
                d.loading = false;
              });

              closeLightbox();
            })
            .catch(catchLightboxError('close-notify'));
        }

        break;
      case 'update':
        updateEvents(d => {
          const ind = d.findIndex(t => t.id === creatingEvent?.id) ?? -1;

          if (ind !== -1) {
            d.splice(ind, 1, creatingEvent as TSchedulerTour);
          }
        });
        scheduler.render();
        closeLightbox();
        break;
      case 'close':
      default:
        closeLightbox();
        return;
    }
  };

  const onRescheduleTour = () => {
    updateContext(d => {
      d.mode = 'reschedule';
      d.activeEvent = event as any;
    });

    scheduler.render();

    updateLightbox(d => {
      d.isOpen = false;
      d.eventId = undefined;
    });
  };

  const onCreateTour = (data: IUpdateTourData) => {
    updateLightbox(d => {
      d.loading = true;
    });

    updateTour(ep, event, data);

    createTour(ep, { ...(event ?? {}), ...(data.tour ?? {}) }, centreId)
      .then((res: ICreateTourReturn) => {
        const newEvent: Partial<TSchedulerTour> = {
          ...(event ?? {}),
          ...res.tour,
          text: res.tour.parent_first_name ?? '',
          readonly: true,
          [linkedMeetingEventTag]: res.meetingEvent,
          id: event.id ?? res.tour.id,
          start_date: event?.start_date,
          end_date: event?.end_date,
        };

        onCloseLightbox('create', newEvent as TSchedulerTour);
      })
      .catch(catchLightboxError('notify'));
  };

  const onSaveTour = (data: IUpdateTourData) => {
    updateLightbox(d => {
      d.loading = true;
    });

    updateTour(ep, event, data)
      .then((res: IUpdateTourData) => {
        const newEvent: Partial<TSchedulerTour> = {
          ...(event ?? {}),
          ...res.tour,
          text:
            res.contact?.first_name ??
            res.tour?.parent_name ??
            event?.text ??
            '',
          readonly: true,
          id: event.id ?? res.tour?.id,
          start_date: event?.start_date,
          end_date: event?.end_date,
        };

        onCloseLightbox('update', newEvent as TSchedulerTour);
      })
      .catch(catchLightboxError('notify'));
  };

  const onSelectLead = (lead: AnyListItem<ILead>, single?: boolean) => {
    selectLead(lead, single, updateEvent, setLightboxMode);
  };

  const onCancelTour = () => {
    setLightboxMode('confirm-cancel');
  };

  const onConfirmCancel = () => {
    if (!!event?.id) {
      updateContext(d => {
        d.loading = true;
      });

      updateLightbox(d => {
        d.loading = true;
      });

      onCloseLightbox('cancel');
    }
  };

  const catchOpenLightbox = (e: ActionEvent) => {
    const event = scheduler.getEvent(e.args?.id);

    if (!!event) {
      updateEvent(event);
    }

    if (!!e.args?.date) {
      const startDate = DateTime.fromISO(e.args.date).setZone(zone);
      const endDate = startDate.plus({ minute: 30 });

      updateEvent(d => {
        d.start_date = startDate;
        d.end_date = endDate;
      });
    }

    updateLightbox(d => {
      d.mode = e.args?.mode;
      d.eventId = e.args?.id;
      d.blockerId = e.args?.blockerId;
      d.isOpen = true;
    });
  };

  useEffect(
    function catchOpenLightboxEffect() {
      actionRegistry.addEventListener(
        'open-lightbox',
        catchOpenLightbox as any,
      );

      return function catchOpenLightboxCleaup() {
        actionRegistry.removeEventListener(
          'open-lightbox',
          catchOpenLightbox as any,
        );
      };
    },
    [lightbox],
  );

  return (
    <LightboxProvider value={[lightbox, updateLightbox, event, updateEvent]}>
      <IsCTProvider value={[isCT, setIsCT]}>
        {!!lightbox.isOpen && (
          <LightboxRender
            timezone={zone}
            mode={lightbox.mode}
            onCloseLightbox={onCloseLightbox}
            setLightboxMode={setLightboxMode}
            //
            // Event \/
            //
            event={event}
            updateEvent={updateEvent}
            //
            // CRUD \/
            //
            onCancelTour={onCancelTour}
            onCreateTour={onCreateTour}
            onSaveTour={onSaveTour}
            onSelectLead={onSelectLead}
            onConfirmCancel={onConfirmCancel}
            onRescheduleTour={onRescheduleTour}
          />
        )}
        {children}
      </IsCTProvider>
    </LightboxProvider>
  );
}

export function useLightboxContext(): [
  ILightboxContext,
  Updater<ILightboxContext>,
  Partial<TSchedulerTour>,
  Updater<Partial<TSchedulerTour>>,
] {
  const [lightbox, updateLightbox, event, updateEvent] =
    useContext(lightboxContext);

  return [lightbox, updateLightbox, event, updateEvent];
}

interface IUseLightbox {
  isOpen: boolean;
  openLightbox(
    mode?: TLightboxMode,
    eventId?: string,
    persistEvent?: boolean,
  ): void;
  closeLightbox(): void;
  mode: TLightboxMode;
  setLightboxMode(mode: TLightboxMode): void;
  eventId?: string;
  setEventId(eventId: string): void;
  blockerId?: string;
  catchLightboxError: TCatchLightboxError;
  loading?: boolean;
}

export function useLightbox(): IUseLightbox {
  const [lightbox, updateLightbox, , updateEvent] = useLightboxContext();

  const openLightbox = (
    mode: TLightboxMode = 'select-contact-or-lead',
    eventId?: string,
    clearEvent: boolean = true,
  ) => {
    if (clearEvent) {
      updateEvent(() => ({}));
    }

    updateLightbox(d => {
      d.isOpen = true;

      d.mode = mode;

      if (!!eventId) {
        d.eventId = eventId;
      } else {
        d.eventId = undefined;
      }
    });
  };

  const closeLightbox = () => {
    updateEvent(() => ({}));
    updateLightbox(d => {
      d.loading = false;
      d.mode = 'select-contact-or-lead';
      d.isOneOff = false;
      d.isOpen = false;
    });
  };

  const setLightboxMode = (mode: TLightboxMode) => {
    updateLightbox(d => {
      d.mode = mode;
    });
  };

  const setEventId = (eventId: string) => {
    updateLightbox(d => {
      d.eventId = eventId;
    });
  };

  const catchLightboxError = useLightboxError(closeLightbox);

  return {
    isOpen: lightbox.isOpen,
    openLightbox,
    closeLightbox,
    mode: lightbox.mode,
    setLightboxMode,
    eventId: lightbox.eventId,
    setEventId,
    blockerId: lightbox.blockerId,
    catchLightboxError,
    loading: lightbox.loading,
  };
}
