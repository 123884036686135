import {
  useChoices,
  useCurrentSiteEndpoint,
  useListItemContext,
} from '@rapid/sdk';
import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCentreAlias } from '../../Store';

interface INotProceedingFormProps {
  onSubmit?(): void;
}

const defaultState = {
  notes: '',
  enquiryClosure: null,
  enquiryClosure2: null,
  formTitle: "Remove From My Centre's Waitlist",
  isLoading: true,
  showEnquiryClosure: false,
  showEnquiryClosure2: false,
  onMultipleCentres: false,

  // Logic
  hasWaitListItem: false,
  waitListItemIsOnThisCentre: false,
  linkedWaitLists: [],
  linkedTours: [],
};

export default function NotProceedingForm({
  onSubmit,
}: INotProceedingFormProps) {
  const ep = useCurrentSiteEndpoint();
  const { leadId, bondId, tourId, centreId: rawCentreId } = useParams<any>();
  const [{ item, fetchItem, fetchTimeline }] = useListItemContext();
  const [enquiryClosures] = useChoices('Leads', 'enquiry_closure');
  const [secondaryReasons, setSecondaryReason] = useState([]);
  const [state, updateState] = useState(defaultState);
  const centreAlias = useCentreAlias();
  const centreId = /^(\d+)/.exec(rawCentreId)?.[0]!;

  const enquiryClosureOptions = useMemo(
    function returnOptions() {
      return enquiryClosures.map(c => ({
        value: c.Text,
        label: c.Text,
      }));
    },
    [enquiryClosures],
  );

  async function setSecondaryReasons($filter?: string) {
    const list = 'Secondary Wrap Up Codes';

    try {
      let result = await ep.Lists[list].All.Items.getJson({ $filter });

      if (!result.value.length) {
        result = await ep.Lists[list].All.Items.getJson();
      }

      setSecondaryReason(
        result.value.map(value => ({ value: value.id, label: value.title })),
      );
    } catch (e) {
      setSecondaryReason([]);
    }
  }

  const onFormValueChanged = (key: string) => (value: any) => {
    if (key === 'enquiry_closure') {
      setSecondaryReasons(`related_code eq '${value}'`);
      updateState({ ...state, enquiryClosure: value });
    }

    if (key === 'enquiry_closure2') {
      updateState({ ...state, enquiryClosure2: value });
    }

    if (key === 'notes') {
      updateState({ ...state, notes: value?.target?.value });
    }
  };

  // ===========================================================================
  //  Steps that happen
  //  1. Find centres attached to this lead and add them to `linkedItemsToRemove`
  //  2. Find waitListItems attached to this lead and centre and mark as `status: closed`
  //  3. Find Tours attached to this lead and centre and mark as `not_proceeding: true`
  //  4. Set enquiry
  //  5. Add notes and inheritedLinkedItems
  //  6. Finish
  // ===========================================================================
  async function onFormSubmitted(_ev) {
    _ev.preventDefault();
    updateState({ ...state, isLoading: true });
    const linkedItemsToAdd: string[] = [];
    const linkedItemsToRemove: number[] = [];

    // Remove Link between lead and centre
    if (state.onMultipleCentres) {
      for (const i of item!.LinkedItems!) {
        if (i.column_name === 'centres' && i.centre_id === +centreId) {
          linkedItemsToRemove.push(+i.id!);
        }
      }
    }

    // Set waitLiteItems linked to this centre to closed
    // Note: linkedWaitLists is all waitListItems linked to this lead
    if (state.hasWaitListItem) {
      for (const waitListItem of state.linkedWaitLists as WaitListItem[]) {
        if (waitListItem.centre_id === +centreId) {
          await ep.Lists.Waitlists.Items[waitListItem.id].putJson(undefined, {
            status: 'Closed',
          });
        }
      }
    }

    // Set Tours linked to this lead and centre to closed
    const tours = await ep.Lists.Tour.All.Items.getJson({
      linkedTo: `Leads/${leadId}`,
      $filter: `centre_id eq ${centreId}`,
    });

    for (const tour of tours?.value ?? []) {
      await ep.Lists.Tour.Items[tour.id].putJson(undefined, {
        on_waitlist: false,
        not_proceeding: true,
      });
    }

    //
    if (state.enquiryClosure) {
      await ep.Lists.Leads.Items[leadId].putJson(undefined, {
        enquiry_closure: state.enquiryClosure,
        enquiry_closure_2_id: state.enquiryClosure2,
      });
    }

    if (linkedItemsToRemove.length) {
      await ep.Lists.Leads.Items[leadId].putJson(undefined, {
        LinkedItemsToRemove: linkedItemsToRemove,
      });
    }

    // Set inheritedLinkItems
    const notes: any[] = [];
    const inheritedLinksParams = { type: 'Notes' };
    const inheritedLinks: string[] = await ep.Lists.Leads.Items[leadId][
      'inherited-links'
    ].getJson(inheritedLinksParams);

    // Add links
    linkedItemsToAdd.push(`lead/${leadId}`);
    if (inheritedLinks?.length) linkedItemsToAdd.push(...inheritedLinks);
    if (tourId) linkedItemsToAdd.push(`tour/${tourId}`);
    if (bondId) linkedItemsToAdd.push(`bond/${bondId}`);

    // Add user notes
    if (state.notes.length) {
      const body = { LinkedItemsToAdd: linkedItemsToAdd, body: state.notes };
      notes.push(body);
    }

    // Add generated note
    if (state.waitListItemIsOnThisCentre) {
      let body = `${item?.child ?? 'TBC'}`;
      body += `was removed from the waitlist at ${centreAlias} centre.`;
      notes.push({ LinkedItemsToAdd: linkedItemsToAdd, body: body });
    } else {
      let body = `${item?.child ?? 'TBC'}`;
      body += `was marked as not proceeding at ${centreAlias} centre.`;
      notes.push({ LinkedItemsToAdd: linkedItemsToAdd, body: body });
    }

    await ep.Lists.Notes.Items.postJson(undefined, notes);

    // Finish
    fetchItem();
    fetchTimeline?.(true);
    onSubmit?.();
  }

  // ===========================================================================
  //  Steps that happen
  //  1. Check If lead is on multiple centres
  //  2. Get all waitList items attached to this lead that are open
  //  3. Check if lead has open waitList item
  //  4. Check if waitList item is on this centre
  //  5. Set Loading to false
  // ===========================================================================
  useEffect(function onMount() {
    const newState = { ...state };

    const promises = [
      checkIfMultipleCentres({ linkedTo: `Leads/${leadId}&term=` }, newState),
      checkWaitListStatus(
        {
          linkedTo: `Leads/${leadId}`,
          $filter: "status eq 'Open'",
        },
        newState,
      ),
      setSecondaryReasons(),
    ];

    Promise.all(promises).finally(() => {
      newState.isLoading = false;
      updateState(newState);
    });
  }, []);

  // ===========================================================================
  //  1. Get all centres linked to this lead and set `onMultipleCentres`
  // ===========================================================================
  async function checkIfMultipleCentres(params: any, newState) {
    const resp = await ep.Lists.Centres.All.Items.getJson(params);
    const onMultipleCentres = resp.value.length > 1 ? true : false;
    newState.onMultipleCentres = onMultipleCentres;
  }

  // ===========================================================================
  //  1. Get all Open WaitListItems linked to this lead
  //  2. Check is a WaitListItem is linked to this centre
  //  3. Change title of form is need be
  // ===========================================================================
  async function checkWaitListStatus(params: any, newState) {
    const resp = await ep.Lists.Waitlists.All.Items.getJson(params);
    let hasWaitListItem = true;
    let waitListItemIsOnThisCentre = false;

    const centre = await ep.Lists.Centres.All.Items[centreId].getJson();
    if (centre?.secondary_wrap_up_code_selection) {
      newState.showEnquiryClosure2 = true;
    }

    // Lead has no waitListItems
    if (!resp.value?.length) {
      newState.formTitle = 'Not Proceeding';
      if (!newState.onMultipleCentres) newState.showEnquiryClosure = true;
      return;
    }

    for (const waitList of resp.value as WaitListItem[]) {
      if (waitList.centre_id === +centreId) waitListItemIsOnThisCentre = true;
    }

    newState.linkedWaitLists = resp.value;
    newState.hasWaitListItem = hasWaitListItem;
    newState.waitListItemIsOnThisCentre = waitListItemIsOnThisCentre;

    if (
      waitListItemIsOnThisCentre &&
      resp.value.length === 1 &&
      !newState.onMultipleCentres
    ) {
      newState.showEnquiryClosure = true;
    }

    if (!hasWaitListItem || (hasWaitListItem && !waitListItemIsOnThisCentre)) {
      newState.formTitle = 'Not Proceeding';
    }
  }

  return (
    <form
      onSubmit={onFormSubmitted}
      className="gce-component-container gce-margin-medium-top"
      style={{ display: 'block', position: 'relative' }}
    >
      <h2 className="gce-text-center">{state.formTitle}</h2>

      <div className="gce-custom-alert">
        In order for us to provide better services in the future can you please
        complete the following details.
      </div>

      <div className="gce-grid gce-margin">
        {state.showEnquiryClosure && (
          <>
            <div className="gce-width-1-1">
              Can you please tell us why they do not wish to proceed with
              enrolment?
              <Select
                className="gce-input"
                options={enquiryClosureOptions}
                value={state.enquiryClosure ?? ''}
                onSelect={onFormValueChanged('enquiry_closure')}
              />
            </div>

            {state.showEnquiryClosure2 && (
              <div className="gce-width-1-1" style={{ marginTop: '10px' }}>
                Please provide a secondary reason
                <Select
                  className="gce-input"
                  options={secondaryReasons}
                  value={state.enquiryClosure2 ?? ''}
                  onSelect={onFormValueChanged('enquiry_closure2')}
                />
              </div>
            )}
          </>
        )}

        <div className="gce-width-1-1" style={{ marginTop: '25px' }}>
          <label htmlFor="rejectNote" className="gce-form-label">
            Anything else we need to know?
          </label>
          <textarea
            name="rejectNote"
            onChange={onFormValueChanged('notes')}
            className="gce-textarea"
            rows={4}
          />
        </div>

        <div className="gce-width-1-1 gce-margin-top gce-text-center">
          <button
            className="gce-button gce-button-danger gce-red"
            type="submit"
          >
            {state.formTitle === 'Not Proceeding'
              ? 'Not proceeding'
              : 'Remove From WaitList'}
          </button>
        </div>
      </div>

      {state.isLoading && (
        <div className={`gce-mask isVisible`}>
          <span className="fa-stack fa-3x">
            <i className="fad fa-spinner-third fa-stack-2x fa-spin"></i>
            <i className="fal fa-question fa-stack-1x"></i>
          </span>
          <div className="gce-text-center" style={{ fontSize: '1.5rem' }}>
            {'Please Wait...'}
          </div>
        </div>
      )}
    </form>
  );
}
