import { IField } from '@rapid/data-model';
import { useComposedClassName, useCurrentSiteEndpoint } from '@rapid/sdk';
import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './lookup-field.scss';

interface ILookupFieldProps {
  label: string;
  field: IField;
  error?: string;
  className?: string;
  id?: string;
  onChange: (value: string) => void;
  value?: string;
  required?: boolean;
  [key: string]: unknown;
}

function LookupField({
  className,
  label,
  field,
  error,
  id,
  required,
  onChange,
  value,
  ...rest
}: ILookupFieldProps) {
  const [options, updateOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const ep = useCurrentSiteEndpoint();

  const cn = useComposedClassName(
    function* () {
      yield 'field-container';
      if (className) yield className;
    },
    [className],
  );

  const fetchItems = useCallback(async () => {
    if (!field.Settings || !field.Settings.LookupList) {
      return [];
    }

    const result = await ep.Lists[field.Settings.LookupList].All.Items.getJson({
      $filter: rest.$filter,
    });

    if (!result?.value?.length) {
      return [];
    }

    const _options = result.value.map(room => {
      const label = room[field.Settings?.LookupField ?? ''];
      const value = room.id;

      return { label: label ?? value, value: value };
    });

    updateOptions(_options);
  }, [field.Settings?.LookupList]);

  useEffect(() => {
    fetchItems().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className={cn} data-required={!!required} data-invalid={!!error}>
      <label htmlFor={id} className="field-container__label">
        {label}
      </label>

      <Select
        className="lookup-field"
        id={id}
        options={options}
        onSelect={onChange}
        value={value}
        allowClear={!required}
        loading={loading}
        onBlur={rest.onBlur as any}
      />

      <p className="field-container__error" id={`${id}-error`}>
        {error}
      </p>
    </div>
  );
}

export default LookupField;
