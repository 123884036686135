import React from 'react';
import { useMsal } from '@rapid/sdk';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

export default function Header() {
  const ua = useMsal();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (ua.getActiveAccount()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [ua]);

  const logout = () => {
    ua.logoutPopup();
  };

  return (
    <header className="Header">
      <div className="content">
        <div className="left">
          <Link to="/">
            <div className="image">
              <img
                alt="Guardian Childcare &nbsp; Education"
                src={
                  'https://cdn.rapidplatform.com/themes/guardianchildcare/images/guardian-logo-color.svg'
                }
              />
            </div>
          </Link>
        </div>
        <div className="center">
          <Link to="/report"><span>Report</span></Link>
          {/* <a href={myCentreURL()}>
            <span>My Centre</span>
          </a>
          <a href={myTasksURL}>
            <span>My Tasks</span>
          </a> */}
        </div>
        <div className="right">
          {isLoggedIn && (
            <a href="#" onClick={logout}>
              <i className="fad fa-2x fa-fw fa-sign-out" />
            </a>
          )}
        </div>
      </div>
    </header>
  );
}
