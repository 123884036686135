import { AnyListItem } from '@rapid/data-model';
import { notification } from 'antd';
import React from 'react';
import { IContact, ILead } from '../Types/ItemTypes';

interface IData {
  lead: Partial<AnyListItem<ILead>>;
  contact: Partial<AnyListItem<IContact>>;
  [key: string]: any;
}

export interface IFormFields {
  leadStartDate: boolean;
  contactFirstName: boolean;
  contactLastName: boolean;
  contactMobile: boolean;
  contactEmail: boolean;
}

export interface IValidateReturn {
  isValid: boolean;
  fields: IFormFields;
}

export default function validateFormData(data: IData): IValidateReturn {
  const leadStartDate = !!data.lead?.start_date;
  const contactFirstName = !!data.contact?.first_name;
  const contactLastName = !!data.contact?.last_name;
  const contactMobile = !!data.contact?.mobile;
  const contactEmail = !!data.contact?.email;

  if (
    leadStartDate &&
    contactFirstName &&
    contactLastName &&
    contactMobile &&
    contactEmail
  ) {
    return {
      isValid: true,
      fields: {
        leadStartDate,
        contactFirstName,
        contactLastName,
        contactMobile,
        contactEmail,
      },
    };
  } else {
    const description = (
      <ul>
        {!leadStartDate && <li>Lead: Estimated Start Date</li>}

        {!contactFirstName && <li>Contact: First Name</li>}

        {!contactLastName && <li>Contact: Last Name</li>}

        {!contactMobile && <li>Contact: Mobile</li>}

        {!contactEmail && <li>Contact: Email</li>}
      </ul>
    );

    notification.warning({
      message: 'Please fill required fields',
      description,
    });

    return {
      isValid: false,
      fields: {
        leadStartDate,
        contactFirstName,
        contactLastName,
        contactMobile,
        contactEmail,
      },
    };
  }
}
