import React, { useRef } from 'react';
import DHXScheduler, { DAYS, IScheduler } from '@rapid/dhtmlx-scheduler';
import {
  onEventDeleted,
  onEventChanged,
  onEventAdded,
  onDblClick,
  onEventRender,
} from './EventHandlers';
import { DateTime } from 'luxon';
import { actionRegistry } from '@rapid/adaptive-framework';
import { useSchedulerContext } from './Contexts/SchedulerContext/SchedulerContext';
import SchedulerToolbar from './Components/SchedulerToolbar/SchedulerToolbar';
import { TLightboxMode, TSchedulerTour } from './Types/SchedulerTypes';
import usePostitionMarkedCells from './Hooks/usePositionMarkedCells';
import checkCollision from './Utils/checkCollision';
import { cellHtml, rescheduleHtml } from './Utils/cellHtml';
import '@rapid/dhtmlx-scheduler/css/scheduler.css';
import './markedCells.scss';
import { useCurrentSiteEndpoint } from '@rapid/sdk';
import { useHistory } from 'react-router-dom';
import { AnyListItem } from '@rapid/data-model';
import { ILead } from './Types/ItemTypes';
import OffsetTime from './Utils/offsetTime';

interface ISchedulerProps {
  zone?: string;
}

export default function Scheduler({ zone }: ISchedulerProps) {
  const {
    scheduler,
    context,
    updateContext,
    blockedTimespans,
    blockingEvents,
    events,
    updateEvents,
  } = useSchedulerContext();

  const history = useHistory();

  const openLead = (lead: AnyListItem<ILead>) => {
    if (!!lead.id) {
      const url = `${history.location.pathname}/lead-${lead.id}`;

      window.open(url, 'blank');
    }
  };

  const cssVarRef = useRef<HTMLDivElement>(null);

  const siteEp = useCurrentSiteEndpoint();

  const onCellClick = (scheduler: IScheduler, date: DateTime) => {
    if (checkCollision(date, events, blockingEvents ?? [])) {
      return;
    }

    if (context.mode === 'reschedule') {
      actionRegistry.dispatchAction('reschedule-tour', {
        date: date.setZone(zone),
      });
      return;
    }

    actionRegistry.dispatchAction('open-lightbox', {
      date: date,
      mode: 'select-contact-or-lead',
    });
  };

  const { markedCells, onMouseMove } = usePostitionMarkedCells(
    scheduler,
    cssVarRef,
    onCellClick,
    context.mode === 'reschedule' ? rescheduleHtml : cellHtml,
    context.startOfWeek,
    zone,
  );

  const onViewChange = (scheduler: IScheduler, mode: 'week', date: Date) => {
    const iso = date.toISOString();
    const sow = DateTime.fromObject(
      {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: 8,
      },
      { zone },
    );

    updateContext(d => {
      d.startOfWeek = sow;
      d.endOfWeek = sow.plus({ days: 6 }).set({ hour: 17, minute: 30 });
    });
  };

  const onOpenLightbox = (id: string, mode: TLightboxMode) => {
    actionRegistry.dispatchAction('open-lightbox', {
      id,
      mode,
    });
  };

  const fetchLeads = async (eventId: number) => {
    return await siteEp.Lists.Leads.All.Items.getJson({
      linkedTo: `Tours/${eventId}`,
    });
  };

  return (
    <div className="cssVarRef" ref={cssVarRef}>
      <SchedulerToolbar />
      {!!context.loading && (
        <div className="SchedulerSkeleton">
          <i className="fad fa-fw fa-spinner-third fa-spin SkeletonIcon" />
          &ensp;
          <div className="SkeletonTitle">
            {context.mode === 'reschedule' ? 'Rescheduling' : 'Loading'}{' '}
            tours...
          </div>
        </div>
      )}
      <DHXScheduler<TSchedulerTour>
        scheduler={scheduler}
        initialStartOfWeek={OffsetTime(context.startOfWeek, zone).toJSDate()}
        events={events}
        firstHour={8}
        lastHour={18}
        hideDays={[DAYS.SUNDAY]}
        hourScale={88}
        customEventDuration={30}
        lockEventDuration
        blockedTimespans={blockedTimespans}
        markedTimespans={markedCells}
        onEventRender={onEventRender(context, fetchLeads, openLead)}
        onOpenLightbox={onOpenLightbox}
        // DHX Event Handlers
        onEventChanged={onEventChanged(events, updateEvents)}
        onEventCreated={onEventAdded(events, updateEvents)}
        onEventDeleted={onEventDeleted(events, updateEvents)}
        onDblClick={onDblClick}
        onViewChange={onViewChange}
        onMouseMove={onMouseMove}
      />
    </div>
  );
}
