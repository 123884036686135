import { IColumnProps } from '../../Store';
import React from 'react'

export default function DataCell({ column, item }: IColumnProps) {
  return (
    <>
      {column.path ? (
        <>
          {column.iconName && (item[column.path] || column.path == 'total_days') ? (
            <i className={`fal fa-fw ${column.iconName}`} />
          ) : (
            ''
          )}
          {item[column.path]}
        </>
      ) : null}
    </>
  );
}
