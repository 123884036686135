import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { IElement } from '@rapid/data-model';
import {
  IMarkedTimespan,
  IScheduler,
  useSchedulerEvents,
} from '@rapid/dhtmlx-scheduler';
import {
  Updater,
  useImmer,
  useListItemContext,
  useViewContextListItemArray,
} from '@rapid/sdk';
import { DateTime } from 'luxon';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useStore } from '../../../../Store';
import useCDNForms from '../../Hooks/useCDNForms';
import useCentreSchedule from '../../Hooks/useCentreSchedule';
import useReschedule from '../../Hooks/useReschdule';
import { IOutlookEvent } from '../../Types/ItemTypes';
import {
  ISchedulerContext,
  ISchedulerContextReturn,
  TSchedulerMode,
  TSchedulerTour,
} from '../../Types/SchedulerTypes';

const schedulerContext = React.createContext<ISchedulerContextReturn>(
  {} as any,
);

schedulerContext.displayName = 'Scheduler Context';

const SchedulerProvider = schedulerContext.Provider;

interface ISchedulerContextProps {
  mode?: TSchedulerMode;
  startOfWeek?: DateTime;
  setStartOfWeek(sow: DateTime): void;
  timezone?: string;
}

//@ts-ignore
const scheduler: IScheduler = window.scheduler;

export default function SchedulerContext({
  mode = 'crud',
  startOfWeek,
  setStartOfWeek,
  timezone: zone,
  children,
}: React.PropsWithChildren<ISchedulerContextProps>) {
  const [items, viewContext] = useViewContextListItemArray();
  const [centreContext] = useListItemContext();

  const [store] = useStore();

  const [events, updateEvents, eventsLoading] =
    useSchedulerEvents<TSchedulerTour>(
      items as any[],
      'tour_booking_date_and_time',
      30,
      'parent_first_name',
      zone,
    );

  const [context, updateContext] = useImmer<ISchedulerContext>({
    mode,
    loading: true,
    startOfWeek: startOfWeek ?? DateTime.now().setZone(zone),
    endOfWeek:
      startOfWeek?.endOf('week').endOf('day') ??
      DateTime.now().setZone(zone).endOf('week').endOf('day'),
    schemas: {},
    meetingEvents: [],
    outlookEvents: [],
    centreLeads: [],
  });

  useCDNForms(updateContext);

  const [
    blockedTimespans,
    blockingEvents,
    scheduleLoading,
    fetchAvailability,
    deleteBlocker,
    updateBlocker,
  ] = useCentreSchedule(
    context.startOfWeek,
    store.isAdmin,
    context.cm,
    updateContext,
    zone,
  );

  useEffect(
    function agregateLoadingEffect() {
      updateContext(d => {
        if (context.mode === 'reschedule') {
          d.loading = false;
          return;
        }

        d.loading =
          scheduleLoading || eventsLoading || viewContext.loading || false;
      });
    },
    [scheduleLoading, eventsLoading, viewContext.loading, context.mode],
  );

  useEffect(
    function updateStartOfWeek() {
      setStartOfWeek(context.startOfWeek);
    },
    [context.startOfWeek],
  );

  useReschedule(
    scheduler,
    context.activeEvent,
    blockingEvents,
    updateEvents,
    context,
    updateContext,
  );

  const schedulerClass = useComposedClassName(
    function* () {
      yield 'SchedulerTab';

      yield context.mode;
    },
    [context.mode],
  );

  const schedulerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cellHeight =
      scheduler.config.hour_size_px * (scheduler.config.time_step / 60);
    schedulerRef.current?.style.setProperty('--cell-height', `${cellHeight}px`);
  }, []);

  return (
    <SchedulerProvider
      value={{
        context,
        updateContext,
        events,
        updateEvents,
        scheduler,
        blockedTimespans: blockedTimespans ?? [],
        blockingEvents,
        fetchAvailability,
        deleteBlocker,
        updateBlocker,
      }}
    >
      <div ref={schedulerRef} className={schedulerClass}>
        {children}
      </div>
    </SchedulerProvider>
  );
}

export function useSchedulerContext() {
  const context = React.useContext(schedulerContext);
  return context;
}

export function useEventsContext(): [
  TSchedulerTour[],
  Updater<TSchedulerTour[]>,
] {
  const { events, updateEvents } = useSchedulerContext();

  return [events ?? [], updateEvents];
}

export function useBlockingContext(): [
  IMarkedTimespan[] | undefined,
  IOutlookEvent[] | undefined,
  () => void,
  (id: string) => void,
  (id: string, patch: Record<string, any>) => Promise<void>,
] {
  const {
    blockingEvents,
    blockedTimespans,
    fetchAvailability,
    deleteBlocker,
    updateBlocker,
  } = useSchedulerContext();

  return [
    blockedTimespans,
    blockingEvents,
    fetchAvailability,
    deleteBlocker,
    updateBlocker,
  ];
}

export function useSchema(
  form: 'createTour' | 'createLead',
): IElement | undefined {
  const { context } = useSchedulerContext();

  return context.schemas[form];
}
