export const linkedLeadsTag: unique symbol = Symbol.for('Selected Leads');
export const linkedFamilyTag: unique symbol = Symbol.for('Linked Family');
export const linkedChildrenTag: unique symbol = Symbol.for('Linked Children');
export const linkedParentTag: unique symbol = Symbol.for('Linked Parent');
export const linkedMeetingEventTag: unique symbol = Symbol.for(
  'Linked Meeting Event',
);
export const linkedOutlookEventIdTag: unique symbol = Symbol.for(
  'Linked Outlook Event Id ',
);
