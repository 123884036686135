import { Button } from 'antd';
import React from 'react';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import Message from '../Message/Message';
import './ConfirmCancel.scss';

interface IConfirmCancelProps {
  event?: Partial<TSchedulerTour>;
  onConfirmCancel(): void;
}

export default function ConfirmCancel({
  event,
  onConfirmCancel,
}: IConfirmCancelProps) {
  return (
    <div className="ConfirmCancel">
      <Message
        title={
          <span>
            {`Are you sure you want to cancel`}{' '}
            <strong className="EventTitle">{event?.text}</strong>
          </span>
        }
        subTitle={
          'Cancelling this tour will automatically notify this family of the cancelation'
        }
        icon={'fad fa-fw fa-triangle-exclamation'}
      />
      <div className="ConfirmCancelActions">
        <Button
          danger
          type="primary"
          onClick={onConfirmCancel}
          className="CRUDButton Save"
        >
          Cancel Tour
        </Button>
      </div>
    </div>
  );
}
