import React from 'react';
import { KIconTypes, useComposedClassName } from '@rapid/sdk';

interface IBreadcrumbItemProps {
  icon?: KIconTypes;
}

export default function BreadcrumbItem({
  icon,
  children,
}: React.PropsWithChildren<IBreadcrumbItemProps>) {
  const className = useComposedClassName(
    function* () {
      yield 'fad fa-fw';
      yield icon as string;
    },
    [icon],
  );

  return (
    <span>
      <i className={className} />
      {children}
    </span>
  );
}
