import { AnyListItem } from '@rapid/data-model';
import React from 'react';

interface ICentreListItemProps {
  centre: AnyListItem;
}

export default function CentreListItem({ centre }: ICentreListItemProps) {
  return (
    <div className="gce-flex gce-flex-middle">
      <div className="gce-flex-none" style={{ padding: '10px' }}>
        <span className="fa-stack" style={{ verticalAlign: 'top' }}>
          <i className="fal fa-circle fa-stack-2x"></i>
          <i className="fal fa-home-lg fa-stack-1x"></i>
        </span>
      </div>
      <div className="gce-flex gce-flex-auto rapid-card-content gce-flex-middle">
        <div className="gce-flex gce-flex-column gce-flex-auto">
          <div style={{ margin: 0, fontWeight: 400 }}>
            {centre.centre_alias}
          </div>
        </div>
        <div
          className="gce-flex-nowrap"
          style={{ width: '20%', fontSize: '0.8rem' }}
          title="Phone"
        >
          <i className="fal fa-fw fa-phone"></i>
          {centre.phone_number}
        </div>
      </div>
    </div>
  );
}
