import { AnyListItem, IChoice, IField, ISortClause } from '@rapid/data-model';
import { IManagedViewContextProps, Updater, useImmer } from '@rapid/sdk';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { startOfToday } from './common/utils/filters';
import DateCell from './Components/common/DateCell';
import IconCell from './Components/common/IconCell';
import ParentChildCell from './Components/common/ParentChildCell';
import { getDate, getDateTime, howOld } from './Components/helper';
import OffsetTime from './Components/SchedulerTab/Utils/offsetTime';
import { getWeekDays, getWeekDaysCount } from './Components/untils';

export const CentreListNaggedKey = 'CentreListNaggedKey';

interface IDataUriContext {
  centreId: string;
}

export type TDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday';

export interface ITab {
  label: string;
  iconName: string;
  loading: boolean;
  maxWidth?: string;
  searchKey?: string;
  selectedIndex?: number;
  days?: Record<TDays, boolean>;
  sort?: ISortClause;
  odataFilters?: string[];
  selectedAgeRanges?: string[];
  dataUrl?(context: IDataUriContext): string;
  columns?: IColumn[];

  selectedLeads?: AnyListItem[];

  viewContext?: IManagedViewContextProps;

  [key: string]: any;
}

export type TTabs =
  | 'tasks'
  | 'tour'
  | 'tourSchedule'
  | 'preOffers'
  | 'sentOffers'
  | 'waitlist'
  | 'enrolled'
  | 'createLead';

export interface IColumn {
  label: string;
  iconName?: string;
  rowIconName?: string;
  path: string;
  width?: number | string;
  minWidth?: number | string;
  title: string;
  align?: string;
  invertSort?: boolean;
  Content?: React.ComponentType<IColumnProps>;

  css?: React.CSSProperties;
}

export interface IColumnProps {
  item: AnyListItem;
  column: IColumn;
  timezone?: string;
}

export interface IParams {
  centreId: string;
  leadId: string;
  tab: TTabs;
  tourId?: string;
  bondId?: string;
}

export type FetchFn = () => Promise<void>;

const u = DateTime.utc();

export type TAgeRanges = '0-1 yrs' | '1-2 yrs' | '2-3 yrs' | '3-4 yrs' | '4-5 yrs' | '5+ yrs';

export type TAges = Record<TAgeRanges, string>;

export const ages: TAges = {
  '0-1 yrs': `1 eq 1 and child_dob ge '${u.minus({ years: 1 }).toISODate()}'`,
  '1-2 yrs': `child_dob gt '${u.minus({ years: 2 }).toISODate()}' and child_dob lt '${u
    .minus({ years: 1 })
    .toISODate()}'`,
  '2-3 yrs': `child_dob gt '${u.minus({ years: 3 }).toISODate()}' and child_dob lt '${u
    .minus({ years: 2 })
    .toISODate()}'`,
  '3-4 yrs': `child_dob gt '${u.minus({ years: 4 }).toISODate()}' and child_dob lt '${u
    .minus({ years: 3 })
    .toISODate()}'`,
  '4-5 yrs': `child_dob gt '${u.minus({ years: 5 }).toISODate()}' and child_dob lt '${u
    .minus({ years: 4 })
    .toISODate()}'`,
  '5+ yrs': `1 eq 1 and child_dob le '${u.minus({ years: 5 }).toISODate()}'`,
};

export const ageOptions = Object.keys(ages).map(a => ({ value: a, label: a }));

export function applySort(
  data: AnyListItem[],
  sort: ISortClause = { ColumnName: 'id', Direction: 'desc' },
) {
  return orderBy(
    data,
    [
      i => {
        const dateNum = Date.parse(i[sort.ColumnName]);

        if (!isNaN(dateNum)) {
          return dateNum;
        }

        return i[sort.ColumnName];
      },
    ],
    [sort?.Direction ?? 'asc'],
  );
}

const Store = {
  isAdmin: false as boolean,

  loginError: false as boolean,
  sitename: 'internal' as string,
  tenant: 'guardianchildcare' as string,

  closureChoices: [] as IChoice[],

  leadFields: [] as IField[],
  leadTour: [] as any[],
  leadBond: [] as any[] | any,
  leadTimeline: [] as any[],

  principalId: 0,

  nagged: !!sessionStorage.getItem(CentreListNaggedKey),
  myCentre: undefined as AnyListItem | undefined,

  leads: [] as AnyListItem[],
  centres: [] as AnyListItem[],

  centreEmail: undefined as string | undefined,
  centreTimezone: undefined as string | undefined,

  tabs: {
    tasks: {
      label: 'Tasks',
      loading: false,
      iconName: 'fa-tasks',
      viewContext: {
        list: 'Tasks',
        dataSource: 'Portal Uncompleted Tasks',
        columns: ['id', 'title', 'due_date', 'start_date', 'status'],
      },
      columns: [
        {
          rowIconName: 'fa-tasks',
          Content: IconCell,
          css: {
            minWidth: '60px',
          },
        },
        {
          label: 'Title',
          path: 'title',
          title: 'Title',
          align: 'left',
          css: {
            width: '100%',
          },
        },
        {
          label: 'Start date',
          path: 'start_date',
          title: 'start_date',
          align: 'left',
          Content: DateCell,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Due Date',
          path: 'due_date',
          title: 'due_date',
          align: 'left',
          Content: DateCell,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Status',
          path: 'status',
          title: 'status',
          align: 'left',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    },
    tour: {
      label: 'Tour',
      loading: false,
      iconName: 'fa-map-signs',
      rowIconName: 'fa-child',
      maxWidth: '',
      searchKey: '',
      viewContext: {
        list: 'Tours',
        columns: [
          'id',
          'tour_booking_date_and_time',
          'family',
          'child_name',
          'tour_lead_id',
          'tour_status',
          'child_dob',
          'parent_name',
          'parent_mobile',
          'start_date',
          'last_activity_date',
        ],
        dataSource: 'Portal Tours',
      },
      odataFilters: [
        `tour_booking_date_and_time ge '${DateTime.local()
          .setZone('Australia/Sydney')
          .startOf('day')
          .toUTC()
          .toISO()}'`,
      ],
      selectedAgeRanges: [],
      sort: {
        ColumnName: 'tour_booking_date_and_time',
        Direction: 'asc',
      },

      columns: [
        {
          Content: IconCell,
        },
        {
          label: 'Name',
          title: 'Name',
          path: 'parent_name',
          Content: ParentChildCell,
          css: {
            width: '100%',
          },
        },
        {
          label: 'Contact Number',
          iconName: 'fa-phone',
          path: 'parent_mobile',
          title: 'Contact Number',
          align: 'right',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Tour Date',
          align: 'left',
          iconName: 'fa-calendar-day',
          title: 'tour booking date time',
          path: 'tour_booking_date_and_time',
          Content: ({ item, timezone }) => {
            const offset = OffsetTime(
              DateTime.fromISO(item.tour_booking_date_and_time),
              timezone,
            ).toISO();

            return (
              <span>{item.tour_booking_date_and_time && offset ? getDateTime(offset) : ''}</span>
            );
          },
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Age',
          align: 'left',
          iconName: 'fa-calendar-day',
          path: 'child_dob',
          title: 'Age Bracket',
          invertSort: true,
          Content: ({ item }) => (
            <span>
              {(item.child_dob && howOld(item.child_dob)) ?? (
                <em className="gce-text-meta">Unborn</em>
              )}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Start Date',
          align: 'left',
          iconName: 'fa-calendar-star',
          path: 'start_date',
          width: '140px',
          title: 'Start Date',
          Content: ({ item }) => <span>{item.start_date ? getDate(item.start_date) : ''}</span>,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Tour Status',
          align: 'left',
          path: 'tour_status',
          width: '140px',
          title: 'Status',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Last Activity',
          iconName: 'fa-calendar-star',
          path: 'last_activity_date',
          align: 'right',
          width: '120px',
          title: 'Last Activity',
          Content: ({ item }) => (
            <span>{item.last_activity_date ? getDate(item.last_activity_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    },
    tourSchedule: {
      label: 'Tour Schedule',
      loading: false,
      iconName: 'fa-calendar-lines-pen',
    },
    preOffers: {
      label: 'Pre-Offers',
      loading: false,
      iconName: 'fa-handshake',
      searchKey: '',
      maxWidth: '',
      sort: {
        ColumnName: 'tour_booking_date_and_time',
        Direction: 'asc',
      },
      odataFilters: [`tour_booking_date_and_time le '${startOfToday()}'`],
      selectedAgeRanges: [],
      viewContext: {
        list: 'Tours',
        dataSource: 'Portal Pre-Offers',
        columns: [
          'id',
          'tour_booking_date_and_time',
          'family',
          'child_name',
          'tour_lead_id',
          'tour_status',
          'child_dob',
          'parent_name',
          'parent_mobile',
          'start_date',
          'last_activity_date',
        ],
      },
      columns: [
        {
          Content: IconCell,
        },
        {
          label: 'Name',
          title: 'Name',
          path: 'parent_name',
          Content: ParentChildCell,
          css: {
            width: '100%',
          },
        },
        {
          label: 'Contact Number',
          iconName: 'fa-phone',
          path: 'parent_mobile',
          title: 'Contact Number',
          align: 'right',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Tour Date',
          align: 'left',
          iconName: 'fa-calendar-day',
          title: 'tour booking date time',
          path: 'tour_booking_date_and_time',
          Content: ({ item, timezone }) => {
            const offset = OffsetTime(
              DateTime.fromISO(item.tour_booking_date_and_time),
              timezone,
            ).toISO();

            return (
              <span>{item.tour_booking_date_and_time && offset ? getDateTime(offset) : ''}</span>
            );
          },
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Age',
          align: 'left',
          title: 'age bracket',
          iconName: 'fa-calendar-day',
          path: 'child_dob',
          invertSort: true,
          Content: ({ item }) => (
            <span>
              {(item.child_dob && howOld(item.child_dob)) ?? (
                <em className="gce-text-meta">Unborn</em>
              )}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },

        {
          label: 'Start date',
          align: 'left',
          title: 'Start date',
          iconName: 'fa-calendar-star',
          path: 'start_date',
          Content: ({ item }) => <span>{item.start_date ? getDate(item.start_date) : ''}</span>,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Last Activity',
          align: 'right',
          iconName: 'fa-calendar-star',
          path: 'last_activity_date',
          title: 'Last Activity',
          Content: ({ item }) => (
            <span>{item.last_activity_date ? getDate(item.last_activity_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    },
    sentOffers: {
      label: 'Offers Sent',
      loading: false,
      iconName: 'fa-envelope',
      searchKey: '',
      maxWidth: '',
      sort: { ColumnName: 'expire_date', Direction: 'asc' },
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      },
      odataFilters: [],
      selectedAgeRanges: [],
      viewContext: {
        list: 'Bonds',
        dataSource: 'Portal Sent Offers',
        columns: [
          'id',
          'lead_id',
          'payment_confirmation',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'expire_date',
          'offer_expire',
          'amount_paid',
          'total_days',
          'status',
          'bond_status',
          'child',
          'parent_name',
          'parent_mobile',
          'start_date',
          'child_dob',
          'lead_closure',
          'last_activity_date',
          'room',
          'room_id',
        ],
      },

      columns: [
        {
          Content: IconCell,
        },
        {
          label: 'Name',
          title: 'Name',
          path: 'parent_name',
          Content: ParentChildCell,
          css: {
            width: '100%',
          },
        },
        {
          label: 'Mobile',
          iconName: 'fa-phone',
          path: 'parent_mobile',
          title: 'Contact Number',
          align: 'right',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Age',
          iconName: 'fa-calendar-day',
          align: 'left',
          path: 'child_dob',
          title: 'Age Bracket',
          invertSort: true,
          Content: ({ item }) => (
            <span>
              {(item.child_dob && howOld(item.child_dob)) ?? (
                <em className="gce-text-meta">Unborn</em>
              )}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Start Date',
          align: 'left',
          iconName: 'fa-calendar-star',
          path: 'start_date',
          title: 'Start Date',
          Content: ({ item }) => <span>{item.start_date ? getDate(item.start_date) : ''}</span>,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Day Offered',
          align: 'left',
          iconName: 'fa-calendar-week',
          path: 'total_days',
          title: 'Day Offered',
          Content: ({ item }) => (
            <span>
              <span>{getWeekDays(item)}</span>
              <span>[{item.total_days ?? getWeekDaysCount(item)}]</span>
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Offer Expires',
          align: 'right',
          iconName: 'fa-calendar-day',
          path: 'expire_date',
          title: 'Expire Date',
          Content: ({ item }) => <span>{item.expire_date ? getDate(item.expire_date) : ''}</span>,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Last Activity',
          align: 'right',
          iconName: 'fa-calendar-star',
          path: 'last_activity_date',
          title: 'Last Activity',
          Content: ({ item }) => (
            <span>{item.last_activity_date ? getDate(item.last_activity_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    },
    waitlist: {
      label: 'Waitlist',
      loading: false,
      iconName: 'fa-clipboard-list',
      searchKey: '',
      maxWidth: '',
      sort: { ColumnName: 'total_days', Direction: 'asc' },
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      },
      odataFilters: [],
      selectedAgeRanges: [],
      viewContext: {
        list: 'Waitlists',
        dataSource: 'waitlist',
        columns: [
          'id',
          'lead_name',
          'lead_id',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'reason',
          'days_waitlisted',
          'total_days',
          'child_dob',
          'child_name',
          'estimated_start_date',
          'last_activity_date',
        ],
      },
      columns: [
        {
          Content: IconCell,
        },
        {
          label: 'Lead',
          path: 'lead_name',
          title: 'Lead',
          align: 'left',
          Content: ParentChildCell,
          css: {
            width: '100%',
          },
        },
        {
          label: 'Reason',
          path: 'reason',
          title: 'Reason',
          align: 'left',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Days Waitlisted',
          path: 'days_waitlisted',
          title: 'Days Waitlisted',
          align: 'left',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Day Offered',
          align: 'left',
          iconName: 'fa-calendar-week',
          path: 'total_days',
          title: 'Day Offered',
          Content: ({ item }) => (
            <span>
              <span>{getWeekDays(item)}</span>
              <span>[{item.total_days ?? '0'}]</span>
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'DOB',
          path: 'child_dob',
          title: 'Date Of Birth',
          align: 'left',
          Content: ({ item }: { item: AnyListItem<any> }) => (
            <span>{item.child_dob ? getDate(item.child_dob) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Age',
          align: 'left',
          path: 'child_dob',
          title: 'Age Bracket',
          Content: ({ item }: { item: AnyListItem<any> }) => (
            <span>
              {(item.child_dob && howOld(item.child_dob)) ?? (
                <em className="gce-text-meta">Unborn</em>
              )}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Estimated Start',
          iconName: 'fa-calendar-day',
          path: 'estimated_start_date',
          title: 'Estimated Start',
          align: 'left',
          Content: ({ item }: { item: AnyListItem<any> }) => (
            <span>{item.estimated_start_date ? getDate(item.estimated_start_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Last Activity',
          align: 'right',
          iconName: 'fa-calendar-star',
          path: 'last_activity_date',
          title: 'Last Activity',
          Content: ({ item }) => (
            <span>{item.last_activity_date ? getDate(item.last_activity_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    } as any,
    enrolled: {
      label: 'Enrolled',
      loading: false,
      iconName: 'fa-clipboard-check',
      searchKey: '',
      maxWidth: '',
      sort: { ColumnName: 'start_date', Direction: 'asc' },
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      },
      odataFilters: [],
      selectedAgeRanges: [],
      viewContext: {
        list: 'Bonds',
        dataSource: 'Portal Enrolled',
        columns: [
          'id',
          'lead_id',
          'payment_confirmation',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'expire_date',
          'offer_expire',
          'amount_paid',
          'total_days',
          'status',
          'bond_status',
          'child',
          'parent_name',
          'parent_mobile',
          'start_date',
          'child_dob',
          'lead_closure',
          'last_activity_date',
        ],
      },

      columns: [
        {
          Content: IconCell,
        },
        {
          label: 'Name',
          title: 'Name',
          path: 'parent_name',
          Content: ParentChildCell,
          css: {
            width: '100%',
          },
        },
        {
          label: 'Contact Number',
          iconName: 'fa-phone',
          path: 'parent_mobile',
          title: 'Contact Number',
          align: 'right',
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Age',
          align: 'left',
          iconName: 'fa-calendar-day',
          path: 'child_dob',
          title: 'Age Bracket',
          invertSort: true,
          Content: ({ item }) => (
            <span>
              {(item.child_dob && howOld(item.child_dob)) ?? (
                <em className="gce-text-meta">Unborn</em>
              )}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Start Date',
          align: 'left',
          iconName: 'fa-calendar-star',
          path: 'start_date',
          title: 'Start Date',
          Content: ({ item }) => <span>{item.start_date ? getDate(item.start_date) : ''}</span>,
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Day Offered',
          align: 'left',
          iconName: 'fa-calendar-week',
          path: 'total_days',
          title: 'Day Offered',
          Content: ({ item }) => (
            <span>
              <span>{item.monday ? 'M' : '-'}</span> <span>{item.tuesday ? 'T' : '-'}</span>{' '}
              <span>{item.wednesday ? 'W' : '-'}</span> <span>{item.thursday ? 'T' : '-'}</span>{' '}
              <span>{item.friday ? 'F' : '-'}</span>{' '}
              {item.total_days ? <span>[{item.total_days}]</span> : ''}
            </span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
        {
          label: 'Last Activity',
          align: 'right',
          iconName: 'fa-calendar-star',
          path: 'last_activity_date',
          title: 'Last Activity',
          Content: ({ item }) => (
            <span>{item.last_activity_date ? getDate(item.last_activity_date) : ''}</span>
          ),
          css: {
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            paddingInline: '1rem',
          },
        },
      ],
    },
    createLead: {
      label: '',
      loading: false,
      searchKey: '',
      iconName: 'fa-search',
      maxWidth: '40px',
      viewContext: {
        list: 'Contacts',
        dataSource: 'All',
      },
      // data: null
    },
  } as Record<TTabs, ITab>,

  isLoggedIn(authContext: any): boolean {
    return !!authContext?.account?.name;
  },
};

export type Store = typeof Store;

const StoreContext = React.createContext<[Store, Updater<Store>]>([Store, null as any]);

export function StoreProvider({ children }: React.PropsWithChildren<{}>) {
  const [store, updateStore] = useImmer(Store);

  return <StoreContext.Provider value={[store, updateStore]}>{children}</StoreContext.Provider>;
}

export function useStore(): [Readonly<Store>, Updater<Store>] {
  const [store, updateStore] = useContext(StoreContext);

  return [store, updateStore];
}

export function useTabContext<T extends TTabs>(
  tab: T,
): [Readonly<Store['tabs'][T]>, Updater<Store['tabs'][T]>] {
  const [store, updateStore] = useStore();
  const c = store.tabs[tab] as Readonly<Store['tabs'][T]>;

  return [
    c,
    function updateTab(d) {
      updateStore(draft => {
        const t = draft.tabs[tab];

        (d as any)(t);
      });
    },
  ];
}

export function useCentreAlias(): string {
  const match = useRouteMatch<any>();

  const centreAlias = startCase(/^\d+-(.+?)$/.exec(match.params.centreId)?.[1]);

  return centreAlias;
}
