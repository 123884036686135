import { TSchedulerEvent, IScheduler } from '@rapid/dhtmlx-scheduler';
import { Updater } from '@rapid/sdk';

export function onEventDeleted<T>(
  events: TSchedulerEvent<T>[],
  updateEvents: Updater<TSchedulerEvent<T>[]>,
) {
  return function _onEventDeleted(
    scheduler: IScheduler,
    id: string,
    ev: MouseEvent,
  ) {
    const event = scheduler.getEvent(id);
    updateEvents(d => {
      const ind = d.findIndex(e => e.id === event?.id) ?? -1;
      if (ind !== -1) {
        d.splice(ind, 1);
      }
    });
  };
}
