import {
  UncontrolledAdaptiveRenderer,
  useAdaptiveData,
  useRapidUpdater,
} from '@rapid/adaptive-framework';
import React, { useMemo } from 'react';
import './TourForm.scss';
import {
  linkedChildrenTag,
  linkedFamilyTag,
  linkedLeadsTag,
  linkedParentTag,
} from '../../../Utils/SchedulerSymbols';
import { IElement } from '@rapid/data-model';
import { useLightbox } from '../../../Contexts/LightboxContext/LightboxContext';
import { useSchema } from '../../../Contexts/SchedulerContext/SchedulerContext';
import { TSchedulerTour } from '../../../Types/SchedulerTypes';
import { Updater } from '@rapid/sdk';
import jsonpath from 'jsonpath';
import { IUpdateTourData } from '../../../CRUD/updateTour';

interface ITourFormProps {
  event?: Partial<TSchedulerTour>;
  updateTourData: Updater<IUpdateTourData>;
  loading?: boolean;
}

const noForm = { $type: 'Layout.Section', id: 'no-form-yet' };

export default function TourForm({
  event,
  loading,
  updateTourData,
}: ITourFormProps) {
  const { loading: lbLoading } = useLightbox();

  const schema = useSchema('createTour');

  const mode = 'edit';

  const formData = useMemo(
    function buildFormDataEffect() {
      const data = {
        contact: event?.[linkedParentTag],
        child: event?.[linkedChildrenTag]?.[0],
        lead: event?.[linkedLeadsTag]?.[0],
        family: event?.[linkedFamilyTag]?.[0],
        tour: event,
      };

      return data;
    },
    [event?.[linkedChildrenTag], event?.[linkedParentTag]],
  );

  const [update, forceUpdate] = useRapidUpdater();
  const [data, , onDataChange, dataLoading] = useAdaptiveData(
    formData,
    schema ?? noForm,
    forceUpdate,
    mode,
    [formData],
  );

  const onDataChangeOuter = (
    element: IElement,
    data: any,
    path?: string,
    key?: string,
  ) => {
    if (!!path && key === 'data') {
      updateTourData(d => {
        jsonpath.value(d, path, data);
      });
    }

    onDataChange(element, data, path, key);
  };

  return (
    <div className="TourForm">
      <div className="FormContainer">
        {(!!loading || !!dataLoading) && (
          <div className="LoadingData">
            <i className="fal fa-spinner-third fa-fw fa-spin" />
            &ensp;Loading...
          </div>
        )}
        {lbLoading && (
          <div className="LoadingData">
            <i className="fal fa-spinner-third fa-fw fa-spin" />
            &ensp;Saving...
          </div>
        )}
        <UncontrolledAdaptiveRenderer
          mode={mode}
          schema={schema ?? noForm}
          rendererId="tour-form"
          update={update}
          forceUpdate={forceUpdate}
          onDataChange={onDataChangeOuter}
          data={data}
        />
      </div>
    </div>
  );
}
