export const leadFormDatas = [
  {
    type: 'dropdown_list',
    name: 'priority',
    label: 'Priority',
    divClass: 'gce-width-1-3@m',
    textField: 'Text',
    dataKey: 'Text',
  },
  {
    type: 'dropdown_list',
    name: 'enquiry_stage',
    label: 'Enquiry Stage',
    divClass: 'gce-width-1-3@m',
    textField: 'Text',
    dataKey: 'Text',
  },
  {
    type: 'dropdown_list',
    name: 'enquiry_closure',
    label: 'Wrap up code',
    divClass: 'gce-width-1-3@m',
    textField: 'Text',
    dataKey: 'Text',
  },
  {
    type: 'datepicker',
    name: 'start_date',
    label: 'Start date',
    divClass: 'gce-width-1-3@m',
    max: null,
  },
  {
    type: 'text',
    name: 'total_days',
    label: 'Total Days',
    divClass: 'gce-width-1-3@m',
    inputType: 'number',
  },
  {
    type: 'dayCheckbox',
    name: '',
    label: 'Days',
    divClass: 'gce-width-1-3@m',
  },
];

export const familyFormDatas = [
  {
    type: 'text',
    name: 'family_name',
    label: 'Name',
    divClass: '',
    inputType: 'text',
    required: true,
  },
];

export const childFormDatas = [
  {
    type: 'text',
    name: 'first_name',
    label: 'First Name',
    required: true,
    divClass: 'gce-width-2-5@m',
    inputType: 'text',
  },
  {
    type: 'text',
    required: true,
    name: 'last_name',
    label: 'Last Name',
    divClass: 'gce-width-2-5@m',
    inputType: 'text',
  },
  {
    type: 'text',
    name: 'qkid',
    label: 'QKID',
    divClass: 'gce-width-1-5@m',
    inputType: 'text',
  },
  {
    type: 'datepicker',
    required: true,
    name: 'dob',
    label: 'Date of birth',
    divClass: 'gce-width-1-2@m',
    max: new Date(),
  },
  {
    type: 'dropdown_list',
    name: 'gender',
    required: true,
    label: 'Gender',
    divClass: 'gce-width-1-2@m',
    textField: 'Text',
    dataKey: 'Text',
  },
];

export const contactFormDatas = [
  {
    type: 'text',
    name: 'first_name',
    label: 'First Name',
    required: true,
    divClass: 'gce-width-2-5@m',
    inputType: 'text',
  },
  {
    type: 'text',
    required: true,
    name: 'last_name',
    label: 'Last Name',
    divClass: 'gce-width-2-5@m',
    inputType: 'text',
  },
  {
    type: 'text',
    name: 'qkid',
    label: 'QKID',
    divClass: 'gce-width-1-5@m',
    inputType: 'text',
  },
  {
    type: 'text',
    required: true,
    name: 'mobile',
    label: 'Mobile',
    divClass: 'gce-width-1-3@m',
    inputType: 'text',
  },
  {
    type: 'text',
    required: true,
    name: 'email',
    label: 'Email',
    divClass: 'gce-width-2-3@m',
    inputType: 'text',
  },
];
