import React, { useMemo } from 'react';
import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { IScheduler } from '@rapid/dhtmlx-scheduler';
import { DateTime } from 'luxon';
import { TSchedulerMode, TSchedulerTour } from '../../Types/SchedulerTypes';
import { Popover } from 'antd';
import EventPopoverContent from './EventPopoverContent';
import './EventRender.scss';
import './EventPopoverContent.scss';
import { ILead } from '../../Types/ItemTypes';
import { AnyListItem } from '@rapid/data-model';

interface IEventRenderProps {
  scheduler: IScheduler;
  event: TSchedulerTour;
  active?: boolean;
  mode: TSchedulerMode;
  fetchLeads(eventId: number): Promise<any>;
  openLead(lead: AnyListItem<ILead>): void;
}

export default function EventRender({
  event,
  active,
  mode,
  fetchLeads,
  openLead,
}: IEventRenderProps) {
  const startTime = useMemo(
    function getStateDateEffect() {
      return DateTime.fromJSDate(event.start_date).toFormat('hh:mm');
    },
    [event.start_date],
  );

  const endTime = useMemo(
    function getStateDateEffect() {
      return DateTime.fromJSDate(event.end_date).toFormat('hh:mm');
    },
    [event.end_date],
  );

  const dateString = useMemo(
    function getStateDateEffect() {
      return DateTime.fromJSDate(event.start_date).toFormat('DDDD');
    },
    [event.start_date],
  );

  const eventClass = useComposedClassName(
    function* () {
      yield 'EventRender';

      if (!!active) {
        yield 'active';
      }

      if (mode === 'reschedule') {
        yield 'reschedule';
      }
    },
    [active, mode],
  );

  // const title = `${event.text}\n\nStart: ${startTime}\n\nEnd: ${endTime}\n\nDouble click to edit`;

  return (
    <Popover
      overlayClassName="EventPopoverContainer"
      content={
        <EventPopoverContent
          event={event}
          fetchLeads={fetchLeads}
          openLead={openLead}
          start={startTime}
          end={endTime}
          date={dateString}
        />
      }
      mouseEnterDelay={0.6}
      autoAdjustOverflow={false}
      placement="top"
    >
      <div className={eventClass}>
        <div className="EventHeader">
          <div className="EventTimeFrame">
            <div className="EventStart">{startTime}</div>
            &ensp;-&ensp;
            <div className="EventEnd">{endTime}</div>
          </div>
          <div className="EventTitle">{event.text}</div>
        </div>
      </div>
    </Popover>
  );
}
