import { useViewContextCount } from '@rapid/sdk';
import React from 'react';

function Offered() {
  const [count, loading] = useViewContextCount();

  return (
    <tr>
      <th>
        <i className="fal fa-fw fa-handshake"></i>
      </th>
      <th>Offered</th>
      <td>{loading ? '...' : <span>{count > 0 ? 'YES' : 'NO'}</span>}</td>
    </tr>
  );
}

export default Offered;
